<template lang="pug">
div
  q-skeleton(style="height: 56px", v-if="useloading && loading && !hasError")
  q-skeleton.q-pa-none(
    style="height: 56px",
    v-if="useloading && hasError",
    animation="none"
  )
    .q-pl-md.text-grey-7.ellipsis Problem loading {{ component.url }}: {{ hasError }}
  //- transition(name="component-fade", mode="out-in")
  Component(
    v-if="!loading",
    :is="computedComponent.control",
    :firebase="firebase",
    :context="context",
    :functions="functions",
    :demo="demo"
  )
</template>

<script>
import loadRemoteModule, {
  createRequires,
} from "@paciolan/remote-module-loader";

const dependencies = {
  vue: require("vue"),
};

const requires = createRequires(dependencies);

export default {
  name: "ComputedComponent",
  props: {
    local: {
      required: false,
    },
    useloading: {
      type: Boolean,
      default: false,
    },
    component: {
      required: true,
      type: Object,
    },
    type: {
      required: true,
      type: String,
    },
    firebase: {
      required: true,
      type: Object,
    },
    functions: {
      required: true,
      type: Object,
    },
    context: {
      required: true,
      type: Object,
    },
    demo: {},
    library: {},
  },
  data() {
    return {
      computedComponent: { control: null },
      loading: true,
      hasError: false,
    };
  },
  // mounted() {
  //   if (typeof window.componentLibrary == "undefined")
  //     window.componentLibrary = {};
  // },
  watch: {
    component: {
      immediate: true,
      async handler(newComponent, prevComponent = "") {
        // console.log(`${newComponent.url}: ${this.context}`);

        // console.log(this.component);
        if (this.component.local) {
          console.log(`Loading local: ${this.component.name}`);
          this.computedComponent.control = this.component.local;
          this.loading = false;
        } else {
          try {
            //same component as last render:
            if (newComponent.url === prevComponent.url) return;

            // console.log(this.library);

            if (this.library[`${this.component.url}:${this.type}`]) {
              console.info(
                `Loaded CACHE ::: ${newComponent.plugin.plugin}:${this.type}@${newComponent.plugin.branch}`
              );
              this.computedComponent.control =
                this.library[`${this.component.url}:${this.type}`];
              return;
            }

            // console.log(this.component.plugin);

            console.info(
              `Loaded ::: ${newComponent.plugin.plugin}:${this.type}@${newComponent.plugin.branch}`
            );

            const Imported = await loadRemoteModule({ requires })(
              `${this.component.url}`
            );

            this.computedComponent.control = Imported[this.type];

            if (this.computedComponent.control == null)
              throw new Error(
                `No component for ${this.type} in ${this.component.url}`
              );

            this.library[`${this.component.url}:${this.type}`] =
              Imported[this.type];
            // window.componentLibrary[`${this.component.url}:${this.type}`] =
            //   Imported[this.type];
            this.hasError = false;
            // console.log(`loaded ${this.type}`);
          } catch (e) {
            this.hasError = e.message;
            console.error(e);
          } finally {
            this.loading = false;
          }
        }
      },
    },
  },
};
</script>
<style>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 2s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
