<template lang="pug">
div
  //- portal(to="header_buttons")
  //-   q-btn(
  //-     rounded,
  //-     flat,
  //-     dense,
  //-     icon="arrow_back_ios_new",
  //-     color="primary",
  //-     label="All Client Sessions",
  //-     :to="'/dashboard/' + patientUid"
  //-   ) 

  //- q-btn.absolute.bg-grey-2.q-mt-lg.z-max(
  //-   icon="west",
  //-   flat,
  //-   dense,
  //-   round,
  //-   :to="`/dashboard/${patientUid}`"
  //- )
  BackBtn.absolute-top-left.q-ml-sm.q-mt-sm.z-max

  .relative(v-if="session")
    .row.q-mb-sm(v-if="sessionPrep")
      .col-12
        q-banner.text-center
          .text-h6 The session will start at {{ getFrom }}
          .text-body1 The session will only start when you are finished reviewing the client, and press the button below.
          q-btn.q-ma-md(
            color="green",
            rounded,
            :disable="!sessionCanStart",
            @click="manuallyStartSessionNow()"
          ) Start Session
          q-chip.text-white(v-if="isOnline", color="secondary", dense) client is online
        //- .text-h5.q-mt-lg.text-center SESSION PREPARATION
        .text-h5.text-center.q-mt-lg {{ session.topic }}
        .text-h6.text-center.text-primary {{ patient.name }} &middot;
          span {{ patient['.key'] }}
        .text-primary.text-center.q-mb-lg Visit the PIP Dashboard to review this client's progress since last session.
        ClientPrep(:client="patient", :meetingid="meetingId")
      //- .col-12(v-if="moduleData")
        //- .text-h4 USER MODULE ACTIVITY RECORDS
        //-   .text-body2.q-mb-lg {{ moduleData.data }}
      //- .col-12(v-if="moduleData")
      //-   .text-h4 USER GOAL SELECTIONS
      //- .text-body2.q-mb-lg {{ moduleData.userGoals }}

    .row.q-mb-sm(v-else-if="sessionEnded && !curated")
      Curation(
        :meetingid="meetingId",
        :patientid="patientUid",
        :actualEndTime="session.actualEndTime",
        :meta="meta"
      )

    .row.q-mb-sm.absolute-center.justify-center.text-center(
      v-else-if="sessionEnded && curated"
    )
      q-icon.q-mb-md.full-width(name="beenhere", color="primary", size="4.5em")
      .text-h5.text-secondary.full-width Review is complete for this session.
      .text-h5.full-width Selected content will be available in My Learning soon.
      q-btn.q-mt-lg(:to="`/dashboard/${patientUid}`", flat, color="secondary") Return to Dashboard

    .fit(v-else) 
      q-dialog(v-model="endSessionDialog")
        q-card.q-pa-sm
          q-card-section
            .text-h6 End Session?
          q-card-section
            .text-body1 Are you sure you want to end the session for all?

          q-card-actions(align="right")
            q-btn(flat, label="Continue", color="primary", v-close-popup)
            q-btn(
              rounded,
              label="End Session",
              color="red-9",
              v-close-popup,
              @click="endSession"
            )

      //- THIS IS THE MAIN VIDEO SCREEN
      .fixed-full.oveflow-hidden
        .column.fit(v-if="audioInput && videoInput && confirmed")
          .col-auto
            .row.justify-center
              div(style="width: 300px")
                q-card.q-ma-sm.q-pa-none(flat)
                  q-card-section
                    .column.items-center
                      .col
                        .text-h5 {{ patient.name }}
                      .col
                        .text-subtitle1 {{ session.topic }}
                      .col
                        .text-subtitle1 {{ getFrom }}
                      .col
                        Timer(:startedAt="session.actualStartTime")
                      .col.full-width
                        DashboardControls.q-pt-lg(
                          :library="library"
                          :meetingid="meetingId",
                          :sequenceid="patientUid",
                          :onlyglobal="true",
                          :currentstate="config"
                        ) 
                q-btn.absolute-bottom-left.z-max(
                  style="margin-left: 4em; margin-bottom: 0.8em",
                  flat,
                  round,
                  color="grey-5",
                  icon="settings",
                  dense,
                  @click="showOnboarding = true"
                )

              .col
                .row.fit.items-center
                  Session(
                    v-on:updateInputs="updateInputs",
                    v-if="isSessionRunning && session.session && session.token",
                    apiKey=process.env.apiKey,
                    :sessionId="session.session",
                    :token="session.token",
                    ref="sessionRef",
                    :patientid="patientUid",
                    :meetingid="meetingId",
                    :audioInput="audioInput",
                    :audioOutput="audioOutput",
                    :videoInput="videoInput"
                  )

          .col
            SessionController(
              :meetingid="meetingId",
              :sequenceid="patientUid",
              :sessionEnded="session && session.actualEndTime"
            )
              template(v-slot:ending)
                q-btn(
                  rounded,
                  v-if="isSessionRunning",
                  color="red-9",
                  label="End Session",
                  @click="endSessionDialog = true",
                  style="min-width: 130px"
                ) 
  .fixed-top-left.fit.bg-grey-4(v-if="showOnboarding")
    .row.full-width.q-mb-sm.q-px-xl.absolute-center.justify-center
      Onboarding(
        :selectAudioInput="selectAudioInput",
        :selectVideoInput="selectVideoInput",
        :confirmOnboarding="confirmOnboarding"
      )
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { db } from "@/db";
import moment from "moment-timezone";
import Session from "@/components/OpenTok/Session.vue";
import SessionController from "@/components/Meeting/SessionController.vue";
import DashboardControls from "@/components/Meeting/DashboardControls";

import Curation from "@/components/Curation/Curation.vue";
import Onboarding from "@/components/dialogs/Onboarding.vue";
import Timer from "@/components/utils/Timer.vue";
import ClientPrep from "@/components/dashboard/ClientPrep.vue";
import { crono } from "vue-crono";
import notifications from "@/utils/notifications";
import BackBtn from "@/components/utils/BackBtn.vue";

const BASE_URL = process.env.VUE_APP_PLUGIN_DIRECTORY;

export default {
  props: ["meetingId"],
  components: {
    Session,
    SessionController,
    Curation,
    Onboarding,
    Timer,
    DashboardControls,
    ClientPrep,
    BackBtn,
  },
  mixins: [crono, notifications],
  cron: {
    time: 1000,
    method: "updateTime",
    autoStart: true,
  },
  computed: {
    isOnline() {
      return new Date().valueOf() - this.ping.clientPing < 5000;
    },
    db() {
      return db;
    },
    getFrom() {
      return moment(this.session.startTime).format("LT");
    },
    sessionCanStart() {
      return moment(this.session.startTime).unix() - this.currentTime < 0;
    },
    canStartSession() {
      return (
        !this.session ||
        !this.session.scheduled ||
        (this.session && this.session.actualEndTime)
      );
    },
    sessionPrep() {
      return !this.session.actualStartTime;
    },
    sessionEnded() {
      return this.session.actualEndTime;
    },
    curated() {
      return this.session.curated;
    },
    // isEditing() {
    //   return this.$route.params.type === "editing";
    // },
    ...mapGetters(["getUser"]),
  },
  methods: {
    updateTime() {
      this.currentTime = moment().unix();
    },
    manuallyStartSessionNow() {
      this.manuallyStartSession = true;
      this.isSessionRunning = true;
      this.startSession({ uid: this.uid, meetingId: this.meetingId });
    },
    updateInputs() {
      //reset as the inputs dont work:
      localStorage[`confirmedSettings-${this.meetingId}`] = false;
      // localStorage.audioInput = null;
      // localStorage.videoInput = null;
      this.showOnboarding = true;
      // location.reload();
    },
    getSessionDate() {
      const sessionDate = moment(this.session.startTime).toDate();
      return sessionDate.toDateString();
    },
    endSession() {
      this.isSessionRunning = false;
      try {
        this.$refs.sessionRef.disconnect();
      } catch {
        // error cutting call
      }
      this.endSessionAll({ uid: this.uid, meetingId: this.meetingId });
    },
    selectAudioInput(audioInput) {
      this.audioInput = audioInput;
    },
    selectVideoInput(videoInput) {
      this.videoInput = videoInput;
    },
    confirmOnboarding() {
      // console.log("confirmed");
      this.confirmed = true;
      this.showOnboarding = false;
      localStorage[`confirmedSettings-${this.meetingId}`] = true;
      // this.startCurrentSession();
      // console.log("confirmed onboarding");
    },
    ...mapActions(["startSession", "endSessionAll"]),
  },
  data: () => {
    return {
      currentTime: moment().unix(),
      manuallyStartSession: false,
      patient: null,
      patientUid: null,
      session: null,
      isSessionRunning: false,
      endSessionDialog: false,
      uid: null,
      meta: null,
      audioInput: null,
      audioOutput: null,
      videoInput: null,
      confirmed: false,
      // moduleData: null,
      plugins: null,
      showOnboarding: false,
      config: null,
      ping: {},
      library: {},
    };
  },
  async mounted() {
    this.$q.loading.show({
      customClass: "bg-grey-4",
    });
    this.patientUid = this.$route.params.uid;
    this.uid = this.getUser.uid;

    try {
      if (this.plugins == null) {
        let resp = await fetch(`${BASE_URL}index.json`);
        this.plugins = await resp.json();
      }
      await Promise.all([
        this.$rtdbBind(
          "patient",
          db.ref("patients").child(this.$route.params.uid)
        ),
        this.$rtdbBind(
          "session",
          db.ref("meetings").child(this.uid).child(this.meetingId)
        ),
        // await this.$rtdbBind("chats", db.ref("data/chats").child(this.meetingId));
        this.$rtdbBind(
          "meta",
          db.ref("data/meetingMeta").child(this.meetingId)
        ),

        this.$rtdbBind(
          "config",
          db.ref(`config/${this.meetingId}/current/currentState/plugins`)
        ),

        this.$rtdbBind("ping", db.ref(`data/pings/${this.meetingId}`)),
      ]);

      // console.log(this.clientData);

      // this.$rtdbBind("config", db.ref("config/meetingMeta").child(this.meetingId));
      //HACK: FOR WHEN THE currentSchedule has not been created in the config node:
      // console.log(this.config);
      if (this.config == null) {
        // console.log("resetting currentSection");
        await db.ref(`config/${this.meetingId}/current/currentSection`).set(-1);
        await db.ref(`config/${this.meetingId}/current/currentSection`).set(0);
      }

      // ##### Temporarily displaying the records on the pre-session page
      // const sessionIdx = (
      //   await db
      //     .ref("meetings")
      //     .child(`${this.uid}/${this.meetingId}`)
      //     .once("value")
      // ).val().sessionIdx;

      // const moduleData = await getUserModuleDataFn({
      //   uid: this.$route.params.uid,
      //   moduleId: `m${sessionIdx}`,
      // });
      // this.moduleData = moduleData.data;

      // console.log(localStorage.confirmedSettings);

      //if the session has started:
      if (this.session.actualStartTime) {
        this.isSessionRunning = true;
      }

      //if the session has not started yet:
      if (!this.sessionEnded) {
        if (
          localStorage[`confirmedSettings-${this.meetingId}`] &&
          localStorage[`confirmedSettings-${this.meetingId}`] == "true"
        ) {
          this.showOnboarding = false;
          this.confirmOnboarding();
          this.audioInput = localStorage.audioInput;
          this.videoInput = localStorage.videoInput;
        } else {
          this.showOnboarding = true;
        }
      } else {
        this.showOnboarding = false;
      }
    } catch (e) {
      this.showError(e);
    }
    this.$q.loading.hide();
  },
  created() {},
  beforeDestroy() {
    // console.log("unmounted");
    try {
      this.$refs.sessionRef.disconnect();
    } catch {
      //error cutting call
    }
    // this._userMediaInputStream.getTracks().forEach(track => {
    //   track.stop();
    // });
  },
};
</script>

<style lang="sass" scoped></style>
