<template lang="pug">
div.print-fixed.fixed.full-width.full-height
  .row.q-mb-sm.justify-center.full-width(style="height:200px;")
    .col.q-px-sm
      InspectSlider(
        :readonly="true"
        :meetingid="meetingid",
        :patientid="patientid",
        :duration="duration",
        :currentTime="currentTime"
        :item="item",
        :startTime="0",
        :endTime="0",
        :slides="slides",
        :slideChange="onChange",
        :sections="sections",
        :sectionNames="sectionNames",
        :actualStartTime="actualStartTime",
        :transcript="transcription",
        :annotations="annotations"
        :actualEndTime="actualEndTime",
        :assets="assets"
      )
    
  .row.q-col-gutter-xs
    .col
      //- div {{ slideHistory }}
      .q-px-sm
        span.text-overline Legend:
        q-chip(size="12px" color="green-3" dense) Section Change
        q-chip(size="12px" color="red-3" dense) Annotation
        q-chip(size="12px" color="pink-3" dense) Slide Change
        q-chip(size="12px" color="blue-3" dense) Slide Input
        q-chip(size="12px" color="purple-3" dense) Pip Content
        span.asset-p(style="font-size:12px") Curated for Parent&nbsp;
        span.underline(style="font-size:12px") Marked as interesting
      .q-mt-sm.q-mx-sm.rounded-borders.print-scroll(
        style="border: 1px solid #eee",
        ref="scrollArea"
      )
        .transcript(
          style="margin-right: 30px; margin-top: 5px; margin-bottom: 5px; margin-left: 5px; position: relative"
        )
          q-chip(size="12px" color="green-3" dense) {{ sectionNames[0] }}
          .timestamp.cursor-pointer(
            v-for="(item, index) in transcript.currentArr",
            @click="skip(item)"
            :data-time="getCurrentStartTime(item.start)",
            :class="{ t_highlight: isCurrentTime(item) }"
          )
            a(v-if="isCurrentTime(item)", :id="`marked-master`")
            span(:class="{'underline':getTagging(item),'asset-p':isParent(getAsset(item)), 'asset-c':getAsset(item)}") {{ item.content }}&nbsp;
            span(v-if="getSection(item)")
              q-chip(size="12px" color="green-3" dense v-for="(sect,index) in getSection(item)" icon="skip_next" :key="index") {{ sect }}
            span(v-if="getAnnotation(item)")
              q-chip(size="12px" color="red-3" dense v-for="(sect,index) in getAnnotation(item)" icon="note" :key="index") Annotation
                q-tooltip(max-width="300px")
                  Viewer(:initialValue="sect").text-white
            span(v-if="getSlide(item)" v-for="sect in getSlide(item)" :key="sect.start")
              q-chip(size="12px" color="blue-3" dense v-for="(inp,index) of sect" icon="edit" :key="index") {{ inp.label }}
                q-tooltip(max-width="300px")
                  Viewer(:initialValue="inp.value")
            span(v-if="getPipSearch(item)" )
              q-chip(size="12px" color="purple-3" dense icon="search") {{ getPipSearch(item)._selected.path }}
            span(v-if="getSlideHistory(item)" )
              q-chip(size="12px" color="pink-3" dense icon="arrow_forward") {{ getSlideHistory(item)._slide + 1 }}

            q-tooltip(max-width="300px" v-if="getAsset(item)")
              .body-1 {{ getAsset(item).title }}
              q-badge(v-for="label of getAsset(item).labels" :key="label") {{ label }}
              


          q-chip(size="12px" color="green-3" dense) Session Ended
    .col-auto.hideprint
      video-player.vjs-custom-skin.q-mt-sm.q-mr-sm.hideprint(
          style="border: 1px solid #eee",
          ref="videoPlayer",
          v-if="url"
          :options="playerOptions",
          @pause="onPlayerPause($event)",
          @ended="onPlayerEnded($event)",
          @loadeddata="onPlayerLoadeddata($event)",
          @waiting="onPlayerWaiting($event)",
          @playing="onPlayerPlaying($event)",
          @timeupdate="onPlayerTimeupdate($event)",
          @canplay="onPlayerCanplay($event)",
          @canplaythrough="onPlayerCanplaythrough($event)",
          @ready="playerReadied",
          @statechanged="playerStateChanged($event)"
        )
  
</template>

<script>
import { db } from "@/db";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { formatMinuteSecond, formatDuration } from "../../utils/formatter";
import { getTranscription } from "../../utils/transcription";
import InspectSlider from "./InspectSlider.vue";
import { scroll } from "quasar";
const { getScrollTarget } = scroll;
import { Viewer } from "@toast-ui/vue-editor";
import { pickBy } from "lodash";

let regex = /^:::\s(s-)?(\w*):(.*)$/gm;

export default {
  name: "VideoCuration",
  // mixins: [notifications],
  props: {
    annotations: {
      required: true,
    },
    meetingid: {
      required: true,
    },
    patientid: {
      required: true,
    },
    url: {
      required: true,
    },
    startTime: {
      required: true,
    },
    endTime: {
      required: true,
    },
    sections: {
      required: true,
    },
    slides: {
      required: true,
    },
    actualStartTime: {
      required: true,
    },
    actualEndTime: {
      required: true,
    },
    sectionNames: {
      required: true,
    },
    transcription: {
      required: true,
    },
    coachid: {
      required: true,
    },
  },
  components: { videoPlayer, InspectSlider, Viewer },
  data() {
    return {
      item: {},
      play: null,
      duration: 0,
      currentTime: 0,
      saving: false,
      fbref: null,
      sequenceinputs: {},
      meetinginputs: {},
      slideTimestamp: [],
      pipSearchData: {},
      assets: {},
      slideHistory: {},
    };
  },
  computed: {
    playerOptions() {
      return {
        autoplay: false,
        muted: false,
        language: "en",
        sources: [{ src: this.url, type: "video/mp4" }],
      };
    },
    min() {
      return this.item.editCurrent
        ? this.item.editCurrent.startTime
        : this.startTime;
    },
    max() {
      return this.item.editCurrent
        ? this.item.editCurrent.endTime
        : this.endTime;
    },
    transcript() {
      if (!this.transcription) return "";
      // console.log("getting transcription");
      return getTranscription(this.transcription, this.min, this.max);
    },
  },
  methods: {
    isParent(p) {
      return p?.toParents ?? false;
    },
    timeCodeToSecs(time) {
      let tt = time.split(":");
      // console.log(tt);
      return parseInt(tt[0]) * 3600 + parseInt(tt[1]) * 60 + parseInt(tt[2]);
    },
    getPipSearch(time) {
      if (!this.pipSearchData) return;

      // let returned = [];

      for (let sect in this.pipSearchData._history) {
        // console.log(sect);
        // console.log(this.actualStartTime);
        let ss = (parseInt(sect) - this.actualStartTime) / 1000;
        // console.log(ss);
        // console.log(time.start);
        // console.log(this.assets[sect]);
        // console.log(this.timeCodeToSecs(this.assets[sect].startTimecode));
        // let ss = this.timeCodeToSecs(this.assets[sect].startTimecode);
        // let ee = this.timeCodeToSecs(this.assets[sect].endTimecode);
        // console.log(time.start);
        // console.log(ss);
        // console.log(ee);
        if (time.start <= ss && time.end > ss) {
          // console.log("found");
          return this.pipSearchData._history[sect];
        }
      }

      // return returned.length ? returned : null;
    },
    getSlideHistory(time) {
      if (!this.slideHistory) return;

      // let returned = [];

      for (let sect in this.slideHistory) {
        // console.log(sect);
        // console.log(this.actualStartTime);
        let ss = (parseInt(sect) - this.actualStartTime) / 1000;

        if (time.start <= ss && time.end > ss) {
          // console.log("found");
          return this.slideHistory[sect];
        }
      }

      // return returned.length ? returned : null;
    },
    getAsset(time) {
      if (!this.assets) return;

      // let returned = [];

      for (let sect in this.assets) {
        // console.log(this.assets[sect]);
        if (sect != "remaining") {
          // console.log(this.timeCodeToSecs(this.assets[sect].startTimecode));
          let ss = this.timeCodeToSecs(this.assets[sect].startTimecode);
          let ee = this.timeCodeToSecs(this.assets[sect].endTimecode);

          // console.log(time.start);
          // console.log(ss);
          // console.log(ee);

          if (
            (time.start < ss && time.end > ss) ||
            (time.start > ss && time.end < ee) ||
            (time.start > ss && time.start < ee)
          )
            return this.assets[sect];
        }
      }

      // return returned.length ? returned : null;
    },
    getTagging(time) {
      //NEEDS REWRITING FOR EFFICIENCY
      // console.log(this.taggingData);
      if (!this.taggingData) return;
      let returned = [];

      for (let sect in this.taggingData) {
        let ss =
          (this.taggingData[sect].timestamp - this.actualStartTime) / 1000;
        // console.log(
        //   (this.taggingData[sect].timestamp - this.actualStartTime) / 1000
        // );
        // console.log(time.start);
        if (time.start <= ss && time.end > ss)
          returned.push(`${this.taggingData[sect]}`);
      }

      return returned.length ? returned : null;
    },
    getSlideIndex(value) {
      return this.slideTimestamp.indexOf(this.formatTimestamp(value));
    },
    formatTimestamp(timestamp) {
      return Math.round(timestamp * 10) / 10;
    },
    skip(item) {
      // console.log(item);
      this.player.currentTime(item.start);
    },
    toZeroTime(time) {
      return {
        start: (time.startTime - this.actualStartTime) / 1000,
        end: (time.endTime - this.actualStartTime) / 1000,
      };
    },
    getSection(time) {
      //NEEDS REWRITING FOR EFFICIENCY
      //for each section, is that section start within this?
      if (!this.sections) return false;
      let returned = [];

      for (let sect in this.sections) {
        let ss = this.toZeroTime(this.sections[sect]);
        if (time.start <= ss.start && time.end > ss.start)
          returned.push(`${this.sectionNames[sect]}`);
        // if (time.start <= ss.end && time.end > ss.end)
        //   returned.push(`${this.sectionNames[sect]} ended`);
      }

      return returned.length ? returned : null;
    },
    getSlide(time) {
      //NEEDS REWRITING FOR EFFICIENCY
      // console.log(time);
      if (!this.slideTimestamp) return false;

      let returned = [];
      // console.log("slides");
      for (let sect of this.slideTimestamp) {
        // console.log(sect);
        // console.log(time.start);
        if (time.start <= sect && time.end > sect)
          if (this.getSlideIndex(sect) >= 0 && this.hasInput(sect)) {
            returned.push(this.getInputForSlide(sect));
          }
      }

      return returned.length ? returned : null;
    },
    getInput(type, field) {
      if (type === "s-") return this.sequenceinputs[field] || "";
      else return this.meetinginputs[field] || "";
    },
    hasInput(index) {
      let content = this.slides[this.getSlideIndex(index)].slideContent;
      let result = content.match(/:::/);

      return result != null;
    },
    getInputForSlide(index) {
      let content = this.slides[this.getSlideIndex(index)].slideContent;

      let inputs = [...content.matchAll(regex)];

      let tmp = [];

      for (let field of inputs) {
        tmp.push({
          label: field[3],
          value: this.getInput(field[1], field[2]),
        });
      }

      return tmp;
    },
    getAnnotation(time) {
      //for each section, is that section start within this?
      if (!this.annotations) return false;
      let returned = [];

      for (let sect in this.annotations) {
        // let ss = this.toZeroTime(this.sections[sect]);
        if (time.start <= sect && time.end > sect)
          returned.push(`${this.annotations[sect]}`);
        // if (time.start <= ss.end && time.end > ss.end)
        //   returned.push(`${this.sectionNames[sect]} ended`);
      }

      return returned.length ? returned : null;
    },
    pause: function () {
      this.player.pause();
    },
    isCurrentTime(item) {
      // console.log(this.currentTime);
      return this.currentTime > item.start && this.currentTime < item.end;
    },
    // async slideChange(min, max) {
    //   this.player.currentTime(min);
    // },
    getCurrentStartTime(start) {
      return formatMinuteSecond(start);
    },
    getCurrentEndTime(end) {
      return formatMinuteSecond(end);
    },
    getEndTime() {
      return formatMinuteSecond(this.duration);
    },
    getTimeLabel() {
      return `Suggested: ${formatMinuteSecond(
        this.startTime
      )} - ${formatMinuteSecond(this.endTime)}`;
    },
    getDuration() {
      return `${formatDuration(this.endTime - this.startTime)}`;
    },
    // onPlayerPlay(player) {
    //   this.$emit("onPlay", this);
    // },
    onPlayerPause(player) {
      // console.log("player pause!", player);
    },
    onPlayerEnded(player) {
      // console.log("player ended!", player);
    },
    onPlayerLoadeddata(player) {
      // console.log("player Loadeddata!", player);
      // console.log(player.duration());
      if (!this.duration) {
        // player.pause();
        const videoDuration = Math.round(player.duration() * 10) / 10;
        this.duration = videoDuration;
      }
    },
    onPlayerWaiting(player) {
      // console.log("player Waiting!", player);
    },
    onPlayerPlaying(player) {
      // console.log("player Playing!", player);
    },
    onPlayerTimeupdate(player) {
      // Looping the current range
      // if (player.currentTime() >= this.max) this.player.currentTime(this.min);
      this.currentTime = player.currentTime();
      //scroll?
      //TODO: fix this
      // this.scroll();
    },
    onPlayerCanplay(player) {
      // console.log("player Canplay!", player);
    },
    onPlayerCanplaythrough(player) {
      // console.log("player Canplaythrough!", player);
    },
    playerStateChanged(playerCurrentState) {
      // console.log("player current update state", playerCurrentState);
    },
    playerReadied(player) {
      this.player = player;
      this.player.currentTime(this.startTime);
    },
    onChange(min, max) {
      // console.log(min, max);
      this.slideChange(min, max);
      setTimeout(() => {
        this.scroll();
      }, 1000);
    },
    scroll() {
      // const { getScrollTarget, setScrollPosition } = scroll;

      // console.log(this.manualKey);

      const ele = document.getElementById(`marked-master`);
      // console.log(ele);
      if (ele) {
        const target = getScrollTarget(ele);
        // console.log(ele.offsetTop);
        // console.log(
        //   ele.getBoundingClientRect().top - target.getBoundingClientRect().top
        // );
        // const offset = ele.offsetTop;

        // console.log(
        //   Math.abs(target.scrollTop) + target.getBoundingClientRect().height
        // );
        if (
          Math.abs(target.scrollTop) + target.getBoundingClientRect().height >
          ele.getBoundingClientRect().top - target.getBoundingClientRect().top
        ) {
          // console.log(target.scrollTop);
          // const eleRect = ele.getBoundingClientRect();
          // const targetRect = target.getBoundingClientRect();
          // const offset = eleRect.top - targetRect.top;
          // console.log(offset);
          // const duration = 200;
          // setScrollPosition(target, offset, duration);
          ele.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
      // throw new Error("this error");
    },
  },
  async mounted() {
    this.fbref = db.ref(`data/transcription/${this.meetingid}/raw/`);
    // console.log(this.index);

    const slideTimestamp = [];
    // console.log(this.slides);
    Object.keys(this.slides).forEach((key) => {
      slideTimestamp.push(
        this.formatTimestamp(
          (this.slides[key].startTime - this.actualStartTime) / 1000
        )
      );
    });
    this.slideTimestamp = slideTimestamp;

    this.sequenceinputs = (
      await db.ref(`sequence/slides/${this.patientid}/current`).once("value")
    ).val();

    this.meetinginputs = (
      await db.ref(`data/slides/${this.meetingid}/current/`).once("value")
    ).val();

    this.taggingData = (
      await db.ref(`data/tagging/${this.meetingid}`).once("value")
    ).val();

    this.assets = (
      await db.ref(`data/curationAsset/${this.meetingid}`).once("value")
    ).val();

    this.pipSearchData = (
      await db.ref(`data/pipsearch/${this.meetingid}`).once("value")
    ).val();

    // let sl = (
    //   await db.ref(`data/slides/${this.meetingid}_history`).once("value")
    // ).val();

    let tmp = (
      await db
        .ref(`data/slides/${this.meetingid}/_history`)
        .orderByChild("_slide")
        .once("value")
    ).val();

    this.slideHistory = pickBy(tmp, (f) => {
      return Number.isInteger(f._slide);
    });
  },
};
</script>

<style lang="sass">
.print-scroll
  height: calc(100vh - 340px)
  overflow: scroll

@media print
  .hideprint
    display: none !important
  .print-fixed
    position: relative !important
  .print-scroll
    height: auto
    .scroll
      // height: fit-content !important
      height: 100%
      overflow: auto !important

.vjs-paused.vjs-has-started.vjs-custom-skin > .video-js .vjs-big-play-button,
.video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button
  display: block

.video-js
  width: 250px
  // height: 127px

.vjs-custom-skin > .video-js .vjs-load-progress div,
.vjs-seeking .vjs-big-play-button,
.vjs-waiting .vjs-big-play-button
  display: none !important

.vjs-custom-skin > .video-js .vjs-big-play-button
  top: 50%
  left: 50%
  margin-left: -1.5em
  margin-top: -1em

.vjs-custom-skin
  border-radius: 4px
  overflow: hidden

.vjs-custom-skin > .video-js .vjs-big-play-button
  background-color: rgba(0,0,0,0.45)
  font-size: 2.5em
  height: 2em !important
  line-height: 2em !important
  margin-top: -1em !important

.inline-text
  display: inline

.transcript
  line-height: 30px
  margin-top: 10px

.timestamp
  display: inline
  position: relative

.timestamp::before
  position: absolute
  top: -20px
  font-size: 10px
  color: #ccc
  content: attr(data-time)

.video-js .vjs-tech
  position: absolute
  top: -64px
  left: 0
  width: 100%
  height: 255px

.t_highlight
  text-shadow: 0px 0px 2px var(--q-color-secondary)

.postit
  background-color: #ded66e

.underline
  border-bottom: red solid 1px

.asset-p
  // border-bottom: blue solid 1px
  background: #ffffb3 !important

.asset-c
  // border-bottom: blue solid 1px
  background: #ccffb3
</style>
