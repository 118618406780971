export const formatMinuteSecond = (time) => {
  // console.log(time);
  const hours = Math.floor(time / 3600);
  time = time - hours * 3600;
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);

  // console.log(hours);

  return `${hours ? hours.toString().padStart(2, "0") + ":" : ""}${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const getMediaConvertTimeCode = (time) => {
  const hours = Math.floor(time / 3600);
  time = time - hours * 3600;
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);

  return `${hours ? hours.toString().padStart(2, "0") + ":" : "00:"}${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}:00`;
};

export const getTimeInSeconds = (str) => {
  const arr = str.split(":");
  return (
    (Number.parseInt(arr[0]) * 24 + Number.parseInt(arr[1])) * 60 +
    Number.parseInt(arr[2])
  );
};

export const formatDuration = (time) => {
  const hours = Math.floor(time / 3600);
  time = time - hours * 3600;
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);

  const hourStr =
    hours > 0 ? (hours === 1 ? "1 Hour " : `${hours} Hours `) : "";
  const minStr =
    minutes > 0 ? (minutes === 1 ? "1 Min " : `${minutes} Mins `) : "";
  const secStr =
    seconds > 0 ? (seconds === 1 ? "1 Sec" : `${seconds} Secs`) : "";

  return hourStr + minStr + secStr;
};
