<template lang="pug">
.q-pa-sm
  .text-h5.q-my-xl.text-center TODAY'S SESSIONS
  .row.q-col-gutter-lg.items-center.justify-center
    .col-12.col-sm-6.col-md-4.col-lg-3(
      v-for="(session, index) in todaySessions"
    )
      PatientCard(
        :key="index",
        :uid="session.patientUid",
        :name="session.name",
        :patient="getPatient(session.patientUid)",
        :nextSession="getNextSessionTime(session)",
        :sessionName="getNextSessionName(session)",
      )
  .text-overline.text-center(v-if="todaySessions.length == 0") You don't have any sessions today.

  .text-h5.q-mt-xl.text-center MY CLIENTS
  //- .row.q-col-gutter-md.items-stretch.justify-center
    //- div {{ futureSessions }}
    //- .col-12.col-sm-6.col-md-3(v-for="(session, index) in futureSessions")
  .row
    q-list.full-width(separator)
      //- div {{futureSessions}}
      PatientRow(
        v-for="(session, index) in futureSessions",
        :patient="session.patient",
        :key="index",
        :uid="session.uid",
        :name="session.name"   
        :nextSession="session.nextSession"
      )

      .text-overline.text-center(v-if="Object.keys(futureSessions || {}) == 0") You don't have any clients yet.

      q-item
        q-item-section
        q-item-section(side)
          q-btn(
            color="secondary",
            flat,
            icon="add",
            @click="createParent = true"
          ) Add a New Client

    //- .col-12.col-sm-6.col-md-3.text-center
    //-   q-card.full-height(flat, style="border-radius: 8px; min-height: 200px")
    //-     q-btn.absolute-center(
    //-       round,
    //-       color="secondary",
    //-       icon="add",
    //-       size="xl",
    //-       @click="createParent = true"
    //-     )
    //-       q-tooltip(transition-show="scale", transition-hide="scale") Create a New Client Profile

    q-dialog(v-model="createParent")
      q-card
        q-card-section
          .row.items-center
            //- q-icon(name="folder_shared", color="primary", size="xl")
            .text-h6.text-bold.q-ml-sm Create a New Client Profile
        q-separator
        q-card-section
          .row.q-mb-lg.justify-center
            .col-12
              q-input.full-width.q-mb-lg(
                color="primary",
                v-model="pipUid",
                label="PiP UID"
              )
                template(v-slot:prepend)
                  q-icon(name="fingerprint", color="primary", size="md")
            .col-12
              q-input.full-width.q-mb-lg(
                color="primary",
                v-model="name",
                label="Name"
              )
                template(v-slot:prepend)
                  q-icon(name="person", color="primary", size="md")
            .col-12
              q-input.full-width(
                color="primary",
                v-model="email",
                label="Email"
              )
                template(v-slot:prepend)
                  q-icon(name="email", color="primary", size="md")
        q-separator
        q-card-actions.q-pr-lg(align="right")
          q-btn(flat, label="Cancel", color="primary", v-close-popup)
          q-btn(
            flat,
            :loading="loading",
            label="Create",
            color="secondary",
            @click="createParentProfile"
          )
</template>

<script>
import { mapGetters } from "vuex";
import { db, createParentProfileFn } from "@/db";
import {map,filter, sortBy,groupBy,compact} from "lodash";
import moment from "moment-timezone";
import Session from "@/components/OpenTok/Session.vue";
import PatientCard from "@/components/dashboard/PatientCard.vue";
import PatientRow from "@/components/dashboard/PatientRow.vue";
import notifications from "@/utils/notifications";


export default {
  name: "Dashboard",
  plugins: [],
  props: {},
  mixins: [notifications],
  components: { Session, PatientCard, PatientRow },
  computed: {
    db() {
      return db;
    },
    todaySessions() {
      if (!this.patients) return [];

      let nextSessions = Object.values(this.getNextSessions());
      nextSessions = compact(map(nextSessions,'nextSession'));
      // console.log(nextSessions);



      const filtered = filter(nextSessions, (session) => {
        if (!session.startTime) return false;

        let current = moment().utc();
        // const diffDays =
        //   (moment(session.startTime).toDate() - current) / 1000 / 60 / 60 / 24;
        // console.log(session.startTime);

        return current.isSame(moment(session.startTime),'day');
        // return false;
        //within 24 hours?
        // return diffDays <= 1;
      });

      return sortBy(filtered, ["startTime"]);
    },
    futureSessions() {
      if (!this.patients) return;
      let nextSessions = this.getNextSessions();
      return Object.values(sortBy(nextSessions,['name'],['asc']));
    },
    ...mapGetters(["getUser"]),
  },
  data: () => {
    return {
      uid: null,
      sessionId: null,
      token: null,
      archiveId: null,
      coachMeetings: null,
      patients: null,
      createParent: false,
      pipUid: "",
      name: "",
      email: "",
      loading: false,
    };
  },
  methods: {
    getNextSession(patient) {
      let nextSession;
      patient.forEach((session) => {
        const current = moment().unix();
        const diff = moment(session.startTime).unix() - current;
        // console.log(diff);
        if (diff >= 0 && !nextSession) nextSession = session;
      });

      return nextSession;
    },
    getNextSessions() {
      let meetings = sortBy(this.coachMeetings, ["startTime"]);

      const tmp = groupBy(meetings, "patientUid");
      // console.log(tmp);

      // console.log(this.patients);

      Object.keys(this.patients).forEach((key) => {
        if (this.patients[key].coach === this.uid && !tmp[key]) {
          tmp[key] = [];
        }
      });

      let nextSessions = {};
      // console.log(this.uid);
      Object.keys(tmp).forEach((key) => {
        // console.log(key);
        // console.log(this.patients[key]);
        // console.log(this.getNextSession(tmp[key]));
        
        nextSessions[key] = {
          nextSession:this.getNextSession(tmp[key]),
          name: this.patients[key]?.name,
          patient: this.patients[key],
          uid: key,
        };
      });

      // console.log(nextSessions)

      return nextSessions;
    },
    getNextSessionTime(session) {
      return session && session.startTime
        ? `${moment(session.startTime).format("LT")}`
        : "";
    },
    getNextSessionName(session) {
      console.log(session)
      return session && session.topic ? session.topic : "";
    },
    async getPatientName(patientUid) {
      try {
        const patient = (
          await db.ref(`patients/${patientUid}`).once("value")
        ).val();
        return patient.name;
      } catch (e) {
        this.showError(e);
      }
    },
    // async startNewArchive() {
    //   const data = await this.startArchive({
    //     uid: this.uid,
    //     sessionId: this.sessionId,
    //   });
    //   this.archiveId = data.id;
    // },
    async createParentProfile() {
      this.loading = true;

      try {
        const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
        let errorMessage = "";
        if (this.name.length <= 0)
          errorMessage += "Name is required for creating a client profile!";
        if (this.pipUid.length <= 0)
          errorMessage +=
            " PiP+ UID is required for creating a client profile!";
        if (!emailValid)
          errorMessage +=
            " Please provide a valid email to get the one-time access code!";

        if (errorMessage.length > 0)
          this.$q.notify({
            message: errorMessage,
            type: "negative",
          });
        else {
          const queryObj = {
            pipUid: this.pipUid,
            name: this.name,
            email: this.email,
            coach: this.uid,
          };
          const result = await createParentProfileFn(queryObj);

          this.$q.notify({
            message: result.data.message,
            type: result.data.status !== 200 ? "negative" : "positive",
          });
          this.createParent = false;
        }

        this.pipUid = "";
        this.name = "";
        this.email = "";
      } catch (e) {
        this.showError(e);
      }
      this.loading = false;
    },
    // ...mapActions(["startArchive", "stopArchive"]),
    getPatient(p)
    {
      return this.patients[p];
    }
  },
  async mounted() {
    this.$q.loading.show({});
    try {
      this.uid = this.getUser.uid;
      await Promise.all([
        this.$rtdbBind("coachMeetings", db.ref("meetings").child(this.uid)),
        this.$rtdbBind("patients", db.ref("patients")),
      ]);
    } catch (e) {
      this.showError(e);
    }
    this.$q.loading.hide();
  },
};
</script>

<style></style>
