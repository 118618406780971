<template lang="pug">
#app
  q-layout.bg-grey-1(view="hHh lpR fFf")
    q-header.bg-white.text-primary(
      bordered,
      v-if="pathname && pathname !== 'OverlayDisplay' && pathname !== 'Login' && pathname !== 'MeetingScreen' && pathname !== 'SessionDetail' && pathname !== 'ParentLearning'"
    )
      q-toolbar
        portal-target.row.items-center(name="header_buttons")
          //- .col-auto
          //-   .row
          //-     img.on-left(src="./assets/favicon.svg", style="width: 32px")

          .col-auto
            BackBtn
          .col-auto
            .text-h6.text-secondary Bicara

        q-toolbar-title
          .text-h5(v-if="patientUid && patient") {{ patient.name }}&nbsp;
            span.text-grey-5 &middot; {{ patient['.key'] }}

        //- q-space

        q-btn.lt-md(flat, round, icon="menu", v-if="getUser")
          q-menu
            q-list(style="min-width: 200px")
              q-item(
                clickable,
                v-close-popup,
                to="/dashboard",
                v-if="!isAnonymous() && isCoach"
              )
                q-item-section.text-subtitle2 Dashboard

              q-item(
                clickable,
                v-close-popup,
                to="/learning",
                v-if="!isAnonymous() && isCoach"
              )
                q-item-section.text-subtitle2 My Learning

              q-item(
                clickable,
                v-close-popup,
                to="/editor/templates",
                v-if="!isAnonymous() && isAdmin"
              )
                q-item-section.text-subtitle2 Editor

              q-item(
                clickable,
                v-close-popup,
                to="/admin",
                v-if="!isAnonymous() && isAdmin"
              )
                q-item-section.text-subtitle2 Admin

              q-separator

              q-item(clickable, v-close-popup, @click="signOut")
                q-item-section.text-subtitle2 Sign out

        .q-gutter-sm.row.items-center.no-wrap.gt-sm(v-if="getUser")
          q-btn.menuItem(
            flat,
            rounded,
            to="/dashboard",
            label="Dashboard",
            v-if="!isAnonymous() && isCoach",
            v-bind:class="{ active: pathname && pathname.indexOf('dashboard') >= 0 }"
          )
          q-btn.menuItem(
            flat,
            rounded,
            to="/learning",
            label="My Learning",
            v-if="!isAnonymous() && isCoach",
            v-bind:class="{ active: pathname && pathname.indexOf('learning') >= 0 }"
          ) 
          q-btn.menuItem(
            flat,
            rounded,
            to="/editor/templates",
            label="Editor",
            v-if="!isAnonymous() && isAdmin",
            v-bind:class="{ active: pathname && pathname.indexOf('editor') >= 0 }"
          )
          q-btn.menuItem(
            flat,
            rounded,
            to="/admin",
            label="Admin",
            v-if="!isAnonymous() && isAdmin",
            v-bind:class="{ active: pathname && pathname.indexOf('admin') >= 0 }"
          )

          q-btn(flat, round, icon="logout", @click="signOut") 
            q-tooltip Sign out

        q-btn(flat, icon="help", to="/docs", round) 
          q-tooltip How to use Bicara

    q-page-container.overflow-hidden
      q-page(:style-fn="myTweak")
        router-view

    .text-center.q-pa-sm(
      v-if="!(pathname && (pathname == 'ParentLearning' || pathname == 'MeetingScreen' || pathname == 'Editor' || pathname == 'Login'))"
    )
      Footer
</template>

<script>
import { db } from "@/db";
import { mapGetters, mapActions } from "vuex";
import Footer from "./components/utils/Footer.vue";
import BackBtn from "./components/utils/BackBtn.vue";

export default {
  name: "App",
  components: { Footer, BackBtn },
  data: () => {
    return {
      pathname: null,
      isCoach: false,
      isAdmin: false,
      patient: null,
      patientUid: null,
      hover: false,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  async mounted() {
    this.authAction();
    this.updatePathname();
    this.updatePatientUid();
    const claims = await this.getCustomClaims();
    // console.log(claims);
    this.isCoach = claims && claims.coach;
    this.isAdmin = claims && claims.admin;
    // throw Error("TEST ERROR");
  },
  updated() {
    this.updatePathname();
    this.updatePatientUid();
  },
  methods: {
    myTweak(offset) {
      // "offset" is a Number (pixels) that refers to the total
      // height of header + footer that occupies on screen,
      // based on the QLayout "view" prop configuration
      // console.log(offset);
      if (this.pathname != 'Login')
        offset += 40;
      // this is actually what the default style-fn does in Quasar
      return { minHeight: offset ? `calc(100vh - ${offset}px)` : "100vh" };
    },
    isAnonymous() {
      const user = this.getUser;
      return user && user.isAnonymous;
    },
    async signOut() {
      const tmpIsCoach = this.isCoach;
      await this.signOutAction();
      if (tmpIsCoach) this.$router.replace("/login");
      else this.$router.go(0);
    },
    updatePathname() {
      this.pathname = this.$route.name;
    },
    updatePatientUid() {
      this.patientUid = this.$route.params.uid;
    },
    ...mapActions(["authAction", "signOutAction", "getCustomClaims"]),
  },
  watch: {
    getUser: {
      immediate: true,
      async handler() {
        const claims = await this.getCustomClaims();
        this.isCoach = claims && claims.coach;
        this.isAdmin = claims && claims.admin;
      },
    },
    patientUid: {
      immediate: true,
      handler() {
        if (this.patientUid)
          this.$rtdbBind("patient", db.ref("patients").child(this.patientUid));
      },
    },
  },
};
</script>

<style lang="sass" scoped>
@import '~/src/styles/quasar.variables.sass'

.menuItem:hover
  background-color: $primary
  color: #ffffff

.active
  background-color: $primary
  color: #ffffff

.journey
  background-color: rgba(79, 44, 10, 1) !important
</style>
