<template lang="pug">
.q-ml-none
  .q-ma-none.q-pa-none.bicara-scoped-template(v-for="(control,index,ind) of uilibs", :key="index")
    .col.no-shadow
      keep-alive
        ComputedComponent(
          :component="control",
          :library="library"
          type="Dashboard",
          :firebase="db",
          :functions="functions",
          :context="context",
          :useloading="true"
        )
    q-separator.q-my-sm(
      v-if="Object.keys(uilibs).length > 1 && ind < Object.keys(uilibs).length - 1"
    )
</template>

<script>
// Designed to be routed to directly /display/:meetingid
import ComputedComponent from "@/components/utils/ComputedComponent.vue";
import { find, includes, isEqual } from "lodash";

import { db, functions } from "@/db";

const BASE_URL = process.env.VUE_APP_PLUGIN_DIRECTORY;

export default {
  name: "DashboardControls",
  plugins: [],
  props: {
    meetingid: {
      type: String,
      required: true,
    },
    sequenceid: {
      require: true,
    },
    currentstate: {
      type: Object,
      required: true,
    },
    onlyglobal: {
      default: false,
    },
    library: {},
  },
  components: {
    ComputedComponent,
  },
  computed: {
    context() {
      return {
        meetingid: this.meetingid,
        sequenceid: this.sequenceid,
        host: true,
      };
    },
    db() {
      return db;
    },
    functions() {
      return functions;
    },
    style() {
      return this.config.style || "";
    },
  },
  data: () => {
    return {
      config: {},
      uilibs: {},
      plugins: null,
    };
  },
  methods: {
    shouldEnable(control) {
      return includes(Object.keys(this.currentstate), control.name);
    },
  },
  watch: {
    config: {
      // deep: true,
      async handler(newVal, oldVal) {
        if (this.plugins == null) {
          let resp = await fetch(`${BASE_URL}index.json`);
          this.plugins = await resp.json();
        }
        // console.log("val changed");
        // console.log(Object.keys(oldVal), Object.keys(newVal));
        let oldKeys = Object.keys(oldVal);
        let newKeys = Object.keys(newVal);
        // console.log(oldKeys, newKeys, isEqual(oldKeys, newKeys));

        //plugins are the same:
        if (!isEqual(oldKeys, newKeys)) {
          //for each key, if that key matches a plugin with an overlay component, then load it if it has an overlay option
          for (let plugneeded of newKeys) {
            //if oldkeys does not in includes the newkey, go get it
            if (!includes(oldKeys, plugneeded)) {
              // console.log(plugneeded);
              let plug = find(this.plugins, function (pl) {
                return pl.plugin.toLowerCase() == plugneeded.toLowerCase();
              });

              // console.log(plug);

              let global = newVal[plugneeded].global;

              if (
                plug &&
                plug.modules[0] &&
                includes(plug.exports, "Dashboard")
              ) {
                if (
                  (this.onlyglobal && global) ||
                  (!this.onlyglobal && !global)
                ) {
                  // console.log("loading", plug);
                  this.$set(this.uilibs, plugneeded, {
                    url: `${BASE_URL}${plug.modules[0].path}`,
                    plugin: plug,
                  });
                }
              }
            } else {
              console.log(`${plugneeded} already loaded`);
            }
          }

          //go through oldkeys that are not in newkeys and remove:
          for (let plugremove of oldKeys) {
            if (!includes(newKeys, plugremove)) {
              this.$delete(this.uilibs, plugremove);
            }
          }
        }
      },
    },
    meetingid: {
      immediate: true,
      async handler() {
        this.$rtdbBind(
          "config",
          db.ref(`config/${this.meetingid}/current/currentState/plugins`)
        );
      },
    },
  },
};
</script>
