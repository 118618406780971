const { last,find } = require("lodash");

export const goBackOne = (transcript,min)=>{
  //get index of current:
  const thisone = find(transcript,function(f){
    return parseSrtTime(f.startTime) == min;
  });

  const newindex = Math.max(0,parseInt(thisone.id) -1);

  const previous = find(transcript,function(f){
    return f.id == newindex;
  });
  return parseSrtTime(previous.startTime);
};

export const goFwdOne = (transcript,min)=>{
  //get index of current:
  const thisone = find(transcript,function(f){
    return parseSrtTime(f.startTime) == min;
  });

  // console.log(thisone.id)

  const newindex = Math.min(Object.keys(transcript).length, parseInt(thisone.id) + 1);
  
  // console.log(newindex)

  const next = find(transcript,function(f){
    return f.id == newindex;
  });
  return parseSrtTime(next.startTime);
};

export const goFwdOneO = (transcript,min)=>{
  //get index of current:
  const thisone = find(transcript,function(f){
    return parseSrtTime(f.endTime) == min;
  });

  // console.log(thisone.id)

  const newindex = Math.min(Object.keys(transcript).length, parseInt(thisone.id) + 1);
  
  // console.log(newindex)

  const next = find(transcript,function(f){
    return f.id == newindex;
  });
  return parseSrtTime(next.endTime);
};

export const goBackOneO = (transcript,min)=>{
  //get index of current:
  const thisone = find(transcript,function(f){
    return parseSrtTime(f.endTime) == min;
  });

  const newindex = Math.max(0,thisone.id -1);

  const previous = find(transcript,function(f){
    return f.id == newindex;
  });
  return parseSrtTime(previous.endTime);
};

export const getNearestStart = (transcript, min) => {
  let bestOne = 0;

  for (let i = 0; i < transcript.length; i++) {
    const item = transcript[i];
    const start = parseSrtTime(item.startTime);
    // const end = parseSrtTime(item.endTime);

    if (start > bestOne && start <= min) bestOne = start;
  }

  // console.log(`orig: ${min}, new: ${bestOne}`);
  return bestOne;
};

export const getNearestEnd = (transcript, max) => {
  let bestOne = Infinity;

  for (let i = 0; i < transcript.length; i++) {
    const item = transcript[i];
    const end = parseSrtTime(item.endTime);
    // const end = parseSrtTime(item.endTime);

    if (end < bestOne && end >= max) bestOne = end;
  }

  if (bestOne == Infinity) bestOne = parseSrtTime(last(transcript).endTime);

  return bestOne;
};

export const getTranscription = (raw, startTime, endTime) => {
  // let before = "";
  // let current = "";
  // let after = "";
  // let beforeCount = 0;
  // let currentCount = 0;
  // let afterCount = 0;
  // let beforeStart = 0;
  // let currentStart = startTime;
  // let afterStart = endTime;
  const beforeArr = [];
  const currentArr = [];
  const afterArr = [];

  // console.log(startTime);
  // console.log(endTime);

  // console.log(`s: ${startTime}, e: ${endTime}`);

  for (let i = 0; i < raw.length; i++) {
    const item = raw[i];
    const start = parseSrtTime(item.startTime);
    const end = parseSrtTime(item.endTime);

    // console.log(`start: ${start}, end: ${end}`);

    //in the 'before times'
    if (start < startTime && end < startTime) {
      beforeArr.push({ start: start, end: end, content: item.text });
    } else if (
      //in the 'after times
      start > endTime
    ) {
      afterArr.push({ start: start, end: end, content: item.text });
    }

    //in the 'between times'
    else {
      currentArr.push({ start: start, end: end, content: item.text });
    }

    //   if (start < startTime) {
    //     before += ` ${item.text}`;
    //     beforeCount++;
    //     if (beforeCount >= 10) {
    //       beforeArr.push({ start: beforeStart, end: end, content: before });
    //       beforeStart = end;
    //       before = "";
    //       beforeCount = 0;
    //     }
    //   } else if (end > endTime) {
    //     after += ` ${item.text}`;
    //     afterCount++;
    //     if (afterCount >= 10) {
    //       afterArr.push({ start: afterStart, end: end, content: after });
    //       afterStart = end;
    //       after = "";
    //       afterCount = 0;
    //     }
    //   } else {
    //     current += ` ${item.text}`;
    //     currentCount++;
    //     if (currentCount >= 10) {
    //       currentArr.push({ start: currentStart, end: end, content: current });
    //       currentStart = end;
    //       current = "";
    //       currentCount = 0;
    //     }
    //   }
  }
  // beforeArr.push({ start: beforeStart, end: startTime, content: before });
  // currentArr.push({ start: currentStart, end: endTime, content: current });
  // afterArr.push({ start: afterStart, end: "", content: after });

  return { beforeArr, currentArr, afterArr };
};

//gets srt time in seconds:
const parseSrtTime = (str) => {
  const arr = str.split(",");
  const tmp = arr[0].split(":");
  const hour = Number(tmp[0]);
  const minute = Number(tmp[1]);
  const second = Number(tmp[2]);
  const millisecond = Number(arr[1]);

  // console.log(hour);

  return (hour * 3600) + (minute * 60) + second + millisecond / 1000;
};
