import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH,
  databaseURL: process.env.VUE_APP_RTDB,
  projectId: process.env.VUE_APP_FB_ID,
  storageBucket: process.env.VUE_APP_FB_BUCKET,
  functions_url: process.env.VUE_APP_FB_FUNCTIONS,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};

firebase.initializeApp(firebaseConfig);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        unsubscribe();
        resolve(user);
      } else resolve(null);
    }, reject);
  });
};

export const db = firebase.database();

if (process.env.VUE_APP_USE_EMULATOR) {
  console.log("USING EMULATORS");
  firebase.functions().useEmulator("localhost", 5001);
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.database().useEmulator("localhost", 9000);
}

export const config = firebaseConfig;
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const functionUrl = firebaseConfig.functions_url;
export const Timestamp = firebase.database.ServerValue.TIMESTAMP;
export const googleProvider = firebase.auth.GoogleAuthProvider;
export const createParentProfileFn = functions.httpsCallable(
  "parentAuth-createParentProfile"
);
export const getUserProfileFn = functions.httpsCallable("pip-getUserProfile");
export const getRecordingForSession = functions.httpsCallable(
  "s3-getRecordingForSession"
);
export const getUserModuleDataFn = functions.httpsCallable(
  "pip-getUserModuleData"
);

export const getAssetsForClient = functions.httpsCallable(
  "assets-getAssetsForClient"
);

export const getRecordingForAsset = functions.httpsCallable(
  "s3-getRecordingForAsset"
);

export const getAdminList = functions.httpsCallable("admins-getAdminList");
export const getAdminInspect = functions.httpsCallable(
  "admins-getAdminInspect"
);
export const listPossibleAdmins = functions.httpsCallable(
  "admins-listPossibleAdmins"
);

export const toggleAdmin = functions.httpsCallable("admins-toggleAdmin");
export const addWhiteList = functions.httpsCallable("admins-addWhiteList");
export const removeWhiteList = functions.httpsCallable(
  "admins-removeWhiteList"
);

export const getStyleForMeeting = functions.httpsCallable(
  "templates-getStyleForMeeting"
);

export const saveStyle = functions.httpsCallable("templates-saveStyle");

export const PERSISTANCE = firebase.auth.Auth.Persistence;
