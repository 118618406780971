<template lang="pug">
.relative-position(
  :class="{ dark: dark && $q.screen.gt.xs, light: !dark || $q.screen.lt.xs }"
)
  .absolute-center
    q-circular-progress(
      :value="currentTime",
      :max="1",
      color="white",
      :size="!dark || $q.screen.lt.sm ? '30px' : '60px'"
    )
  .absolute-center
    q-btn(
      :class="{ dark_btn: dark }",
      round,
      outline,
      :icon="!playing ? 'play_arrow' : 'stop'",
      :size="!dark || $q.screen.lt.sm ? 'sm' : 'lg'",
      @click="play()"
    )
  //- div {{ asset.signedUrl }}
  audio.hidden(
    ref="player",
    @timeupdate="onTimeUpdateListener",
    @pause="onPause",
    @play="onPlay"
  )
    source(:src="asset.signedUrl", type="audio/mp3")
</template>

<script>
// import { getAudioLink } from "@/db";
export default {
  props: ["asset", "dark", "meetingid"],
  data() {
    return {
      currentTime: 0,
      playing: false,
    };
  },
  computed: {},
  // async mounted() {
  //   try {
  //     // console.log(this.asset);

  //     // console.log(this.asset.meetingId);
  //     // console.log(this.asset.patientUid);
  //     const url = await getAudioLink({
  //       assetid: this.asset.id,
  //       meetingid: this.asset.meetingId,
  //       clientid: this.asset.patientUid,
  //     });
  //     this.src = url.data;
  //   } catch {
  //     //do nothing
  //   }
  // },
  methods: {
    // return url.data;
    // return `${this.getS3Link(
    //   this.asset.s3UrlCurated.replace(".mp4", ".mp3")
    // )}`;
    // },
    onPause() {
      this.playing = false;
    },
    onPlay() {
      this.playing = true;
    },
    onTimeUpdateListener() {
      this.currentTime =
        this.$refs.player.currentTime / this.$refs.player.duration;
    },
    // getS3Link(src) {
    //   if (!src) return "";
    //   let url = `${process.env.VUE_APP_CLOUDFRONT_URL}/${src
    //     .replace("s3://", "")
    //     .replace(process.env.VUE_APP_S3_BUCKET, "")}`;
    //   return url;
    // },
    play() {
      if (this.playing) {
        this.$refs.player.pause();
      } else {
        // console.log(this.asset.)
        // this.playing = true;
        this.$refs.player.play();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.light {
  width: 50px;
  height: 50px;
}

.dark {
  width: 60px;
  height: 80px;
}

.dark_btn {
  box-shadow: 0 0 10px black;
  filter: drop-shadow(0 0 10px black);
}
</style>