<template lang="pug">
div.full-width
  .loading.fixed-full.z-top.dimmed.row(v-show="loading").items-center.justify-center
    q-spinner(size="xl")
  .row.full-width(v-if="schedule && curation && curation.suggested")
    //- div {{ meta }}
    //- q-btn.absolute.bg-grey-2.q-mt-sm.q-ml-xs(
    //-   icon="west",
    //-   flat,
    //-   dense,
    //-   round,
    //-   :to="`/dashboard/${patientid}`"
    //- )
    .col-12
      .text-grey-6.text-center.bg-white.q-px-lg.q-pa-sm
        .text-body1 We have selected interesting segments from this session. You can choose to&nbsp;
          span.text-secondary share these with the client&nbsp;
          span or&nbsp;
          span.text-secondary add them to your learning.
          div The algorithm to generate these suggestions is under development, and suggestions are welcome.
      q-stepper.bg-grey-1(
        :contracted="$q.screen.lt.md",
        alternative-labels,
        v-model="step",
        ref="stepper",
        color="primary",
        animated,
        flat
      )
        template(v-slot:navigation)
          q-stepper-navigation(style="margin-top: -28px")
            .row.justify-end
              q-btn.q-ml-sm(
                flat,
                v-if="step > 0",
                color="grey",
                @click="$refs.stepper.previous()",
                label="Back"
              )
              q-btn(
                :disable="step > Object.keys(schedule.script).length - 1",
                rounded,
                @click="$refs.stepper.next()",
                color="secondary",
                label="Continue",
                v-if="step < Object.keys(schedule.script).length"
              )
              q-btn(
                rounded,
                color="green",
                label="Finish Review",
                v-if="step >= Object.keys(schedule.script).length",
                @click="createCurationAsset"
              )
        q-step(
          style="margin-top: -10px",
          v-for="(key, index) in schedule.script",
          :key="`section_${index}`",
          :title="key.name",
          :name="index",
          :prefix="getCount(index)",
          :disable="!recordingList[index]"
        )
          div(v-for="(item, idx) in recordingList[index]")
            q-card.q-mb-xl(v-if="getStatus(item, idx) !== 'removed'")
              q-card-section
                VideoCuration.q-mb-xl(
                  ref="videoPlayer",
                  v-on:onPlay="onPlay($event, this)",
                  :key="`section_${index}_item_${idx}`",
                  :index="idx",
                  :meetingid="meetingid",
                  :patientid="patientid",
                  :url="videoSrc",
                  :startTime="(item.startTime - meta.actualStartTime) / 1000",
                  :endTime="(item.endTime - meta.actualStartTime) / 1000",
                  :item="item",
                  :actualStartTime="meta.actualStartTime",
                  :actualEndTime="meta.actualEndTime",
                  :sections="sections",
                  :slides="slides",
                  :sectionNames="sectionNames",
                  :annotations="annotations",
                  :transcription="transcription",
                  :manualKey="`section_${index}_item_${idx}`",
                  suggested="true",
                  :coachid="meta.uid"
                )

              q-separator(horizontal)

              CurationButtonGroup(
                :item="item",
                :tags="tags",
                :meetingid="meetingid",
                :curation="curation",
                :saveLoading="saveLoading",
                :removeLoading="removeLoading",
                :onClickDelete="onClickDelete",
                :toggleToParents="toggleToParents",
                :toggleToCoach="toggleToCoach"
              )

        q-step(
          title="Custom",
          :name="Object.keys(schedule.script).length",
          :prefix="getCount('manual')"
        )
          .col-12(v-if="curation")
            div(v-for="(item, key) in recordingList['manual']")
              q-card.q-mb-xl(v-if="getStatus(item, key) !== 'removed'")
                q-card-section(style="margin-top: -28px")
                  VideoCuration.q-mb-xl(
                    :key="`section_manual_item_${key}`",
                    :meetingid="meetingid",
                    :patientid="patientid",
                    :url="videoSrc",
                    :startTime="(item.startTime - meta.actualStartTime) / 1000",
                    :endTime="(item.endTime - meta.actualStartTime) / 1000",
                    :item="item",
                    :actualStartTime="meta.actualStartTime",
                    :sections="sections",
                    :slides="slides",
                    :annotations="annotations",
                    :sectionNames="sectionNames",
                    :transcription="transcription",
                    :coachid="meta.uid"
                  )

                q-separator

                CurationButtonGroup(
                  :item="item",
                  :tags="tags",
                  :meetingid="meetingid",
                  :curation="curation",
                  :saveLoading="saveLoading",
                  :removeLoading="removeLoading",
                  :onClickDelete="onClickDelete",
                  :toggleToParents="toggleToParents",
                  :toggleToCoach="toggleToCoach"
                )

          .row.q-gutter-xl.justify-center
            q-btn(flat, icon="add", size="lg", @click="addCurationAsset") Add another segment

    //- q-dialog(v-model="confirm")
    //-   q-card
    //-     q-card-section.row.items-center
    //-       q-icon(name="report", color="primary", size="lg")
    //-       .text-subtitle1.q-ml-md Are you sure you want to remove this segment?
    //-     q-card-actions(align="right")
    //-       q-btn(flat, rounded, label="Cancel", color="primary", v-close-popup)
    //-       q-btn(
    //-         flat,
    //-         rounded,
    //-         label="Confirm",
    //-         @click="removeFromList",
    //-         v-close-popup
    //-       )

  .row.q-mb-sm.absolute-center.justify-center.text-center(
    v-else-if="Object.keys(curation).length === 0"
  )
    q-icon.q-mb-md.full-width(name="beenhere", color="primary", size="4.5em")
    .text-h5.text-secondary.full-width This session is complete.
    .text-body1.text-negative.full-width(
      v-if="transcriptionObj && transcriptionObj.status == 'error'"
    ) There was an error in transcribing this session. Please contact Bicara support.
      .text-overline {{ transcriptionObj.error }}
    .text-h5.text-primary.full-width(
      v-if="(transcriptionObj && transcriptionObj.status != 'error') || !transcriptionObj"
    ) You will be notified once it is ready for review.
    q-btn(flat, :to="`/dashboard/${patientid}`") Back to Dashboard
</template>

<script>
import { mapGetters } from "vuex";
import { db, getRecordingForSession } from "@/db";
import { find, map, filter, groupBy, isObject } from "lodash";
import { QSpinnerBars } from "quasar";
import VideoCuration from "./VideoCuration.vue";
import CurationButtonGroup from "./CurationButtonGroup.vue";
import { getMediaConvertTimeCode } from "../../utils/formatter";
import moment from "moment-timezone";
import notifications from "../../utils/notifications";

export default {
  mixins: [notifications],
  props: {
    meetingid: {
      required: true,
    },
    patientid: {
      required: true,
    },
    actualEndTime: {
      required: true,
    },
    meta: {
      required: true,
    },
  },
  components: { VideoCuration, CurationButtonGroup },
  computed: {
    db() {
      return db;
    },
    annotations() {
      let annotation_keys = filter(
        Object.keys(this.slideChange?.current ?? {}),
        (o) => o.startsWith("_annotation")
      );

      let expt = {};

      //find the last timestamp for which each annotation exists: (inefficient)
      for (const key of annotation_keys) {
        let stamp = -1;
        for (const time in this.slideChange._history) {
          if (Object.keys(this.slideChange._history[time]).includes(key)) {
            //this event is for this annotation:
            if (time > stamp) {
              stamp = time;
            }
          }
        }

        //calculate time from start of meeting:
        // Math.round(timestamp * 10) / 10
        stamp =
          Math.round(((stamp - this.meta.actualStartTime) / 1000) * 10) / 10;
        expt[stamp] = this.slideChange.current[key];
      }

      return expt;
    },
    sections() {
      if (this.sectionChange) {
        let allHistory = this.sectionChange["_history"];
        // Append the meeting end time to get the duration for the last section
        allHistory[this.actualEndTime] = { _currentSection: -1 };
        const keys = Object.keys(allHistory);

        const sectionMap = {};
        for (let i = 1; i < keys.length; i++) {
          const duration = keys[i] - keys[i - 1];
          const prev = allHistory[keys[i - 1]]["_currentSection"];

          if (!sectionMap[prev] || sectionMap[prev].duration < duration)
            sectionMap[prev] = {
              duration,
              startTime: keys[i - 1],
              endTime: keys[i],
            };
        }
        return sectionMap;
      }
      return null;
    },
    slides() {
      if (this.slideChange && this.meta) {
        const noOfSections = Object.keys(this.schedule.script).length;

        const resultArr = {};
        let total = 0;

        for (let i = 0; i < noOfSections; i++) {
          const section = this.sections[i];
          // console.log(this.sections);

          if (section) {
            let nextSectionIndex = i + 1;

            if (i < noOfSections) {
              while (
                typeof this.sections[nextSectionIndex] === "undefined" &&
                nextSectionIndex < noOfSections - 1
              )
                nextSectionIndex++;

              // console.log(`ended while loop at ${nextSectionIndex} for ${i}`);
            }

            // const nextStart =
            //   i < noOfSections - 1
            //     ? this.sections[i + 1].startTime
            //     : this.meta.actualEndTime;

            const nextStart =
              i < noOfSections - 1
                ? this.sections[nextSectionIndex].startTime
                : this.meta.actualEndTime;

            const slideMap = this.getSlideTimestamps(
              section,
              nextSectionIndex,
              nextStart
            );
            const sectionSlides = this.getSectionSlides(i);
            const noOfSlides = sectionSlides.length;

            for (let j = 0; j < noOfSlides; j++) {
              const current = sectionSlides[j];

              if (slideMap[j]) {
                resultArr[total] = {
                  ...slideMap[j],
                  type: "slide",
                  slideContent: current,
                };
                ++total;
              }
            }
          }
        }
        return resultArr;
      }
      return null;
    },
    sectionNames() {
      // console.log(Object.keys(this.schedule.script));
      return map(this.schedule.script, "name");
    },
    ...mapGetters(["getUser"]),
  },
  methods: {
    getCount(index) {
      if (this.recordingList[index]) {
        let items = filter(
          this.recordingList[index],
          (s) => s.status != "removed"
        );
        // console.log(items);
        return items.length;
      } else {
        return 0;
      }
    },
    onPlay(obj) {
      // console.log("playing", obj);

      for (const video of this.$refs.videoPlayer) {
        // console.log(video);
        if (video != obj) video.pause();
      }
    },
    getStatus(item, key) {
      return item.status;
      // if (!this.curation) return;
      // if (item.type === "manual") return this.curation["manual"][key].status;
      // return this.curation[item.startTime][item.endTime].status;
    },
    async saveForCuration(item, doit) {
      this.saveLoading = true;
      const { type } = item;
      // const dataPath = `datathis.getDataPath(item);
      let newstatus = "saved";

      // console.log(doit);

      if (doit) {
        newstatus = "saved";
      } else {
        newstatus = type === "manual" ? "manual" : "suggested";
      }

      // console.log(newstatus);

      // console.log(item.key);

      try {
        await db
          .ref("data/curation")
          .child(this.meetingid)
          .child(item.key)
          .update({
            status: newstatus,
          });
      } catch (e) {
        this.showError(e);
      }

      this.saveLoading = false;
    },
    onClickDelete(item) {
      // this.confirm = true;
      // this.currentItem = item;
      // console.log(item);
      this.$q
        .dialog({
          title: "Confirm Delete",
          message: "Would you like to remove this segment?",
          cancel: true,
          persistent: true,
        })
        .onOk(async () => {
          this.removeLoading = true;
          try {
            await db
              .ref("data/curation")
              .child(this.meetingid)
              .child(item.key)
              .update({ status: "removed" });

            item.status = "removed";
          } catch (e) {
            this.showError(e);
          }
          // this.$delete(this.recordingList["manual"], item.key);
          this.removeLoading = false;
        });
    },
    async toggleToParents(item) {
      // Whether the artefact will be used for parents' learning journey
      const timestamp = Date.now();
      // const dataPath = this.getDataPath(item);

      try {
        await Promise.all([
          db
            .ref("data/curation")
            .child(
              `${this.meetingid}/${item.key}/_toParentsHistory/${timestamp}`
            )
            .set({ toParents: !item.toParents }),

          db
            .ref("data/curation")
            .child(`${this.meetingid}/${item.key}/toParents`)
            .set(!item.toParents),
        ]);

        item.toParents = !item.toParents;

        if (item.toParents || item.toCoach) {
          this.saveForCuration(item, true);
        } else {
          this.saveForCuration(item, false);
        }
      } catch (e) {
        this.showError(e);
      }
    },
    async toggleToCoach(item) {
      // Whether the artefact will be used for coachs' self reflection
      const timestamp = Date.now();
      // const dataPath = this.getDataPath(item);
      try {
        await db
          .ref("data/curation")
          .child(`${this.meetingid}/${item.key}/_toCoachHistory/${timestamp}`)
          .set({ toCoach: !item.toCoach });

        await db
          .ref("data/curation")
          .child(`${this.meetingid}/${item.key}/toCoach`)
          .set(!item.toCoach);

        item.toCoach = !item.toCoach;

        if (item.toParents || item.toCoach) {
          this.saveForCuration(item, true);
        } else {
          this.saveForCuration(item, false);
        }
      } catch (e) {
        this.showError(e);
      }
    },
    async addCurationAsset() {
      try {
        let obj = {
          startTime: this.meta.actualStartTime,
          endTime: this.actualEndTime,
          toCoach: false,
          toParents: false,
          status: "manual",
          type: "manual",
          section: "manual",
          duration: this.actualEndTime - this.meta.actualStartTime,
        };
        let temp = await db.ref(`data/curation/${this.meetingid}`).push(obj);

        if (!this.recordingList["manual"]) this.recordingList["manual"] = [];

        this.recordingList["manual"].push({ ...obj, key: temp.key });
      } catch (e) {
        this.showError(e);
      }
      // console.log(temp);

      //add it to the local copy:
    },
    async createCurationAsset() {
      this.$q.loading.show({
        spinner: QSpinnerBars,
        message: "Creating content. Please wait...",
      });

      try {
        const assets = [];

        Object.keys(this.curation).forEach((key) => {
          let {
            duration,
            section,
            status,
            title,
            editCurrent,
            toCoach,
            toParents,
            type,
            labels,
          } = this.curation[key];

          // console.log(status);

          if (status === "saved") {
            // console.log(editCurrent);
            // let { startTime, endTime } = editCurrent;
            const startTime =
              editCurrent && typeof editCurrent.startTime != "undefined"
                ? editCurrent.startTime
                : (this.curation[key].startTime - this.meta.actualStartTime) /
                  1000;
            const endTime =
              editCurrent && typeof editCurrent.endTime != "undefined"
                ? editCurrent.endTime
                : (this.curation[key].endTime - this.meta.actualStartTime) /
                  1000;

            // console.log(startTime);
            // console.log(getMediaConvertTimeCode(startTime));
            // console.log(endTime);
            // console.log(getMediaConvertTimeCode(endTime));
            if (!labels) labels = [];

            assets.push({
              template: this.meeting.template,
              createdAt: moment().unix(),
              duration,
              section: section ? section : "",
              title: title ? title : "Selected Segment",
              toCoach,
              toParents,
              labels,
              type,
              s3UrlVideo: this.recording.s3UrlVideo,
              startTimecode: getMediaConvertTimeCode(startTime),
              endTimecode: getMediaConvertTimeCode(endTime),
            });
          }
        });

        await db
          .ref(`data/curationAsset/${this.meetingid}/remaining`)
          .set(assets.length);

        const promises = [];
        assets.forEach((asset) => {
          promises.push(
            db.ref(`data/curationAsset/${this.meetingid}`).push(asset)
          );
        });
        await Promise.all(promises);

        setTimeout(async () => {
          //REMOVED FOR DEBUG
          await db
            .ref(`meetings/${this.getUser.uid}/${this.meetingid}/curated`)
            .set(true);

          this.$q.loading.hide();
        }, 2000);
      } catch (e) {
        this.showError(e);
        this.$q.loading.hide();
      }
    },
    getSlideTimestamps(current, index, nextStart) {
      let allHistory = this.slideChange["_history"];

      allHistory = Object.fromEntries(
        Object.entries(allHistory).filter(
          (history) =>
            history[1]["_slide"] !== undefined &&
            history[0] >= current.startTime &&
            history[0] <= current.endTime
        )
      );
      allHistory[nextStart] = { _slide: -1 };

      const keys = Object.keys(allHistory);
      const slideMap = {};

      for (let i = 1; i < keys.length; i++) {
        const duration = keys[i] - keys[i - 1];
        const prev = allHistory[keys[i - 1]]["_slide"];

        if (!slideMap[prev] || slideMap[prev].duration < duration)
          slideMap[prev] = {
            duration,
            startTime: keys[i - 1],
            endTime: keys[i],
            sectionIdx: index,
          };
      }

      return slideMap;
    },
    getSectionSlides(index) {
      const plugins = this.schedule.script[index].plugins;
      const tmp = find(plugins, (plugin) => plugin.plugin === "Slides");

      if (tmp) return tmp.settings.content;
      return [];
    },
  },
  data: () => {
    return {
      loading: true,
      step: 1,
      tagging: null,
      recording: null,
      slideChange: null,
      sectionChange: null,
      schedule: null,
      currentSection: null,
      curation: {},
      removeLoading: false,
      saveLoading: false,
      currentItem: null,
      transcription: null,
      transcriptionObj: null,
      tags: [],
      recordingList: {},
      meeting: {},
      videoSrc: null,
    };
  },
  async mounted() {
    // this.$q.loading.show({
    //   customClass: "bg-grey-4",
    // });
    // console.log(this.meta);
    try {
      await Promise.all([
        this.$rtdbBind("tagging", db.ref("data/tagging").child(this.meetingid)),
        this.$rtdbBind(
          "recording",
          db.ref("data/recording").child(this.meetingid)
        ),
        this.$rtdbBind(
          "slideChange",
          db.ref("data/slides").child(this.meetingid)
        ),
        this.$rtdbBind(
          "sectionChange",
          db.ref("data/sectionChange").child(this.meetingid)
        ),
        this.$rtdbBind("curation", db.ref(`data/curation/${this.meetingid}`)),
        this.$rtdbBind(
          "meeting",
          db.ref(`meetings/${this.getUser.uid}/${this.meetingid}`)
        ),
        this.$rtdbBind(
          "schedule",
          db.ref(`config/${this.meetingid}/schedule/schedule`)
        ),
        this.$rtdbBind("tags", db.ref(`meta/tags`)),
        // this.$rtdbBind("meta", db.ref(`data/meetingMeta/${this.meetingid}`)),
      ]);

      //load video src:
      const url = await getRecordingForSession({
        meetingid: this.meetingid,
        coachid: this.meta.uid,
      });
      // console.log(url.data);
      // this.$set(this.playOptions.sources[0], "src", url.data);
      this.videoSrc = url.data;
      // this.playerOptions.sources.push({
      //   type: "video/mp4",
      //   src: url.data,
      // });

      // this.$rtdbUnbind("curation", true),
      // console.log(this.curation);
      // this.recordingList = this.getRecordingList();

      // console.log(this.curation[".key"]);

      for (const key in this.curation) {
        // console.log(key);
        // console.log(this.curation[key][".key"]);
        if (isObject(this.curation[key])) this.curation[key].key = key;
      }

      this.recordingList = groupBy(this.curation, "section");

      // console.log(this.recordingList);

      // Set the q-stepper step to the first section which has a suggested curation asset
      // let index = 0;
      // Object.keys(this.recordingList).forEach((section) => {
      //   if (this.recordingList[section].length > 0) return true;
      //   index++;
      // });

      // console.log(recordingList);

      // console.log(Object.keys(this.recordingList)[0]);

      this.step = parseInt(Object.keys(this.recordingList)[0]);

      const transcriptionSnap = (
        await db.ref(`data/transcription/${this.meetingid}`).once("value")
      ).val();
      if (transcriptionSnap) {
        this.transcriptionObj = transcriptionSnap;
        this.transcription = transcriptionSnap.raw;
      }
    } catch (e) {
      this.showError(e);
    }

    // this.$q.loading.hide();
    this.loading = false;
  },
  created() {},
};
</script>

<style lang="sass" scoped>
.asset-card
  border-radius: 20px
  width: 100%
  height: 00%

.slide
  color: white
  border-radius: 5px
  padding: 8px
  font-size: 14px
  overflow: hidden
</style>
