var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"q-px-md"},[_c('h1',[_vm._v("User Documentation")]),_c('div',{staticClass:"text-body1"},[_vm._v("This documentation is provided as a quick reference, not a comprehensive user guide. Please contact the Bicara administrator in your organisation for more detailed information.")]),_vm._m(0),_c('q-separator',{staticClass:"q-mt-lg"}),_c('h2',[_vm._v("Adding Clients")]),_c('div',{staticClass:"text-body1"},[_vm._v("Bicara has a limited client management system, allowing you to schedule and run sessions.")]),_vm._m(1),_c('q-separator',{staticClass:"q-mt-lg"}),_c('h2',[_vm._v("Scheduling Sessions")]),_c('div',{staticClass:"text-body1"},[_vm._v("Scheduling is managed on a shared Google calendar, to which you have access. When a session is sheduled in Bicara it will appear on the shared calendar immediatly, however there will a short delay until it appears within your Bicara dashboard.")]),_vm._m(2),_c('q-separator',{staticClass:"q-mt-lg"}),_c('h2',[_vm._v("Preparing a Session")]),_c('div',{staticClass:"text-body1"},[_vm._v("Once you schedule a session, you have the ability to prepare for this session at any point before the scheduled start time. The session will only start for you and the client once you click the 'Start Session' button, allowing you to review notes etc. if required. Once the session is started however, you cannot go back to this preparation screen.")]),_vm._m(3),_c('q-separator',{staticClass:"q-mt-lg"}),_c('h2',[_vm._v("Running a Session")]),_vm._m(4),_c('q-separator',{staticClass:"q-mt-lg"}),_c('h2',[_vm._v("Session Review")]),_c('div',{staticClass:"text-body1"},[_vm._v("After each session, the coach has the opportunity to select specific sections of the session and share them with the client or keep for their own learning.")]),_vm._m(5)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-green-2 q-my-md q-pa-sm rounded-borders"},[_c('p',[_vm._v("Things to note:")]),_c('ul',[_c('li',[_vm._v("You are called a 'coach' in Bicara.")]),_c('li',[_vm._v("The person you are talking to is called a 'client' in Bicara.")]),_c('li',[_vm._v("The client will not be aware this system is called Bicara - as it is branded to the specific programme you are delivering.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-green-2 q-my-md q-pa-sm rounded-borders"},[_c('p',[_vm._v("Common questions:")]),_c('ul',[_c('li',[_c('strong',[_vm._v("Where do I put my case notes?")]),_c('p',[_vm._v("Case notes should be recorded and stored in your existing client management processes.")])]),_c('li',[_c('strong',[_vm._v("Where do I get the client ID from?")]),_c('p',[_vm._v("The client ID should be the unique indentifier from your existing client management system, for example the UID from Partners in Parenting.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-green-2 q-my-md q-pa-sm rounded-borders"},[_c('p',[_vm._v("Common questions:")]),_c('ul',[_c('li',[_c('strong',[_vm._v("How do I change the time of a session?")]),_c('p',[_vm._v("Move the session in Google calendar to the new time. Within a couple of minutes, these changes will be reflected in Bicara.")])]),_c('li',[_c('strong',[_vm._v("How do I cancel a session?")]),_c('p',[_vm._v("Delete the session from Google calendar. Within a couple of minutes, these changes will be reflected in Bicara.")])]),_c('li',[_c('strong',[_vm._v("How do I chance the coach for a session?")]),_c('p',[_vm._v("This functionality is currently not supported.")])]),_c('li',[_c('strong',[_vm._v("Can I schedule multiple sessions in the future?")]),_c('p',[_vm._v("Yes, schedule as many as you want ahead of time, however note that you cannot change the template for a session once it has been scheduled.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-green-2 q-my-md q-pa-sm rounded-borders"},[_c('p',[_vm._v("Common questions:")]),_c('ul',[_c('li',[_c('strong',[_vm._v("How do I change the slide content for a session?")]),_c('p',[_vm._v("All non-editable slide content is designed and configured by your program administrator. You only have the ability to edit client related fields on these slides during session preparation.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-green-2 q-my-md q-pa-sm rounded-borders"},[_c('p',[_vm._v("Common questions:")]),_c('ul',[_c('li',[_c('strong',[_vm._v("Why does the link on the calendar not work for me?")]),_c('p',[_vm._v("The link in the calendar description is for the client only. As a coach, you should access the session via the Bicara dashboard.")])]),_c('li',[_c('strong',[_vm._v("What happens if I need to change the order of content in a session?")]),_c('p',[_vm._v("You can go backwards and forwards through the session script at any time in a session.")])]),_c('li',[_c('strong',[_vm._v("What happens if my camera or mic stops working during a session?")]),_c('p',[_vm._v("Simply refresh the browser - Bicara will appear exactly as you left off, and will usually re-connect to your devices.")])]),_c('li',[_c('strong',[_vm._v("What happens if I accidentally end the session?")]),_c('p',[_vm._v("You cannot restart a closed session.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-green-2 q-my-md q-pa-sm rounded-borders"},[_c('p',[_vm._v("Common questions:")]),_c('ul',[_c('li',[_c('strong',[_vm._v("Why can I not access the session review screen?")]),_c('p',[_vm._v("Session review can only commence when the automatic transcription is complete. This may take a few minutes. You will be notified by email when the session is ready to be reviewed.")])]),_c('li',[_c('strong',[_vm._v("How do I add extra notes for the client?")]),_c('p',[_vm._v("Currently, the 'Make additional notes' field turns into extra prompts for the client (or if just used for your learning, this is the title of the segment).")])])])])
}]

export { render, staticRenderFns }