<template lang="pug">
q-card-actions(v-if="curation")
  .col-6
    //- div {{ item }}
    .row.q-col-gutter-sm
      .col
        q-select(
          color="secondary",
          filled,
          dense,
          v-model="labels",
          use-input,
          option-label="title",
          option-value="name",
          use-chips,
          multiple,
          emit-value,
          input-debounce="0",
          @new-value="createValue",
          :options="tags",
          @filter="filterFn",
          @input="updateLabels",
          map-options
        )
          template(v-slot:option="scope")
            q-item(v-bind="scope.itemProps", v-on="scope.itemEvents")
              q-item-section(avatar)
                q-avatar(round, size="sm", :class="[getIcon(scope.opt)]")
              q-item-section
                q-item-label {{ scope.opt.title }}
                q-item-label(caption) {{ scope.opt.content }}
                q-item-label(caption, v-if="scope.opt.feedback")
                  q-icon(name="check_box")
                  span {{ scope.opt.feedback }}
            q-separator

    .row
      .col.q-pt-sm
        q-input(
          filled,
          dense,
          v-model="title",
          @input="titleUpdate",
          placeholder="Make additional notes (optional)"
        )
          template(v-slot:prepend)
            q-icon(name="title")
  .col-6
    .row.justify-end.q-pl-sm
      .column.items-bottom
        q-btn.q-pr-xs(
          v-if="getStatus(item) === 'manual'",
          flat,
          icon="delete",
          @click="onClickDelete(item)",
          :loading="removeLoading"
        )
      .column.q-gutter-sm
        q-btn.q-pr-xs(
          :color="item.toParents ? 'green-8' : 'primary'",
          rounded,
          :outline="item.toParents ? false : true",
          :icon="item.toParents ? 'supervisor_account' : 'o_supervisor_account'",
          label="Share with Client",
          size="13px",
          @click="toggleToParents(item)"
        )
          q-inner-loading(:showing="saveLoading", size="15px")

        q-btn.q-pr-xs(
          :color="item.toCoach ? 'green-8' : 'primary'",
          rounded,
          :outline="item.toCoach ? false : true",
          :icon="item.toCoach ? 'lightbulb' : 'o_lightbulb'",
          label="Add to My Learning",
          size="13px",
          @click="toggleToCoach(item)"
        )
          q-inner-loading(:showing="saveLoading", size="15px")
</template>

<script>
import { db } from "@/db";
import { debounce } from "quasar";
import { includes, map } from "lodash";
import notifications from "../../utils/notifications";

// const stringOptions = ["Learning 1", "Feedback 1"];

export default {
  mixins: [notifications],
  props: {
    item: {
      required: true,
    },
    meetingid: {
      required: true,
    },
    curation: {
      required: true,
    },
    saveLoading: {
      required: true,
    },
    removeLoading: {
      required: true,
    },

    onClickDelete: {
      required: true,
    },
    toggleToParents: {
      required: true,
    },
    toggleToCoach: {
      required: true,
    },
    tags: {
      required: true,
    },
  },
  components: {},
  computed: {
    db() {
      return db;
    },
  },
  methods: {
    getIcon(label) {
      // if (asset.labels == null) return `tag_water`;
      // console.log(asset.labels);
      // console.log(label);
      return `tag_${label?.icon}` || `tag_water`;
      // return "tag_water";
    },
    async createValue(val, done) {
      // Calling done(var) when new-value-mode is not set or "add", or done(var, "add") adds "var" content to the model
      // and it resets the input textbox to empty string
      // ----
      // Calling done(var) when new-value-mode is "add-unique", or done(var, "add-unique") adds "var" content to the model
      // only if is not already set
      // and it resets the input textbox to empty string
      // ----
      // Calling done(var) when new-value-mode is "toggle", or done(var, "toggle") toggles the model with "var" content
      // (adds to model if not already in the model, removes from model if already has it)
      // and it resets the input textbox to empty string
      // ----
      // If "var" content is undefined/null, then it doesn't tampers with the model
      // and only resets the input textbox to empty string

      if (val.length > 0) {
        const newobj = { name: val.replaceAll(" ", "_"), title: val };
        if (!includes(this.tags, { name: newobj.name })) {
          let newtags = [...this.tags, newobj];
          await db.ref("meta/tags").set(newtags);
        }
        done(newobj, "toggle");
      }
    },

    filterFn(val, update) {
      update(() => {
        if (val === "") {
          this.filterOptions = this.tags;
        } else {
          const needle = val.toLowerCase();
          this.filterOptions = this.tags.filter(
            (v) => v.name.toLowerCase().indexOf(needle) > -1
          );
        }
      });
    },
    getStatus(item) {
      return item.type;
    },
    async updateLabels() {
      const { startTime, endTime } = this.item;

      const dataPath = `${this.meetingid}/${this.item.key}`;

      try {
        await db
          .ref("data/curation")
          .child(`${dataPath}/labels`)
          .set(this.labels);
      } catch (e) {
        this.showError(e);
      }
    },
    titleUpdate: debounce(async function () {
      const { startTime, endTime } = this.item;

      const dataPath = `${this.meetingid}/${this.item.key}`;
      try {
        await db
          .ref("data/curation")
          .child(`${dataPath}/title`)
          .set(this.title);
      } catch (e) {
        this.showError(e);
      }
    }, 2000),
    async tagUpdate() {
      const { startTime, endTime } = this.item;
      const timestamp = Date.now();

      const dataPath = `${this.meetingid}/${this.item.key}`;
      try {
        await db
          .ref("data/curation")
          .child(`${dataPath}/_tagHistory/${timestamp}`)
          .set({ tag: this.select });

        await db.ref("data/curation").child(`${dataPath}/tag`).set(this.select);
      } catch (e) {
        this.showError(e);
      }
    },
  },
  data() {
    return {
      title: this.item.title,
      select: this.item.tag,
      labels: this.item.labels,
    };
  },
};
</script>

<style lang="sass" scoped>
@import "~quasar-variables-styl"
</style>
