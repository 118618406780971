<template lang="pug">
div.full-width
  .row 
    Tagging(
      :key="this.item.key",
      :meetingid="meetingid",
      :actualStartTime="actualStartTime",
      :actualEndTime="actualEndTime"
    )
  .row.full-width
    .col-auto.text-grey-6(style="width: 48px; padding-top: 14px") 00:00
    .col
      q-slider(
        :readonly="true"
        v-model="currentTime",
        :label-value="getTime(currentTime)"
        :min="0",
        :max="duration",
        label-always,
        track-size="8px",
        track-color="grey-1",
        thumb-size="22px",
        color="secondary",
        :step="0.1",
        marker-labels,
        
        style="height: 90px; padding-top: 20px",
        ref="slider"
      )
        template(v-slot:marker-label-group="scope")
          div(
            v-for="marker in scope.markerList",
            v-if="getSectionIndex(marker.value) >= 0 || (getSlideIndex(marker.value) >= 0 && hasInput(marker.value)) || getAnnotation(marker.value)",
            :class="[`text-deep-orange-${2 + Math.ceil(marker.value / 2)}`, marker.classes]",
            :key="marker.index",
            :style="marker.style"
          ) 
            .row(v-if="getSlideIndex(marker.value) >= 0")
              .col-12
                .slide-change(style="height: 15px; margin-bottom: -3px")
              .col-12
                q-badge.cursor-pointer(rounded, color="blue-3")
                  q-tooltip(max-width="300px")
                    div(v-for="input in getInputForSlide(marker.value)")
                      .text-body1.text-bold {{ input.label }}
                      Viewer(:initialValue="input.value")

            .row(
              v-else-if="getAnnotation(marker.value)",
              style="margin-top: -50px"
            )
              .col-12
                q-badge.cursor-pointer(rounded, color="red-3")
                  q-tooltip(max-width="300px")
                    Viewer(:initialValue="getAnnotation(marker.value)")
                    //- .text-body1 {{ getAnnotation(marker.value) }}
              .col-12
                .slide-change.annotation(style="height: 30px; margin-top: -8px")
            .row(v-else)
              .col-12
                .border-section(style="height: 60px")
              .col-12
                q-chip(
                  color="green-3",
                  size="10px",
                  style="transform: translate(-40%, -13px) rotate(315deg); transform-origin: 100% 50%"
                ) {{ sectionNames[getSectionIndex(marker.value)] }}

    .col-auto.text-grey-6.text-right(style="width: 48px; padding-top: 14px") {{ getRangeLabel(duration) }}
</template>

<script>
import { db } from "@/db";
// import Markdown from "@/components/utils/Markdown.vue";
import { formatMinuteSecond } from "../../utils/formatter";
import notifications from "../../utils/notifications";
import Tagging from "./Tagging.vue";
import { Viewer } from "@toast-ui/vue-editor";

let regex = /^:::\s(s-)?(\w*):(.*)$/gm;

export default {
  name: "Slider",
  mixins: [notifications],
  props: [
    "currentTime",
    "annotations",
    "meetingid",
    "patientid",
    "duration",
    "startTime",
    "endTime",
    "item",
    "slideChange",
    "slides",
    "sections",
    "sectionNames",
    "actualStartTime",
    "actualEndTime",
    "transcript",
    "assets",
  ],
  components: { Tagging, Viewer },
  data() {
    return {
      show: true,
      slide: 0,
      temprange: {
        min: this.item.editCurrent
          ? this.item.editCurrent.startTime
          : this.startTime,
        max: this.item.editCurrent
          ? this.item.editCurrent.endTime
          : this.endTime,
      },
      range: {
        min: this.item.editCurrent
          ? this.item.editCurrent.startTime
          : this.startTime,
        max: this.item.editCurrent
          ? this.item.editCurrent.endTime
          : this.endTime,
      },
      sectionTimestamp: [],
      slideTimestamp: [],
      sequenceinputs: {},
      meetinginputs: {},
    };
  },
  methods: {
    getAsset() {
      return true;
    },
    getTime(t) {
      let hours = Math.floor(this.currentTime / (60 * 60));
      // let mins = 0,
      // let secs = 0;
      let mins = Math.floor((this.currentTime - hours * 60 * 60) / 60);
      let secs = Math.floor(this.currentTime - hours * 60 * 60 - mins * 60);

      return `${hours}:${mins.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`;
    },
    getInput(type, field) {
      if (type === "s-") return this.sequenceinputs[field] || "";
      else return this.meetinginputs[field] || "";
    },
    hasInput(index) {
      let content = this.slides[this.getSlideIndex(index)].slideContent;
      let result = content.match(/:::/);

      return result != null;
    },
    getInputForSlide(index) {
      let content = this.slides[this.getSlideIndex(index)].slideContent;

      let inputs = [...content.matchAll(regex)];

      let tmp = [];

      for (let field of inputs) {
        tmp.push({
          label: field[3],
          value: this.getInput(field[1], field[2]),
        });
      }

      return tmp;
    },
    getAnnotation(index) {
      //index is seconds:
      return this.annotations[this.formatTimestamp(index)];
    },

    getRangeLabel(val) {
      return formatMinuteSecond(val);
    },
    formatTimestamp(timestamp) {
      return Math.round(timestamp * 10) / 10;
    },
    getSectionIndex(value) {
      return this.sectionTimestamp.indexOf(this.formatTimestamp(value));
    },
    getSlideIndex(value) {
      return this.slideTimestamp.indexOf(this.formatTimestamp(value));
    },
  },
  mounted() {
    // console.log(this.sectionNames);

    // console.log(`duration: ${this.duration}`);
    try {
      const sectionTimestamp = [];
      Object.keys(this.sections).forEach((key) => {
        sectionTimestamp.push(
          this.formatTimestamp(
            (this.sections[key].startTime - this.actualStartTime) / 1000
          )
        );
      });
      this.sectionTimestamp = sectionTimestamp;

      const slideTimestamp = [];
      Object.keys(this.slides).forEach((key) => {
        slideTimestamp.push(
          this.formatTimestamp(
            (this.slides[key].startTime - this.actualStartTime) / 1000
          )
        );
      });
      this.slideTimestamp = slideTimestamp;

      this.$rtdbBind(
        "sequenceinputs",
        db.ref(`sequence/slides/${this.patientid}/current`)
      );

      this.$rtdbBind(
        "meetinginputs",
        db.ref(`data/slides/${this.meetingid}/current/`)
      );
    } catch (e) {
      this.showError(e);
    }
  },
};
</script>

<style lang="sass" scoped>
@import '../../styles/quasar.variables'

.border-section
  position: inherit
  border-left: 2px solid #eee
  transform: translate(50%)

.slide-change
  position: inherit
  border-left: 2px solid #eee
  transform: translate(35%)

.annotation
  border-color: $red-3
  border-width: 1px
  transform: translate(45%)

.scaled
  width: 200px
  height: 200px
  overflow: hidden
  transform-origin: top left
</style>

<style lang="sass">
.q-slider__track-container--h
  padding: 0
</style>
