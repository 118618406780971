<template lang="pug">
#session.full-width(@error="errorHandler")
  v-style {{ cssPropsOutput }}
  .row
    .col(v-bind:class="{ 'col-6': !scalePatient }")
      q-resize-observer(@resize="onResize", debounce="200", ref="resizeMe")
      
      #subscriberContainer().full-height.full-width
        .row.full-width.justify-center.items-center.full-height(v-if="validStreams.length")
          //- div {{getSubscriberWithVideo()}}
          subscriber.subscribers.col(
              :key="stream.streamId"
              v-for="stream of validStreams"
              :actualHeight="(validStreams.length==1)?subscriberHeight:subscriberHeight/2",
              :stream="stream",
              :session="session",
              :opts="subscriberOptions",
              @error="errorHandler"
            )
        .subscribers(v-if="validStreams.length == 0")
          .absolute-center.text-grey-7.text-center(v-if="!scalePatient") Waiting for client to join...
          .absolute-center.text-grey-7.text-center(v-if="scalePatient") Waiting for coach to join...
        OverlayDisplay.scalePatient(
          v-if="scalePatient",
          nonInteractive,
          :meetingid="meetingid",
          :sequenceid="patientid"
        )

    div(v-bind:class="{ 'col-6 col': !scalePatient }")
      div(v-bind:id="!scalePatient ? 'publisherContainer' : 'parentContainer'")
        publisher(
          v-bind:class="{ producerParentScale: scalePatient }",
          :session="session",
          :opts="publisherOptions",
          @error="errorHandler",
          :audioInput="audioInput",
          :videoInput="videoInput",
          :actualHeight="publisherHeight",
          :isMuted="isMuted",
          ref="publisher"
        )
        OverlayDisplay.scaleCoach(
          v-if="!scalePatient",
          :host="true",
          :meetingid="meetingid",
          :sequenceid="patientid"
        )
  .row.justify-center
    q-btn.q-mt-sm(
      :icon="isMuted ? 'mic_off' : 'mic'",
      :color="isMuted ? 'red-1' : 'red-4'",
      :text-color="!isMuted ? 'red-1' : 'red-4'",
      v-if="scalePatient",
      @click="muteToggle",
      round
    )
    q-btn.fixed.z-top(
      style="left: 100px; bottom: 13px",
      size="sm",
      :icon="isMuted ? 'mic_off' : 'mic'",
      :color="isMuted ? 'red-1' : 'red-4'",
      :text-color="!isMuted ? 'red-1' : 'red-4'",
      v-if="!scalePatient",
      @click="muteToggle",
      round
    )
</template>

<script>
import OT from "@opentok/client";
// OT.setLogLevel(OT.DEBUG);
OT.on("exception", function (event) {
  console.log(event.message);
});
import Publisher from "@/components/OpenTok/Publisher.vue";
import Subscriber from "@/components/OpenTok/Subscriber.vue";
import OverlayDisplay from "@/views/OverlayDisplay";
import { Notify } from "quasar";
import { filter, uniq, map } from "lodash";

// const errorHandler = (err) => {
//   this.$q.notify({ type: "negative", message: err.message });
//   // alert(err.message);
// };

export default {
  name: "session",
  components: { Publisher, Subscriber, OverlayDisplay },
  props: {
    apiKey: {
      type: String,
      default: process.env.VUE_APP_VONAGE_API_KEY,
    },
    sessionId: {
      type: String,
    },
    token: {
      type: String,
    },
    height: {
      type: Number,
    },
    patientid: {
      type: String,
    },
    meetingid: {
      type: String,
    },
    scalePatient: {
      type: Boolean,
    },
    audioInput: {
      required: true,
    },
    videoInput: {
      required: true,
    },
  },
  mounted() {
    this.$refs.resizeMe.trigger(true);
  },
  async created() {
    this.session = OT.initSession(this.apiKey, this.sessionId);

    this.session.connect(this.token, (err) => {
      if (err) {
        this.errorHandler(err);
      }

      // console.log('subscribers');
      // console.log(this.session.getSubscribersForStream(stream));
      console.log("connected");
    });

    this.session.on("streamCreated", (event) => {
      this.streams.push(event.stream);
      console.log("stream created");
    });

    this.session.on("streamDestroyed", (event) => {
      const idx = this.streams.indexOf(event.stream);
      if (idx > -1) {
        this.streams.splice(idx, 1);
      }
    });
  },
  data: () => ({
    streams: [],
    isMuted: false,
    session: null,
    publisherWidth: 480,
    publisherHeight: 270,
    subscriberWidth: 480,
    subscriberHeight: 270,
    publisherVideoScale: 0.35,
    subscriberOptions: {
      insertMode: "append",
      width: "100%",
      height: "100%",
      name: "Client",
    },
  }),
  watch: {
    isMuted() {
      this.pub;
    },
    videoInput() {
      console.log("video input changed", this.videoInput);
    },
  },
  computed: {
    validStreams() {
      console.log(this.streams);
      console.log(map(this.streams, "streamId"));
      return filter(uniq(this.streams, "streamId"), (r) => {
        return r.hasVideo;
      });
    },
    numVideoSessions() {
      return filter(this.streams, (r) => {
        return r.hasVideo;
      }).length;
    },
    publisherOptions() {
      return {
        audioSource: this.audioInput,
        videoSource: this.videoInput,
        publishAudio: true,
        publishVideo: true,
        insertMode: "append",
        width: !this.scaleParent ? "100%" : "150px",
        height: !this.scaleParent ? "100%" : "85px",
        style: { buttonDisplayMode: "off" },
        name: "Coach",
        mirror: false,
        // resolution: "1280x720",
      };
    },
    cssProps() {
      return {
        "--publisherWidth": this.publisherWidth + "px",
        "--publisherHeight": this.publisherHeight + "px",
        "--subscriberWidth": this.subscriberWidth + "px",
        "--subscriberHeight": this.subscriberHeight + "px",
        "--publisherVideoScale": this.publisherVideoScale,
      };
    },
    cssPropsOutput() {
      let output = ":root {\n";
      for (const k in this.cssProps) {
        output += `${k}:${this.cssProps[k]};\n`;
      }
      output += "}";
      // console.log(output);
      return output;
    },
  },
  methods: {
    // getSubscriberWithVideo() {
    //   console.log(this.streams);
    //   return this.streams[0];
    // },
    muteToggle() {
      this.isMuted = !this.isMuted;
    },
    onResize(report) {
      // console.log(report);

      if (!this.scalePatient) {
        this.publisherWidth = report.width - 8;
        this.publisherHeight = (report.width - 8 / 16) * 9;
        this.subscriberWidth = report.width - 8;
        this.subscriberHeight = this.publisherHeight =
          ((report.width - 8) / 16) * 9;
        this.publisherVideoScale = (report.width - 8) / 1280;
      } else {
        //MAX HEIGHT FOR SUBSCRIBER:

        //SET TO P-IN-P SIZE
        this.publisherWidth = 200 - 8;
        this.publisherHeight = (200 - 8 / 16) * 9;

        // this.subscriberHeight = 100;
        this.subscriberWidth = report.width - 8;

        let sixnine = (report.width / 16) * 9;

        let newheight = Math.min(window.innerHeight - 100, sixnine);

        this.subscriberHeight = newheight - 16;

        let newwidth = (newheight / 9) * 16;

        this.subscriberWidth = newwidth - 16;
        // this.subscriberHeight = this.publisherHeight =
        //   ((report.width - 8) / 16) * 9;

        this.publisherVideoScale = (this.subscriberWidth - 8) / 1280;
      }
    },
    disconnect() {
      // console.log("Disconnecting");
      this.$refs.publisher.disconnect();
      this.session.disconnect();
      this.session = null;
      this.streams = [];
    },
    errorHandler(err) {
      // console.log(err);
      Notify.create({ type: "negative", message: err.message });
      if (err.code && err.code == 1500) this.$emit("updateInputs");
    },
  },
};
</script>

<style lang="scss">
#publisherContainer {
  margin: 4px;
  overflow: hidden;
  border-radius: 4px;
  transform: translateZ(0);
  box-shadow: 0px 0px 5px #00000066;
  width: var(--publisherWidth);
  height: var(--publisherHeight);
  position: relative;
}

#parentContainer {
  position: fixed;
  right: 10px;
  bottom: 10px;
  border-radius: 4px;
  width: 150px;
  height: calc(150px / 16 * 9);
  box-shadow: 0px 0px 5px #00000066;
  overflow: hidden;
  margin: 4px;
}

.subscribers {
  margin: 4px;
  overflow: hidden;
  border-radius: 4px;
  transform: translateZ(0);
  box-shadow: 0px 0px 5px #00000066;
  background: #333;
  height: var(--subscriberHeight);
  width: var(--subscriberWidth);
  min-width: var(--subscriberWidth) / 2;
}

#subscriberContainer {
  position: relative;
  width: var(--subscriberWidth);
  height: var(--subscriberHeight);
}

.OT_subscriber {
  float: left;
}

.OT_publisher {
  float: left;
}

.scaleCoach {
  transform: scale(var(--publisherVideoScale)) translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  --webkit-perspective: 1;
  position: absolute;
  height: -20px;
  top: 8px;
  width: 27px;
}

.scalePatient {
  transform: scale(var(--publisherVideoScale)) translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  --webkit-perspective: 1;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  left: 16px;
  top: 8px;
}

.producerParentScale {
  height: 85px !important;
}
</style>
