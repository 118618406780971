<template lang="pug">
.q-pa-sm
  q-banner.q-mb-sm.bg-orange.text-white This is system-wide data. Please note much of this information may be confidential and/or protected.
  .row
    .col-auto
      q-tabs(v-model="tab" vertical class="text-secondary")
        //- q-tab(name="admins" icon="lock")
        q-route-tab(to="/admin/people" name="people" icon="people" label="Staff")
        q-route-tab(to="/admin/sessions" name="sessions" icon="list" label="Clients")
    .col
      q-tab-panels(v-model="tab" animated vertical)
        q-tab-panel(name="people")
          .text-overline Staff
          .text-caption.text-grey-6 Staff appear here once they have logged in using a whitelisted account.
          .row.justify-center
            .col-auto
              q-spinner(size="xl" v-show="staffloading")
          //- div {{ possibleAdmins }}
          q-list(separator)
            q-item(v-for="user in adminsSorted" :key="user.id")
              q-item-section
                q-item-label {{ user.name || user.email }}
                q-item-label(caption) {{ user.email }}
              q-item-section(side)
                q-btn(round flat dense icon="lock" :color="(user.claims.admin)?'green':'grey'" @click="toggleAdmin(user)" :loading="user.loading")
                  q-tooltip Make Administrator
          .text-overline Whitelisted Accounts
          .text-caption.text-grey-6 Staff who are allowed to access the system but have not tried yet.
          q-list(separator)
            q-item()
              q-item-section
                q-item-label
                  q-input(dense type="email" outlined v-model="newEmail" placeholder="Add Google capable email address to whitelist" :disable="inserting")
              q-item-section(side)
                q-btn(round flat dense icon="add" @click="addWL()" :loading="inserting")
                  q-tooltip Add to Whitelist
            q-item(v-for="(wl,index) of whitelist" :key="wl")
              q-item-section
                q-item-label {{ wl }}
              q-item-section(side)
                q-btn(round flat dense icon="delete" @click="removeWL(index)")
                  q-tooltip Remove from whitelist

        q-tab-panel(name="sessions")
          .text-overline Sessions
          q-table.q-my-md(:data="sessions"
              :columns="columns"
              :loading="sessionsloading"
              @request="onRequest"
              :pagination.sync="pagination"
              row-key="session.id")
            template(v-slot:body-cell-when="props")
              q-td(:props="props")
                .row {{ formatT(props.row.startTime) }}
                .row.text-grey-5.text-caption {{ timeSpan(props.row) }}
            template(v-slot:body-cell-recording="props")
              q-td(:props="props")
                q-btn(:href="props.row.recording.url" target="_BLANK" type="a" icon="download" v-if="props.row.recording" flat dense)
            template(v-slot:body-cell-transcript="props")
              q-td(:props="props")
                q-btn(:href="props.row.recording.transcript" target="_BLANK" type="a" icon="download" v-if="props.row.recording" flat dense)
            template(v-slot:body-cell-inspect="props")
              q-td(:props="props")
                q-btn(icon="search" :to="`/admin/inspect/${props.row.id}`" flat dense)
 
</template>

<script>
import {
  getAdminList,
  listPossibleAdmins,
  toggleAdmin,
  addWhiteList,
  removeWhiteList,
} from "../db";
import moment from "moment-timezone";
import { sortBy } from "lodash";
import Notifications from "../utils/notifications";

export default {
  mixins: [Notifications],
  props: ["tab"],
  data: function () {
    return {
      // loading: true,
      sessions: [],
      // tab: "people",
      possibleAdmins: [],
      whitelist: [],
      staffloading: true,
      sessionsloading: true,
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      inserting: false,
      newEmail: "",
      columns: [
        {
          name: "when",
          required: false,
          label: "When",
          align: "left",
          field: (row) => row.startTime,
          // format: (val) => moment(val).format("LLL"),
          sortable: false,
        },
        {
          name: "client",
          required: true,
          label: "Client",
          align: "left",
          field: (row) => row.client?.name || "Unknown",
          // format: (val) => `${val}`,
          sortable: false,
        },
        {
          name: "coach",
          required: true,
          label: "Coach",
          align: "left",
          field: (row) => row.coach?.displayName ?? "Unknown",
          // format: (val) => `${val}`,
          sortable: false,
        },
        {
          name: "topic",
          required: false,
          label: "Topic",
          align: "left",
          field: (row) => row.topic,
          // format: (val) => moment(val).format("LLL"),
          sortable: false,
        },
        {
          name: "recording",
          required: true,
          label: "Recording",
          align: "right",
          // field: (row) => row.recording?.url ?? "Unknown",
          // format: (val) => `${val}`,
          sortable: false,
        },
        {
          name: "transcript",
          required: true,
          label: "Transcript",
          align: "right",
          // field: (row) => row.recording?.transcript ?? "Unknown",
          // format: (val) => `${val}`,
          sortable: false,
        },
        {
          name: "inspect",
          required: true,
          label: "Inspect",
          align: "right",
          // format: (val) => `${val}`,
          sortable: false,
        },
      ],
    };
  },
  computed: {
    adminsSorted() {
      return sortBy(this.possibleAdmins, "name");
    },
  },
  methods: {
    formatT(t) {
      return moment(t).format("LLL");
    },
    timeSpan(row) {
      // console.log(moment(row.actualStartTime));
      return `${moment
        .duration(moment(row.actualEndTime).diff(moment(row.actualStartTime)))
        .humanize()}`;
    },
    async onRequest(props) {
      this.sessionsloading = true;
      // console.log(props);
      //get admin list:
      try {
        let server = (await getAdminList(props.pagination)).data;

        // console.log(server);

        this.sessions.splice(0, this.sessions.length, ...server.data);
        this.pagination = server.pagination;
      } catch (e) {
        this.showError(e);
        console.error(e);
      }
      this.sessionsloading = false;
    },
    async toggleAdmin(user) {
      user.loading = true;
      await toggleAdmin(user.id);
      this.possibleAdmins = (await listPossibleAdmins()).data.coaches;
      user.loading = false;
    },
    async addWL() {
      if (this.newEmail) {
        this.inserting = true;
        await addWhiteList(this.newEmail);
        this.newEmail = "";
        this.whitelist = (await listPossibleAdmins()).data.whitelist;
        this.inserting = false;
      }
    },
    async removeWL(index) {
      await removeWhiteList(index);
      this.whitelist = (await listPossibleAdmins()).data.whitelist;
    },
  },
  async mounted() {
    // if (!this.tab) this.tab = "people";

    try {
      let dat = (await listPossibleAdmins()).data;
      // console.log(dat);
      this.possibleAdmins = dat.coaches;
      this.whitelist = dat.whitelist;
      this.staffloading = false;
      // console.log(this.possibleAdmins);
    } catch (e) {
      this.showError(e);
      console.error(e);
    }

    this.onRequest({
      pagination: this.pagination,
    });
    // //get admin list:
    // try {
    //   this.sessions = (await getAdminList()).data.data;
    // } catch (e) {
    //   console.error(e);
    // }
    // this.sessionsloading = false;

    // this.loading = false;
  },
};
</script>
