<template lang="pug">
.q-mt-lg.q-px-sm
  div(v-if="patient && coachMeetings")
    q-timeline(:layout="layout", color="secondary")
      q-timeline-entry(
        subtitle="Future Session",
        title="Next Session",
        title-color="green",
        color="primary"
      )
        q-btn.q-px-sm(rounded, color="blue-4", @click="scheduleNew = true") Schedule Next Session
        .text-overline.text-grey-6 Sessions may take up to 5 minutes to appear once scheduled.
      SessionItem(
        v-for="(session, index) in getUpcomingSessions()",
        :key="index",
        :session="session",
        :coachid="uid"
      )
      SessionItem(
        v-for="(session, index) in getPastSessions()",
        :key="`s${index}`",
        :session="session",
        :past="true",
        :coachid="uid"
      )
      q-timeline-entry(title="Client Joined", color="primary")

  q-dialog(v-model="scheduleNew")
    q-card(style="min-width: 50vw")
      q-card-section.row.items-center
        .text-h5 Schedule a Session
        q-space
        q-btn(icon="close", flat, round, dense, v-close-popup)
      q-separator
      q-card-section
        .text-overline.text-secondary.q-pb-md This can also be done via the shared Google Calendar
        //- scheduling control
        ComputedComponent(
          v-if="control",
          :plugin="control",
          :component="control",
          type="Dashboard",
          :firebase="db",
          :functions="functions",
          :context="context",
          :useloading="true"
          :library="library"
        )
</template>

<script>
import { mapGetters } from "vuex";
import { db, functions } from "@/db";
import * as _ from "lodash";
import moment from "moment-timezone";
import SessionItem from "@/components/dashboard/SessionItem";
import ComputedComponent from "@/components/utils/ComputedComponent";
import notifications from "../utils/notifications";

const BASE_URL = process.env.VUE_APP_PLUGIN_DIRECTORY;

export default {
  name: "PatientDetail",
  plugins: [],
  props: {},
  mixins: [notifications],
  components: { SessionItem, ComputedComponent },
  computed: {
    db() {
      return db;
    },
    functions() {
      return functions;
    },
    layout() {
      return this.$q.screen.lt.sm
        ? "dense"
        : this.$q.screen.lt.md
        ? "comfortable"
        : "loose";
    },
    context() {
      return {
        meetingid: this.uid,
        coachUid: this.uid,
        patientUid: this.patientUid,
        sequenceid: this.patientUid,
      };
    },
    control() {
      const thecontrol = _.find(this.plugins, { plugin: "Schedule" });
      // console.log(this.plugins);
      if (!this.plugins) return null;
      if (thecontrol) {
        return {
          plugin: thecontrol,
          url: `${process.env.VUE_APP_PLUGIN_DIRECTORY}Schedule/${thecontrol.branch}/Schedule.common.js`,
        };
      } else {
        throw new Error("Can't load Schedule plugin.");
      }
    },
    hasPatientSession() {
      if (this.coachMeetings) {
        const tmp = this.coachMeetings;
        Object.keys(this.coachMeetings).forEach((key) => {
          tmp[key].id = key;
        });

        let sessions = _.groupBy(tmp, "patientUid");
        sessions = sessions[this.patientUid];
        return sessions && sessions.length !== 0;
      }
      return false;
    },
    ...mapGetters(["getUser"]),
  },
  data: () => {
    return {
      patient: null,
      patientUid: null,
      coachMeetings: null,
      uid: null,
      // patientProfile: null,
      scheduleNew: false,
      plugins: null,
      library: [],
    };
  },
  async created() {},
  async mounted() {
    this.$q.loading.show({ customClass: "bg-grey-4" });
    try {
      this.patientUid = this.$route.params.uid;
      this.uid = this.getUser.uid;

      if (this.plugins == null) {
        let resp = await fetch(`${BASE_URL}index.json`);
        this.plugins = await resp.json();
      }

      await Promise.all([
        this.$rtdbBind(
          "patient",
          db.ref("patients").child(this.$route.params.uid)
        ),
        this.$rtdbBind("coachMeetings", db.ref("meetings").child(this.uid)),
      ]);
    } catch (e) {
      this.showError(e);
    }
    this.$q.loading.hide();
  },
  methods: {
    getPatientSessions() {
      const tmp = this.coachMeetings;
      Object.keys(this.coachMeetings).forEach((key) => {
        tmp[key].id = key;
      });

      let sessions = _.groupBy(tmp, "patientUid");
      return sessions[this.patientUid];
    },
    getUpcomingSessions() {
      if (this.coachMeetings) {
        const sessions = this.getPatientSessions();

        const filterd = _.filter(sessions, (session) => {
          if (!session.startTime) return false;
          const diffHours = this.getDiffHours(session.startTime);
          return diffHours >= -4;
        });

        return _.orderBy(filterd, ["startTime"], ["desc"]);
      }
    },
    getPastSessions() {
      if (this.coachMeetings) {
        const sessions = this.getPatientSessions();

        const filterd = _.filter(sessions, (session) => {
          if (!session.startTime) return false;
          const diffHours = this.getDiffHours(session.startTime);
          return diffHours < -4;
        });

        return _.orderBy(filterd, ["startTime"], ["desc"]);
      }
    },
    getDiffHours(timestamp) {
      const current = moment().utc().toDate();
      return (moment(timestamp).toDate() - current) / 1000 / 60 / 60;
    },
  },
  watch: {},
};
</script>

<style lang="sass" scoped>
@import '~/src/styles/quasar.variables.sass'

.session-row
  width: 100%
  @media (min-width: $breakpoint-md-min)
    width: 80%
</style>
