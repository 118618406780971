import firebase from "firebase/app";
import { auth, db, Timestamp } from "../db";
import axios from "axios";

const actions = {
  async getMeetings({ commit }, payload) {
    try {
      const meetings = await db.ref(`meetings/${payload.uid}`).once("value");
      return meetings.val();
    } catch (error) {
      commit("setError", error.message);
    }
  },
  async startSession({ commit }, payload) {
    try {
      let time = await db
        .ref(`meetings/${payload.uid}/${payload.meetingId}/actualStartTime`)
        .once("value");
      // console.log(time.val() == null);
      if (time.val() == null)
        await db
          .ref(`meetings/${payload.uid}/${payload.meetingId}/actualStartTime`)
          .set(Timestamp);
    } catch (error) {
      commit("setError", error.message);
    }
  },
  async endSessionAll({ commit }, payload) {
    try {
      await db
        .ref(`meetings/${payload.uid}/${payload.meetingId}/actualEndTime`)
        .set(Timestamp);
    } catch (error) {
      commit("setError", error.message);
    }
  },
  async startArchive({ commit }, payload) {
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_FB_FUNCTIONS}/opentokApp-opentokApp/archive/start/${payload.sessionId}`,
        { uid: payload.uid }
      );
      return res.data;
    } catch (error) {
      commit("setError", error.message);
    }
  },
  async stopArchive({ commit }, payload) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_FB_FUNCTIONS}/opentokApp-opentokApp/archive/stop/${payload.archiveId}`
      );
      return res.data;
    } catch (error) {
      commit("setError", error.message);
    }
  },
  async signInAction({ commit }, payload) {
    if (!payload.token) return;

    try {
      await auth.signInWithCustomToken(payload.token);
    } catch (error) {
      console.error("Error: ", error);
      commit("setError", error.message);
    }
  },
  async getCustomClaims({ commit }) {
    try {
      if (auth.currentUser)
        return (await auth.currentUser.getIdTokenResult()).claims;
    } catch (error) {
      console.error("Error: ", error);
      commit("setError", error.message);
    }
  },
  async signOutAction({ commit }) {
    try {
      await auth.signOut();
      commit("setUser", null);
    } catch (error) {
      console.error("Error: ", error);
      commit("setError", error.message);
    }
  },
  async googleSignInAction({ commit }) {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");

    try {
      return await auth.signInWithPopup(provider);
    } catch (error) {
      commit("setError", error.message);
    }
  },
  authAction({ commit }) {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        user.updateProfile;
        commit("setUser", user);
      } else {
        commit("setUser", null);
      }
    });
  },
};

export default actions;
