<template>
  <div ref="pub_container"></div>
</template>

<script>
import OT from "@opentok/client";

export default {
  name: "subscriber",
  props: {
    stream: {
      type: OT.Stream,
      required: true,
    },
    session: {
      type: OT.Session,
      required: true,
    },
    opts: {
      type: Object,
      required: false,
    },
    actualHeight: {},
  },
  watch: {
    actualHeight: {
      handler() {
        this.$refs.pub_container.style.height = `${this.actualHeight}px`;
      },
    },
  },
  mounted: function () {
    this.$refs.pub_container.style.height = `${this.actualHeight}px`;
    const subscriber = this.session.subscribe(
      this.stream,
      this.$el,
      this.opts,
      (err) => {
        if (err) {
          this.$emit("error", err);
        } else {
          this.$emit("subscriberConnected", subscriber);
        }
      }
    );
    this.$emit("subscriberCreated", subscriber);
  },
};
</script>
