<template lang="pug">
.containerx
  .brow
    .row.q-gutter-md
      .col-auto
        .dot(style="background:#ED594A;")
        .dot(style="background:#FDD800;")
        .dot(style="background:#5AC05A;")
      .col
        input(type="text" value="https://bicara.io" disabled)
      .col-auto
        .bar
        .bar
        .bar
  .content
    slot
</div>

</template>

<script>

export default {
data: function () {
    return {

    };
  },
};
</script>


<style scoped>
/* The browser window */
.containerx {
  border: 3px solid #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0px 0px 35px 0px #666;
}

/* Container for columns and the top "toolbar" */
.brow {
  padding: 10px;
  background: #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* Three dots */
.dot {
  margin-top: 4px;
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right:4px;
}

/* Style the input field */
input[type=text] {
  width: 100%;
  border-radius: 3px;
  border: none;
  background-color: white;
  margin-top: -8px;
  height: 25px;
  color: #666;
  padding: 5px;
}

/* Three bars (hamburger menu) */
.bar {
  width: 17px;
  height: 3px;
  background-color: #aaa;
  margin: 3px 0;
  display: block;
}

/* Page content */
/* .content { */
  /* padding: 10px; */
/* } */
</style>