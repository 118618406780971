import Vue from "vue";
import App from "./App.vue";
import { store } from "./store";
import OverlayDisplay from "./views/OverlayDisplay";

Vue.config.productionTip = false;

Vue.config.errorHandler = function (err, vm, info) {
  console.error(err);
  if (global.rg4js)
    global.rg4js("send", {
      error: err,
      customData: [{ info: info }],
    });
};

import { rtdbPlugin } from "vuefire";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
Vue.use(rtdbPlugin);

Vue.use(Quasar, quasarUserOptions);

// import MediaPlugin from "@quasar/quasar-ui-qmediaplayer";
// import "@quasar/quasar-ui-qmediaplayer/dist/index.css";

// Vue.use(MediaPlugin);

import PortalVue from "portal-vue";

Vue.use(PortalVue);

import AudioVisual from "vue-audio-visual";

Vue.use(AudioVisual);

import router from "./router";

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

Vue.component("OverlayDisplay", OverlayDisplay);

new Vue({
  router,
  render: (h) => h(App),
  store,
}).$mount("#app");
