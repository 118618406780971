<template lang="pug">
q-timeline-entry(
  :color="past ? 'primary' : 'secondary'",
  v-if="this.curation",
  :title="session.topic",
  :subtitle="getSessionTime(session.startTime)",
  :side="past ? 'right' : 'left'"
)
  //- q-item
    //- q-item-section
      //- .col
        //- .text-h6.text-bold.q-pl-sm.q-mb-sm {{ session.topic }}
        //- q-chip(icon="event", color="secondary", text-color="white") {{ getSessionTime(session.startTime) }}

    q-item-section(avatar)
      .col.items-end.q-pa-md.q-gutter-md
  .text-body1.q-mb-sm.text-grey-7(v-if="!sessionEnded && sessionPrep") Prepare for this session and wait for it to start.
  .text-body1.q-mb-sm.text-grey-7(
    v-if="!sessionEnded && !sessionPrep && !isPast"
  ) This session is in progress, join it now.
  .text-body1.q-mb-sm.text-grey-7(v-if="!sessionEnded && isPast") This session should have ended by now, but is still going.
  .text-body1.q-mb-sm.text-grey-7(v-if="!session.curated && sessionEnded") This session requires a review to create content that is shared with the client.
  .text-body1.q-mb-sm.text-grey-7(v-if="session.curated") Session complete
  q-btn.q-px-sm(
    rounded,
    v-if="!session.curated",
    :color="getColor()",
    :to="`/dashboard/${session.patientUid}/${session.id}`",
    :label="getLabel()"
  )
  q-btn(
    v-if="sessionEnded",
    icon="download",
    flat,
    rounded,
    dense,
    color="grey-6",
    @click="download()"
  ) Download Recording
  //- q-separator(spaced, inset)
</template>

<script>
import { db, getRecordingForSession } from "@/db";
import moment from "moment-timezone";
import notifications from "../../utils/notifications";

export default {
  mixins: [notifications],
  props: {
    coachid: {
      required: true,
    },
    session: {
      required: true,
    },
    past: {
      default: false,
    },
  },
  data() {
    return {
      curation: null,
    };
  },
  methods: {
    async download() {
      // console.log(this.session[".key"]);
      const url = await getRecordingForSession({
        meetingid: this.session.id,
        coachid: this.coachid,
      });
      window.open(url.data);
      // console.log(url);
    },
    getColor() {
      if (this.sessionPrep) return "green";
      if (this.sessionEnded) return this.session.curated ? "primary" : "amber";
      return "green";
    },
    getLabel() {
      if (this.sessionPrep) return "Prepare for Session";
      if (this.sessionEnded)
        return this.session.curated ? "Review Session" : "Review Session";
      return "Join Session";
    },
    getSessionTime(timestamp) {
      return moment(timestamp).format("LL \\a\\t LT");
    },
  },
  computed: {
    isPast() {
      return moment(this.session.endTime).toDate() < moment().utc().toDate();
    },
    sessionPrep() {
      return (
        moment(this.session.startTime).toDate() - moment().utc().toDate() >= 0
      );
    },
    sessionEnded() {
      return this.session.actualEndTime;
    },
    continueEditing() {
      if (!this.curation) return false;
      return Object.keys(this.curation).length > 0;
    },
  },
  mounted() {
    try {
      this.$rtdbBind(
        "curation",
        db.ref("data/curation").child(this.session.id)
      );
    } catch (e) {
      this.showError(e);
    }
  },
};
</script>

<style lang="sass" scoped>
.session-card
  width: 100%
  height: 100%
  border-radius: 10px

.session-card:hover
  background-color: #E8E8E8
</style>
