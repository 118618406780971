import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/app";
import { getCurrentUser } from "../db";
import Login from "@/views/Login";
import OverlayDisplay from "@/views/OverlayDisplay";
// import SessionController from "@/views/SessionController";
import Dashboard from "@/views/Dashboard";
import PatientDetail from "@/views/PatientDetail";
import SessionDetail from "@/views/SessionDetail";
import PatientSession from "@/views/PatientSession";
import Anonymous from "@/views/Anonymous";
import Learning from "@/views/Learning";
import ParentLearning from "@/views/ParentLearning";
import Documentation from "@/views/Documentation";
import Info from "@/views/Info";
import Admin from "@/views/Admin";
import Inspect from "@/views/Inspect";

const Editor = () => import("@/components/editor/Editor.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/info",
    component: Info,
    name: "Info",
  },
  {
    path: "/docs",
    component: Documentation,
    name: "Documentation",
  },
  {
    path: "/dashboard",
    component: Dashboard,
    name: "Dashboard",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/:uid",
    component: PatientDetail,
    name: "PatientDetail",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/:uid/:meetingId",
    component: SessionDetail,
    name: "SessionDetail",
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/learning",
    component: Learning,
    name: "Learning",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/inspect/:id",
    component: Inspect,
    name: "Inspect",
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },

  {
    path: "/admin/:tab",
    component: Admin,
    name: "Admin",
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/admin",
    redirect: "/admin/people",
  },
  {
    path: "/myjourney/:customToken?",
    component: ParentLearning,
    name: "ParentLearning",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/display/:meetingid",
    component: OverlayDisplay,
    name: "OverlayDisplay",
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/editor/:type/:id?",
    component: Editor,
    name: "Editor",
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/meeting",
    name: "Meeting",
    component: Anonymous,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/meeting/:coachid/:meetingid",
    name: "MeetingScreen",
    component: PatientSession,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "*",
    redirect: "/login",
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await getCurrentUser();
  // console.log(currentUser.uid);

  const claims = firebase.auth().currentUser
    ? (await firebase.auth().currentUser.getIdTokenResult()).claims
    : null;
  const isAnonymous = currentUser && currentUser.isAnonymous;

  // console.log(`isAnon: ${isAnonymous}`);

  //should redirect to dashboard:
  if (currentUser && to.path.startsWith("/login") && !isAnonymous) {
    // console.log("login return");
    return next("/dashboard");
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const allowAnonymous = to.matched.some(
    (record) => record.meta.allowAnonymous
  );

  if (!requiresAuth) {
    // console.log("no auth required");
    return next();
  }

  if (!allowAnonymous && isAnonymous) {
    // console.log("needs login");
    return next("/login");
  }

  if (requiresAuth && !currentUser) {
    // console.log("requires auth and no user");
    return next("login");
  } else if (requiresAuth && currentUser) {
    if (requiresAdmin) {
      if (claims && claims.admin) return next();
      else return next("/dashboard");
    } else {
      if (claims && claims.coach) {
        // console.log("needs coach, is coach");
        return next();
      } else {
        // console.log("client page");
        return next("/myjourney");
      }
    }
  }
});

export default router;
