<template lang="pug">
div(style="min-height: inherit;").treebg
  //- .treebg.fullscreen
  //- .treebg.fullscreen
  .absolute-top-left.q-pa-sm
    .text-caption v{{ version }}
  .text-center(style="").fit
    //- .absolute-top-right
      

    //- .absolute-bottom.q-mb-lg
      

    .row.items-center(style="min-height: 60vh;").fit
      .col-12.q-pa-lg
        .text-h4 Welcome to Bicara
        //- .text-body1.text-grey-8 Bicara is an online tool to facilitate remote real-time one-on-one video coaching.
        .text-grey-8(style="font-size:24px") Re-imagining the telehealth experience for clients and clinicians.
        .row.q-pb-xl
          .col-xs-12.col-md.q-mt-lg
            //- .text-body1.text-grey-8.q-mb-md.q-mx-lg What does Bicara do differently?
            q-btn(color="secondary" rounded to="/info" size="lg") Find out more
        .row.q-mt-xl.justify-center
          .col-xs-12.col-md-4.q-mb-lg
            q-card(flat bordered).q-pa-lg
              .text-body1.q-mb-md.q-mx-lg Are you a client looking<br> to review your journey?
              q-btn(
                to="/myjourney",
                color="white",
                text-color="black",
                size="lg",
                rounded
              ) Continue My Journey
          .q-mx-lg
          //- q-separator.q-mx-lg(vertical)
          .col-xs-12.col-md-4
            q-card(flat bordered).q-pa-lg
              .text-body1.q-mb-md.q-mx-lg Coaching or Hosting<br> a session?
              q-btn(@click="signInWithGoogle()" color="white",
                text-color="black", rounded size="lg") Clinician Login

    .absolute-bottom
      Footer

</template>

<script>
import { db, Timestamp, getCurrentUser } from "@/db";
import { mapActions } from "vuex";
import { fabGoogle } from "@quasar/extras/fontawesome-v5";
import Footer from "../components/utils/Footer.vue";

export default {
  data() {
    return {
      accessToken: null,
      email: null,
      emailLogin: false,
      sentEmail: false,
      version: process.env.PACKAGE_VERSION,
    };
  },
  components: { Footer },
  async created() {
    this.fabGoogle = fabGoogle;

    const token = this.$route.params.token;

    if (token) {
      this.$q.loading.show({
        message: "Loading...",
      });
      await this.signInAction({ token: token });
      this.$router.replace("/submission");
      this.$q.loading.hide();
    }
  },
  methods: {
    ...mapActions(["signInAction", "signOutAction", "googleSignInAction"]),
    async signInWithGoogle() {
      this.$q.loading.show({
        message: "Loading...",
      });

      try {
        const data = await this.googleSignInAction();
        const uid = data.user.uid;

        // console.log(data.user);
        let checkcount = 0;

        const checkVerificationInterval = setInterval(async () => {
          checkcount++;
          // console.log(`checking ${checkcount}`);
          let userData = (await db.ref(`users/${uid}`).once("value")).val();

          // console.log(userData);
          if (userData) {
            clearInterval(checkVerificationInterval);
            this.$q.loading.hide();

            if (!userData.verified) {
              this.$q.notify({
                message:
                  "Bicara is available to our program coaches only. If you are a parent of the program, you will receive instruction for accessing your learning assets after your first session. Thanks!",
                type: "negative",
                timeout: 10000,
              });

              // return this.$router.replace("/");
            } else {
              let user = await getCurrentUser();
              let claims = await user.getIdTokenResult(true);

              if (claims.coach) {
                // console.log(userData);
                await db.ref(`users/${uid}`).update({ createdAt: Timestamp });
                // this.$q.notify({
                //   message:
                //     "We have updated your account access and you have been logged out automatically. Please sign in again to access the coach dashboard!",
                //   type: "positive",
                //   timeout: 10000,
                // });
                // await this.signOutAction();
                return this.$router.replace("/dashboard");
              } else return this.$router.replace("/dashboard");
            }
          } else {
            if (checkcount > 2) {
              clearInterval(checkVerificationInterval);
              this.$q.loading.hide();
              await this.signOutAction();
              this.$q.notify({
                message:
                  "Bicara is available to our program coaches only. If you are a parent of the program, you will receive instruction for accessing your learning assets after your first session. Thanks!",
                type: "negative",
                timeout: 10000,
              });
            }
          }
        }, 2000);
      } catch (e) {
        this.$q.loading.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.treebg {
  background-image: url("../assets/tree3.svg");
  // background-position: bottom;
  background-repeat: no-repeat;
  background-position: bottom -620px center;
  // background-attachment: fixed;
}
</style>
>
