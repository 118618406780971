import { render, staticRenderFns } from "./Onboarding.vue?vue&type=template&id=7b5a2b51&scoped=true&lang=pug&"
import script from "./Onboarding.vue?vue&type=script&lang=js&"
export * from "./Onboarding.vue?vue&type=script&lang=js&"
import style0 from "./Onboarding.vue?vue&type=style&index=0&id=7b5a2b51&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b5a2b51",
  null
  
)

export default component.exports
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QStepper,QStep,QSelect,QIcon,QStepperNavigation,QBtn});
