<template lang="pug">
div.full-width
  .row(style="margin-bottom:-50px;margin-top:20px;" v-if="!readonly")
    .col-auto
      q-btn(icon="chevron_left" flat dense color="secondary" @click="backTextIN()")
    .col-auto
      q-btn(icon="chevron_right" flat dense color="secondary" @click="fwdTextIN()") 
    .col
    .col-auto
      q-btn(icon="chevron_left" flat dense color="secondary" @click="backTextOUT()")
    .col-auto
      q-btn(icon="chevron_right" flat dense color="secondary" @click="fwdTextOUT()") 
  .row 
    Tagging(
      :key="this.item.key",
      :meetingid="meetingid",
      :actualStartTime="actualStartTime",
      :actualEndTime="actualEndTime"
    )
  .row.full-width
    .col-auto.text-grey-6(style="width: 48px; padding-top: 14px") 00:00
    .col
      q-range(
        :readonly="readonly"
        v-model="temprange",
        :min="0",
        :max="duration",
        label-always,
        :left-label-value="getRangeLabel(temprange.min)",
        :right-label-value="getRangeLabel(temprange.max)",
        track-size="8px",
        track-color="grey-1",
        thumb-size="22px",
        color="secondary",
        :step="0.1",
        marker-labels,
        @change="snap",
        style="height: 90px; padding-top: 20px",
        ref="slider"
      )
        template(v-slot:marker-label-group="scope")
          div(
            v-for="marker in scope.markerList",
            v-if="getSectionIndex(marker.value) >= 0 || (getSlideIndex(marker.value) >= 0 && hasInput(marker.value)) || getAnnotation(marker.value)",
            :class="[`text-deep-orange-${2 + Math.ceil(marker.value / 2)}`, marker.classes]",
            :key="marker.index",
            :style="marker.style"
          ) 
            .row(v-if="getSlideIndex(marker.value) >= 0")
              .col-12
                .slide-change(style="height: 15px; margin-bottom: -3px")
              .col-12
                q-badge.cursor-pointer(rounded, color="grey-4")
                  q-tooltip(max-width="300px")
                    div(v-for="input in getInputForSlide(marker.value)")
                      .text-body1.text-bold {{ input.label }}
                      .text-body1 {{ input.value }}

            .row(
              v-else-if="getAnnotation(marker.value)",
              style="margin-top: -50px"
            )
              .col-12
                q-badge.cursor-pointer(rounded, color="negative")
                  q-tooltip(max-width="300px")
                    .text-body1 {{ getAnnotation(marker.value) }}
              .col-12
                .slide-change.annotation(style="height: 30px; margin-top: -8px")
            .row(v-else)
              .col-12
                .border-section(style="height: 60px")
              .col-12
                q-chip.text-white(
                  color="grey-6",
                  size="10px",
                  style="transform: translate(-40%, -13px) rotate(315deg); transform-origin: 100% 50%"
                ) {{ sectionNames[getSectionIndex(marker.value)] }}

    .col-auto.text-grey-6.text-right(style="width: 48px; padding-top: 14px") {{ getRangeLabel(duration) }}
</template>

<script>
import { db } from "@/db";
// import Markdown from "@/components/utils/Markdown.vue";
import { formatMinuteSecond } from "../../utils/formatter";
import {
  getNearestStart,
  getNearestEnd,
  goBackOne,
  goBackOneO,
  goFwdOne,
  goFwdOneO,
} from "../../utils/transcription";
import notifications from "../../utils/notifications";
import Tagging from "./Tagging.vue";

let regex = /^:::\s(s-)?(\w*):(.*)$/gm;

export default {
  name: "Slider",
  mixins: [notifications],
  props: [
    "annotations",
    "meetingid",
    "patientid",
    "duration",
    "startTime",
    "endTime",
    "item",
    "slideChange",
    "slides",
    "sections",
    "sectionNames",
    "actualStartTime",
    "actualEndTime",
    "transcript",
    "readonly",
  ],
  components: { Tagging },
  data() {
    return {
      show: true,
      slide: 0,
      temprange: {
        min: this.item.editCurrent
          ? this.item.editCurrent.startTime
          : this.startTime,
        max: this.item.editCurrent
          ? this.item.editCurrent.endTime
          : this.endTime,
      },
      range: {
        min: this.item.editCurrent
          ? this.item.editCurrent.startTime
          : this.startTime,
        max: this.item.editCurrent
          ? this.item.editCurrent.endTime
          : this.endTime,
      },
      sectionTimestamp: [],
      slideTimestamp: [],
      sequenceinputs: {},
      meetinginputs: {},
    };
  },
  computed: {},
  methods: {
    backTextIN() {
      // console.log(this.range.min);
      const newmin = goBackOne(this.transcript, this.range.min);
      // console.log(newmin);
      this.range.min = newmin;
      setTimeout(() => {
        this.temprange.min = this.range.min;
        // this.temprange.max = this.range.max;
      }, 200);
      this.slideChange(this.range.min, this.range.max);
    },
    fwdTextIN() {
      // console.log(this.range.min);
      const newmin = goFwdOne(this.transcript, this.range.min);
      if (newmin < this.range.max) {
        // console.log(newmin);
        this.range.min = newmin;
        setTimeout(() => {
          this.temprange.min = this.range.min;
          // this.temprange.max = this.range.max;
        }, 200);
        this.slideChange(this.range.min, this.range.max);
      }
    },
    backTextOUT() {
      //  console.log(this.range.max);
      const newmax = goBackOneO(this.transcript, this.range.max);
      // console.log(newmax);
      if (newmax > this.range.min) {
        this.range.max = newmax;
        setTimeout(() => {
          this.temprange.max = this.range.max;
          // this.temprange.max = this.range.max;
        }, 200);
        this.slideChange(this.range.min, this.range.max);
      }
    },
    fwdTextOUT() {
      // console.log(this.range.min);
      const newmax = goFwdOneO(this.transcript, this.range.max);
      // console.log(newmin);
      this.range.max = newmax;
      setTimeout(() => {
        this.temprange.max = this.range.max;
        // this.temprange.max = this.range.max;
      }, 200);
      this.slideChange(this.range.min, this.range.max);
    },
    snap(newval) {
      //snap to when the transcript is...
      //get closest start time thats older than this:
      this.range.min = getNearestStart(this.transcript, newval.min);
      //get closet end time that after this:
      this.range.max = getNearestEnd(this.transcript, newval.max);

      setTimeout(() => {
        this.temprange.min = this.range.min;
        this.temprange.max = this.range.max;
      }, 200);

      // console.log(this.range);

      this.slideChange(this.range.min, this.range.max);
    },
    getInput(type, field) {
      if (type === "s-") return this.sequenceinputs[field] || "";
      else return this.meetinginputs[field] || "";
    },
    hasInput(index) {
      let content = this.slides[this.getSlideIndex(index)].slideContent;
      let result = content.match(/:::/);

      return result != null;
    },
    getInputForSlide(index) {
      let content = this.slides[this.getSlideIndex(index)].slideContent;

      let inputs = [...content.matchAll(regex)];

      let tmp = [];

      for (let field of inputs) {
        tmp.push({
          label: field[3],
          value: this.getInput(field[1], field[2]),
        });
      }

      return tmp;
    },
    getAnnotation(index) {
      //index is seconds:
      return this.annotations[this.formatTimestamp(index)];
    },

    getRangeLabel(val) {
      return formatMinuteSecond(val);
    },
    formatTimestamp(timestamp) {
      return Math.round(timestamp * 10) / 10;
    },
    getSectionIndex(value) {
      return this.sectionTimestamp.indexOf(this.formatTimestamp(value));
    },
    getSlideIndex(value) {
      return this.slideTimestamp.indexOf(this.formatTimestamp(value));
    },
  },
  mounted() {
    // console.log(this.sectionNames);

    // console.log(`duration: ${this.duration}`);
    try {
      const sectionTimestamp = [];
      Object.keys(this.sections).forEach((key) => {
        sectionTimestamp.push(
          this.formatTimestamp(
            (this.sections[key].startTime - this.actualStartTime) / 1000
          )
        );
      });
      this.sectionTimestamp = sectionTimestamp;

      const slideTimestamp = [];
      Object.keys(this.slides).forEach((key) => {
        slideTimestamp.push(
          this.formatTimestamp(
            (this.slides[key].startTime - this.actualStartTime) / 1000
          )
        );
      });
      this.slideTimestamp = slideTimestamp;

      this.$rtdbBind(
        "sequenceinputs",
        db.ref(`sequence/slides/${this.patientid}/current`)
      );

      this.$rtdbBind(
        "meetinginputs",
        db.ref(`data/slides/${this.meetingid}/current/`)
      );
    } catch (e) {
      this.showError(e);
    }
  },
};
</script>

<style lang="sass" scoped>
.border-section
  position: inherit
  border-left: 2px solid #eee
  transform: translate(50%)

.slide-change
  position: inherit
  border-left: 2px solid #eee
  transform: translate(35%)

.annotation
  border-color: var(--q-color-negative)
  border-width: 1px
  transform: translate(45%)

.scaled
  width: 200px
  height: 200px
  overflow: hidden
  transform-origin: top left
</style>

<style lang="sass">
.q-slider__track-container--h
  padding: 0
</style>
