<template lang="pug">
.fullscreen.bg-grey-4
  .absolute-center(v-if="invalidLink")
    .text-h3.text-grey-6 Sorry, this link is invalid.
</template>

<script>
import { db, auth, functions, PERSISTANCE } from "@/db";

export default {
  name: "PatientSession",
  data() {
    return {
      token: this.$route.query.token,
      user: {},
      invalidLink: false,
    };
  },
  created: async function () {
    this.$q.loading.show({
      message: "Loading your session...",
    });
    await auth.signOut();
    console.log("signed out");
    console.log(auth.currentUser);
    this.signInAnonymously();
  },
  methods: {
    async signInAnonymously() {
      if (!this.token) {
        // console.log("Missing Meeting Token...");
        this.$q.loading.hide();
        this.invalidLink = true;
        // return this.$router.push("/");
      }

      let curMeetingid;
      let curHostuid;
      try {
        await auth.setPersistence(PERSISTANCE.LOCAL);
        let loginData = await auth.signInAnonymously();

        // .then(async (loginData) => {
        // console.log(loginData);

        this.user = loginData.user;

        // console.log(this.user);

        // Read result of the decryptToken Function
        const decryptToken = functions.httpsCallable("anonymous-decryptToken");
        const result = await decryptToken({ token: this.token });
        const { meetingid, hostuid } = result.data;
        curMeetingid = meetingid;
        curHostuid = hostuid;

        //remove localstorage to make client re-do onboarding.
        localStorage.removeItem("videoInput");
        localStorage.removeItem("audioInput");
        localStorage.removeItem("confirmedSettings");

        this.$router.push(`/meeting/${curHostuid}/${curMeetingid}`);
        this.$q.loading.hide();
      } catch (error) {
        console.log("Error: " + error);
        this.invalidLink = true;
        this.$q.loading.hide();
        // return this.$router.push("/");
      }
    },
  },
};
</script>
