<template lang="pug">
div
  //- .text-overline Pre-populate information for today's session
  //- .row
  //- div {{ clientData.checkin }}
    //- div {{ fields }}

  q-expansion-item.col(
    default-opened,
    label="Information from previous sessions",
    icon="info",
    group="main"
  )
    //- .text-overline Data captured across all sessions with the client:
    .row.q-my-sm.q-col-gutter-sm.q-pa-sm
      .col-md-4.col-xs-12.col-sm-6(v-for="(item, index) in prepData")
        //- div {{item.session}}
        q-card.full-height(bordered, flat)
          q-card-section
            .text-capitalize.text-h6.text-secondary {{ item.name }}
            .text-primary(v-if="item.session") {{getTimeM(item.session.startTime)}}
          q-separator(v-if="item.checkin.length")
          q-card-section(v-if="item.checkin.length")
            .text-body(v-for="(k, v) in item.checkin")
              span {{ k.question ? k.question.name : 'Unknown' }}:&nbsp;
              span.text-secondary {{ k.question ? k.question.options[k.response] : k.response }}
          q-separator
          q-card-section
            div(v-for="(k, v) in item.fields")
              span.text-capitalize {{ k.name.replaceAll('\\n', '') }}:&nbsp;
              span.text-body.text-secondary {{ getFieldValue(k.field) }}
    .text-overline.q-mx-md(v-if="prepData.length == 0") This client has sessions yet
  //- .text-overline Information from previous sessions
  q-separator
  q-expansion-item.col(
    icon="list",
    label="Pre-populate information for today's session",
    group="main"
  )
    .row.q-my-xs
      .col-sm-4.col-md-3.q-pa-xs.col-12(v-for="(field, index) in fields")
        //- div {{ field.field }}
        //- div {{ getFieldValue(field.field) }}
        q-input.full-width(
          outlined,
          dense,
          autogrow,
          :label="field.name",
          :ref="`input_${field.field}`",
          @input="updateField($event, field)",
          :value="getFieldValue(field.field)",
          :loading="field.loading"
        )
          template(v-slot:before)
            .text-center(style="width: 1.1em") {{ index + 1 }}

  //- .text-overline Curated Clips
  q-separator
  q-expansion-item.col(
    label="Curated clips",
    group="main",
    icon="movie_filter"
  )
    .text-overline.q-mx-md(v-if="assets.length == 0") This client has no curated clips yet
    q-card.q-mb-md(v-for="asset in assets", :key="asset.id")
      q-card-section(horizontal)
        q-card-section.full-width
          .float-right.text-grey-5.q-pt-xs {{ getTime(asset.createdAt) }}
          q-chip.float-right(
            dense,
            v-for="label in asset.labels",
            :key="label.name",
            v-if="label"
          ) {{ label.title }}
            q-tooltip(v-if="label.feedback") {{ label.feedback }}
          .text-body1.text-secondary {{ asset.title }}
          div &quot;
            span(v-for="trn in asset.transcript") {{ trn.text }}&nbsp;
            span &quot;
          //- div {{ asset.createdAt }}
          .text-overline(v-if="asset.annotations.length") Annotations
          ul.q-ma-none.q-pa-none.q-pl-md(v-if="asset.annotations.length")
            li(v-for="ann in asset.annotations", :key="ann.value") {{ ann.value }}

        q-separator(vertical)
        q-card-section(actions)
          AudioPlayer(:asset="asset")
  .text-center
    q-spinner(size="md", v-show="loading")
</template>

<script>
import { db, functions } from "@/db";
import AudioPlayer from "@/components/learning/AudioPlayer.vue";
import moment from "moment-timezone";
import notifications from "../../utils/notifications";
import { debounce } from "quasar";
import { filter, find, orderBy } from "lodash";

export default {
  props: ["client", "meetingid"],
  mixins: [notifications],
  components: { AudioPlayer },
  data() {
    return {
      clientData: {},
      assets: [],
      prepData: {},
      // groupedData: {},
      loading: true,
      config: {},
    };
  },
  computed: {
    fields() {
      //get slides info (parse inputs)
      const freg = /::: (.*):(.*)/g;
      const text = this.config?.schedule?.raw ?? "";
      const match = [...text.matchAll(freg)];
      let result = [];
      // console.log(text);
      for (let r of match) {
        // console.log(r[1]);
        const address = r[1].startsWith("s-")
          ? `sequence/slides/${this.client[".key"]}/current/${r[1].replace(
              "s-",
              ""
            )}`
          : `data/slides/${this.meetingid}/current/${r[1]}`;
        if (!find(result, { address })) {
          result.push({
            name: r[2].trim().replaceAll("\\n", "").replaceAll('"', ""),
            type: r[1].startsWith("s-") ? "sequence" : "data",
            field: r[1].replace("s-", ""),
            ref: db.ref(address),
            address,
          });
        }
      }
      return result;
    },
  },
  methods: {
    getTime(t) {
      return moment(t * 1000).format("LL LT");
    },
    getTimeM(t) {
      return moment(t).format("LL LT");
    },
    getFieldValue(field) {
      return this.clientData.slides?.[field] ?? "";
    },
    async doUpdate(field, val) {
      field.loading = true;

      // console.log(`setting ${field.ref} with ${val}`);

      try {
        await field.ref.set(val);
      } catch (e) {
        this.showError(e.message);
      }
      // console.log(field);
      field.loading = false;
    },
    async updateField(element, field) {
      // console.log(this.clientData);
      if (!this.clientData.slides) this.clientData.slides = {};
      // console.log("updating", element);
      // console.log(field);
      this.$set(this.clientData.slides, field.field, element);
      this.doUpdate(field, element);
    },
  },
  created() {
    this.doUpdate = debounce(this.doUpdate, 200);
  },
  async mounted() {
    this.loading = true;
    try {
      //get client data:
      this.$set(
        this.clientData,
        "slides",
        (
          await db
            .ref(`sequence/slides/${this.client[".key"]}/current`)
            .once("value")
        ).val()
      );

      // this.$set(
      //   this.clientData,
      //   "topsCheckin",
      //   (
      //     await db
      //       .ref(`sequence/topsCheckin/${this.client[".key"]}`)
      //       .once("value")
      //   ).val()
      // );

      try {
        let data = (
          await functions.httpsCallable("assets-getPrepInfoForClient")({
            clientid: this.client[".key"],
          })
        ).data;

        // console.log(data);

        data = orderBy(
          filter(data, (obj, key) => key != this.meetingid),
          [(s) => s?.session?.startTime],
          ["desc"]
        );

        // data = filter(data, (obj, key) => key != this.meetingid);

        this.prepData = data;
        // console.log(data);
      } catch (e) {
        console.log(e);
        this.showError(e.message);
      }

      // console.log(this.clientData);

      try {
        let data = (
          await functions.httpsCallable("assets-getAssetsForClient")({
            email: this.client.email,
          })
        ).data;
        this.assets = orderBy(data.assets, ["createdAt"], ["desc"]);
      } catch (e) {
        console.log(e);
        this.$q.notify({
          message: e.message,
          type: "negative",
        });
      }

      // let output = {};
      // for (const k in this.clientData.slides) {
      //   const key = k.split("_")[0];
      //   if (!output[key]) output[key] = { slides: {}, checkin: {} };
      //   output[key]["slides"][k.split("_")[1]] = this.clientData.slides[k];
      // }

      // for (const key in this.clientData.topsCheckin) {
      //   // console.log(k);
      //   // const key = k.split("_")[0];
      //   if (!output[key]) output[key] = { slides: {}, checkin: {} };
      //   output[key]["checkin"] = this.clientData.topsCheckin[key];
      // }

      // this.groupedData = output;

      //get all fields that exist in the template:
      this.config = (
        await db.ref(`config/${this.meetingid}`).once("value")
      ).val();
    } catch (e) {
      this.showError(e);
    }
    this.loading = false;
  },
};
</script>
