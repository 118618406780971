import { render, staticRenderFns } from "./InspectSlider.vue?vue&type=template&id=0441779e&scoped=true&lang=pug&"
import script from "./InspectSlider.vue?vue&type=script&lang=js&"
export * from "./InspectSlider.vue?vue&type=script&lang=js&"
import style0 from "./InspectSlider.vue?vue&type=style&index=0&id=0441779e&prod&lang=sass&scoped=true&"
import style1 from "./InspectSlider.vue?vue&type=style&index=1&id=0441779e&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0441779e",
  null
  
)

export default component.exports
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSlider,QBadge,QTooltip,QChip});
