<template lang="pug">
.q-px-md
  //- .absolute-center(v-if="loading")
    q-spinner(size="lg")
  //- .markdown(v-html="docs")
  h1 User Documentation
  .text-body1 This documentation is provided as a quick reference, not a comprehensive user guide. Please contact the Bicara administrator in your organisation for more detailed information.
  .bg-green-2.q-my-md.q-pa-sm.rounded-borders
    p Things to note:
    ul
      li You are called a 'coach' in Bicara.
      li The person you are talking to is called a 'client' in Bicara.
      li The client will not be aware this system is called Bicara - as it is branded to the specific programme you are delivering.

  q-separator.q-mt-lg

  h2 Adding Clients
  .text-body1 Bicara has a limited client management system, allowing you to schedule and run sessions.
  .bg-green-2.q-my-md.q-pa-sm.rounded-borders
    p Common questions:
    ul
      li #[strong Where do I put my case notes?]
        p Case notes should be recorded and stored in your existing client management processes.
      li #[strong Where do I get the client ID from?]
        p The client ID should be the unique indentifier from your existing client management system, for example the UID from Partners in Parenting.
  q-separator.q-mt-lg

  h2 Scheduling Sessions
  .text-body1 Scheduling is managed on a shared Google calendar, to which you have access. When a session is sheduled in Bicara it will appear on the shared calendar immediatly, however there will a short delay until it appears within your Bicara dashboard.
  .bg-green-2.q-my-md.q-pa-sm.rounded-borders
    p Common questions:
    ul
      li #[strong How do I change the time of a session?]
        p Move the session in Google calendar to the new time. Within a couple of minutes, these changes will be reflected in Bicara.
      li #[strong How do I cancel a session?]
        p Delete the session from Google calendar. Within a couple of minutes, these changes will be reflected in Bicara.
      li #[strong How do I chance the coach for a session?]
        p This functionality is currently not supported.
      li #[strong Can I schedule multiple sessions in the future?]
        p Yes, schedule as many as you want ahead of time, however note that you cannot change the template for a session once it has been scheduled.

  q-separator.q-mt-lg

  h2 Preparing a Session
  .text-body1 Once you schedule a session, you have the ability to prepare for this session at any point before the scheduled start time. The session will only start for you and the client once you click the 'Start Session' button, allowing you to review notes etc. if required. Once the session is started however, you cannot go back to this preparation screen.
  .bg-green-2.q-my-md.q-pa-sm.rounded-borders
    p Common questions:
    ul
      li #[strong How do I change the slide content for a session?]
        p All non-editable slide content is designed and configured by your program administrator. You only have the ability to edit client related fields on these slides during session preparation.
  q-separator.q-mt-lg

  h2 Running a Session
  .bg-green-2.q-my-md.q-pa-sm.rounded-borders
    p Common questions:
    ul
      li #[strong Why does the link on the calendar not work for me?]
        p The link in the calendar description is for the client only. As a coach, you should access the session via the Bicara dashboard.
      li #[strong What happens if I need to change the order of content in a session?]
        p You can go backwards and forwards through the session script at any time in a session.
      li #[strong What happens if my camera or mic stops working during a session?]
        p Simply refresh the browser - Bicara will appear exactly as you left off, and will usually re-connect to your devices.
      li #[strong What happens if I accidentally end the session?]
        p You cannot restart a closed session.

  q-separator.q-mt-lg
  h2 Session Review
  .text-body1 After each session, the coach has the opportunity to select specific sections of the session and share them with the client or keep for their own learning.
  .bg-green-2.q-my-md.q-pa-sm.rounded-borders
    p Common questions:
    ul
      li #[strong Why can I not access the session review screen?]
        p Session review can only commence when the automatic transcription is complete. This may take a few minutes. You will be notified by email when the session is ready to be reviewed.

      li #[strong How do I add extra notes for the client?]
        p Currently, the 'Make additional notes' field turns into extra prompts for the client (or if just used for your learning, this is the title of the segment).
</template>

<script>
// var taskLists = require("markdown-it-task-lists");
// const md = require("markdown-it")({ html: true }).use(taskLists, {
//   enabled: true,
// });

export default {
  data: function () {
    return {
      loading: true,
      docs: "",
    };
  },
  async mounted() {
    //go get docs:
    // console.log("getting", process.env.VUE_APP_DOCS_URL);
    // const raw = await fetch(process.env.VUE_APP_DOCS_URL, { mode: "cors" });
    // console.log(await raw.text());
    //render docs:
    // this.docs = md.render(await raw.text());
    // this.loading = false;
  },
};
</script>
