<template lang="pug">
apexchart.q-mx-xl(
  type="area",
  height="60",
  ref="chart",
  :options="chartOptions",
  :series="series",
  style="width: 100%; margin-bottom: -38px"
)
</template>

<script>
import { db } from "@/db";
import VueApexCharts from "vue-apexcharts";
import notifications from "../../utils/notifications";

export default {
  name: "Tagging",
  props: {
    meetingid: { required: true },
    actualStartTime: { required: true },
    actualEndTime: { required: true },
  },
  components: { apexchart: VueApexCharts },
  mixins: [notifications],
  data: () => {
    return {
      taggingData: {},
      // config: null,
      seriesName: "Interest Level",
      series: [
        {
          name: "Interest Level",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 60,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            left: -25,
            right: -5,
            bottom: -30,
          },
        },
        stroke: {
          show: false,
          curve: "smooth",
          // curve: "stepline",
          lineCap: "butt",
          colors: ["#f86d22"],
          width: 0,
          dashArray: 0,
        },
        tooltip: {
          enabled: false,
        },
        fill: {
          type: "gradient",
          colors: ["#f86d22"],
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.2,
            stops: [0, 100],
          },
        },
      },
      selection: "one_year",
    };
  },
  async created() {
    try {
      // console.log("Run Me");
      let newData = [[0, 0]];
      this.taggingData = (
        await db.ref(`data/tagging/${this.meetingid}`).once("value")
      ).val();

      // console.log(this.taggingData);

      let index = 1;
      if (this.taggingData) {
        for (const key of Object.keys(this.taggingData)) {
          const { data, timestamp } = this.taggingData[key];

          // console.log([...newData]);
          //if previous data was 0, and the one we want to add is not, then add a point right before it...
          if (newData[index - 1] && newData[index - 1][1] == 0 && data > 0) {
            // console.log(`Inserting blank at ${index - 1}`);
            newData.push([timestamp - this.actualStartTime - 1000, 0]);
            index++;
          }

          newData.push([timestamp - this.actualStartTime, data]);
          index++;

          // console.log(
          //   `${data} @ ${timestamp - this.actualStartTime}, ${
          //     this.actualStartTime
          //   }`
          // );
        }

        newData.push([this.actualEndTime - this.actualStartTime, 0]);
        this.series = [{ name: this.seriesName, data: newData }];
      }
      // console.log(`${newData.length} els`);
      // console.log(newData);
    } catch (e) {
      this.showError(e);
    }
  },
};
</script>

<style lang="sass" scoped></style>
