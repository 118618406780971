<template lang="pug">
.fit
  //- LOGIN

  .treebg.fullscreen(v-if="!getUser")
    .absolute-top-left
      q-btn(flat icon="arrow_back_ios_new" round to="/")
    .row.q-mb-sm.q-px-lg.full-width.justify-center.q-mt-xl
      .col-12
        .row.justify-center
          .col.text-center
            .text-h4 Your Journey
        .row.justify-center.q-mb-xl.q-mt-lg
          .col.text-center(style="font-size:24px")
            .text-grey-8 We keep your information secure.
            .text-grey-8 To check it's you, we will send you a link to click in your email.
      .col-12.col-md-6(v-if="!tokenSent")
        q-form.full-width(@submit="onSubmit")
          .row.justify-center.items-center.q-col-gutter-sm
            .col-sm-6.col-xs-12
              q-input(
                :disable="loading",
                rounded,
                color="primary",
                outlined,
                v-model="email",
                placeholder="Your Email"
              )
            .col-sm-auto.col-xs-12.text-center
              q-btn(
                rounded,
                type="submit",
                :loading="loading",
                size="lg",
                color="secondary",
                label="Send Link"
              )

      .col-12.col-md-6(v-else)
        .row.justify-center.items-center.q-col-gutter-sm
          .col-sm-6.col-xs-12
            q-input(
              color="primary",
              outlined,
              align="center",
              v-model="token",
              placeholder="6-Didget Code",
              rounded,
              mask="######",
              style="font-size: 24px"
            )
          .col-sm-auto.col-xs-12.text-center
            q-btn.full-width(
              rounded,
              :loading="loading",
              color="secondary",
              size="lg",
              @click="authenticate",
              label="Continue"
            ) 

          .col-auto
            q-btn(flat, @click="tokenSent = false", label="Send new code") 

        .row.justify-center

  //- MAIN PAGE
  .row.sky(v-if="patient")
    .row.full-vh.full-width.justify-center.items-center
      .col.text-center
        .text-h2.q-pa-md.finger Hello {{ patient.name }}
        q-btn.q-mb-lg(flat, @click="forgetMe") Not you?
        .row.justify-center.q-mx-md
          .col-sm-6.col-xs-12(style="position: relative")
            .followprogress.shadow-1(style="position: absolute; left: 0") Scroll down to reflect on your journey so far and see how much you have grown...

    .row.full-width
      .row.tree(:class="[whichTree]")
      .row.items-center.full-width.ground
        .full-width(style="margin-top: -650px")
          .row.full-width.items-center(
            v-for="i of rows",
            :class="{ roots_1: i == 1, roots_n: i > 1 }"
          )
            .column.full-width.justify-start.full-height
              .col-auto.full-width.rootseg(
                v-for="(asset, index) in getAssets(i)"
              )
                .row.full-height
                  //- div {{ asset }}
                  .col-sm-5.col-xs-12(
                    :class="{ 'offset-7': index % 2 == 0 && $q.screen.gt.xs }"
                  )
                    .artefact(
                      :class="{ 'text-right': index % 2 == 1 && $q.screen.gt.xs }",
                      style="display: block"
                    )
                      //- div {{ asset }}
                      q-avatar(
                        :size="$q.screen.gt.xs ? '60px' : '30px'",
                        round,
                        :class="[getIcon(asset)]"
                      )
                        //- q-img(:src="getIcon(asset)")
                      //- q-separator
                      .text-h6(:class="[getTitleColor(asset)]") {{ getTitle(asset) }}
                      .text-h6.quote &quot;{{ getQuote(asset) }}&quot;
                      .row.items-center.text-left.justify-center.q-my-md
                        .col-auto
                          AudioPlayer.q-mr-md(
                            :dark="true",
                            :asset="asset",
                            style="display: inline-block"
                          )
                        .col-auto
                          .text-h6.finger.xs-hide Listen to what was said
                      .row.full-width.justify-center
                        .feedback.shadow-3(v-if="hasComments(asset)")
                          div(
                            v-for="lab of asset.labels",
                            v-if="lab && lab.feedback"
                          )
                            q-icon.q-mr-xs(name="check_box_outline_blank")
                            span {{ lab.feedback }}
                          div(
                            v-if="asset.title && asset.title != 'Selected Segment'"
                          )
                            q-icon.q-mr-xs(name="check_box_outline_blank")
                            span {{ asset.title }}
                      //- .post-it(v-if="asset.annotations.length")
                      //-   p(v-for="an of asset.annotations") {{ an.value }}
                      //- .text-left(v-if="asset.fields.length")
                      //- .text-overline Your Reflection
                      //-   div(v-for="field of asset.fields") {{ field.value }}

        //- .row.full-width.items-center.vine(
        //-   v-for="(asset, index) in assets",
        //-   :class="{ 'vine-sm': $q.screen.lt.sm }"
        //- )
        //-   .col-sm-5.branch.text-center.q-mx-lg.q-px-lg(
        //-     :class="{ 'offset-7': index % 2 && $q.screen.gt.xs, 'offset-3': $q.screen.lt.sm }"
        //-   )
        //-     .text-h6.text-grey-4 {{ asset.title }}
        //-     .text-h5.quote &quot;{{ getQuote(asset) }}&quot;
        //-     .text-center
        //-       AudioPlayer(:asset="asset", style="display: inline-block")
        //-     .post-it(v-if="asset.annotations.length")
        //-       p(v-for="an of asset.annotations") {{ an.value }}
        //-     .text-left(v-if="asset.fields.length")
        //-       .text-overline Your Reflection
        //-       div(v-for="field of asset.fields") {{ field.value }}
        //-     .text-right(v-if="hasComments(asset)")
        //-       .text-overline How to Grow
        //-       div(v-for="lab of asset.labels") {{ lab.content }}
      .text-center.full-width
        Footer.text-center.text-grey-8(style="margin-top: -60px")
</template>

<script>
import { mapGetters } from "vuex";
import { db, auth, getAssetsForClient } from "@/db";
import axios from "axios";
import { map, filter, uniq, find, orderBy } from "lodash";
import { getTimeInSeconds, formatDuration } from "../utils/formatter";
import AudioPlayer from "@/components/learning/AudioPlayer.vue";
import notifications from "@/utils/notifications";
import Footer from "../components/utils/Footer.vue";

export default {
  name: "Learning",
  plugins: [],
  mixins: [notifications],
  props: {},
  components: { AudioPlayer, Footer },
  computed: {
    db() {
      return db;
    },
    whichTree() {
      if (this.meetingsCount < 9) return `tree${this.meetingsCount}`;
      else return 8;
    },
    rows() {
      // console.log(this.assets.length);
      // console.log(Math.round(this.assets.length / 6) + 1);
      return Math.floor(this.assets.length / 6) + 1;
    },
    meetingsCount() {
      // return 8;
      return uniq(map(this.assets, "meetingId")).length || 1;
    },
    ...mapGetters(["getUser"]),
  },
  data: () => {
    return {
      customToken: null,
      email: "",
      loading: false,
      token: null,
      tokenSent: false,
      patient: null,
      currentvideo: {},
      playvideo: false,
      sessionNames: {},
      assets: [],
    };
  },
  methods: {
    getTitle(asset) {
      // console.log(asset.labels);
      const label = find(asset.labels, function (l) {
        return l?.icon != null ?? false;
      });

      // for when not using free-text
      // return label?.title || asset.title;
      return label?.title || "";
    },
    getTitleColor(asset) {
      const label = find(asset.labels, function (l) {
        return l?.icon != null ?? false;
      });
      // console.log(label);
      return `title_${label?.icon}` || "text-white";
    },
    getIcon(asset) {
      // if (asset.labels == null) return `tag_water`;
      // console.log(asset.labels);
      const label = find(asset.labels, function (l) {
        return l?.icon != null ?? false;
      });
      // console.log(label);
      return `tag_${label?.icon}` || `tag_watering`;
      // return "tag_water";
    },
    getAssets(group) {
      // console.log(group * 6, (group + 1) * 6);

      return this.assets.slice((group - 1) * 6, group * 6);
    },
    hasComments(asset) {
      // console.log(filter(asset.labels, "content"));
      return (
        filter(asset.labels, "feedback").length ||
        (asset.title && asset.title != "Selected Segment")
      );
    },
    getQuote(asset) {
      return map(asset.transcript, "text").join(" ");
    },
    async forgetMe() {
      await auth.signOut();
      this.$router.push("/");
    },
    async onSubmit() {
      try {
        const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
        if (!emailValid)
          this.showError("Please provide a valid email to get a link!");
        else {
          this.loading = true;
          const response = await axios.post(
            `${process.env.VUE_APP_FB_FUNCTIONS}/parentAuth-parentGetTotpToken`,
            {
              email: this.email,
            }
          );
          this.loading = false;

          // console.log("response: ", response);
          const { success, message } = response.data;
          this.$q.notify({
            message: message,
            type: success ? "positive" : "negative",
          });

          if (success) this.tokenSent = true;
        }
      } catch (e) {
        this.loading = false;
        this.showError(e);
      }
    },
    async authenticate() {
      //reset auth if currently logged in with anon session
      if (!this.token || this.token.indexOf("#") >= 0)
        this.showError("Each code should be 6 digits!");
      else {
        this.loading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_FB_FUNCTIONS}/parentAuth-parentAuthenticate`,
          {
            email: this.email,
            token: this.token,
          }
        );
        this.loading = false;

        // console.log("response: ", response);
        const { success, message, customToken } = response.data;

        if (success) this.signInWithCustomToken(customToken);
        else {
          // console.log(auth.currentUser);
          if (!auth.currentUser) this.showError(message);
        }
      }
    },
    async signInWithCustomToken(token) {
      try {
        await auth.signInWithCustomToken(token);
      } catch (error) {
        this.showError("An error occurred during authentication...");
      }
    },
    // getS3Link(src) {
    //   if (!src) return "";
    //   let url = `${process.env.VUE_APP_CLOUDFRONT_URL}/${src
    //     .replace("s3://", "")
    //     .replace(process.env.VUE_APP_S3_BUCKET, "")}`;
    //   return url;
    // },
    // getAudio(asset) {
    //   return `${this.getS3Link(asset.s3UrlCurated.replace(".mp4", ".mp3"))}`;
    // },
    getAssetDuration(asset) {
      const endTime = getTimeInSeconds(asset.endTimecode);
      const startTime = getTimeInSeconds(asset.startTimecode);
      return formatDuration(endTime - startTime);
    },
  },
  created() {},
  async mounted() {
    if (this.$route.query.token && this.$route.query.email) {
      this.token = this.$route.query.token;
      this.email = this.$route.query.email;
      this.authenticate();
    }
  },
  watch: {
    getUser: {
      immediate: true,
      async handler(newVal) {
        if (!newVal) return;

        // console.log(newVal);
        if (newVal.isAnonymous) {
          await auth.signOut();
          return;
        }

        //need to check if this is an anon user...

        this.$q.loading.show({ customClass: "bg-grey-4" });

        // console.log(this.getUser);
        // console.log(auth.currentUser);

        try {
          let data = (
            await getAssetsForClient({
              email: this.getUser.email,
            })
          ).data;
          this.assets = orderBy(data.assets, "createdAt", "desc");
          // console.log(this.assets);
          this.patient = data.client;
        } catch (e) {
          // console.log(e);
          this.showError(e.message);
        }

        this.$q.loading.hide();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Finger+Paint&family=Libre+Bodoni:ital@1&display=swap");

@import "~quasar-variables-styl";

.finger {
  font-family: "Finger Paint", cursive;
}

.quote {
  max-height: 115px;
  font-weight: normal;
  overflow-y: scroll;
  position: relative;
}

.feedback {
  text-align: left;
  border: 8px $brown-6 solid;
  background: black;
  color: white;
  font-family: "Finger Paint", cursive;
  padding: 6px;
  max-width: 60%;
  transform: rotate(-3deg);
  @media (max-width: $breakpoint-xs-max) {
    font-size: 0.7em;
    border-width: 4px;
  }
}

.full-vh {
  height: calc(100vh - 600px) !important;
}

.tree {
  width: 100%;
  // background-color: blue;
  background-repeat: no-repeat;
  // background-position-x: center;
  background-position: bottom -1px center;
  // background-size: 150%;
  height: 1220px;
  // background-color: red;

  margin-top: 150px;
  // z-index: -1;
  background-size: 3000px;
}

.artefact {
  color: white;
  // font-size: 1.2em;
  // font-weight: 500;
  padding: 40px;
}

.rootseg {
  height: 280px;
}

.tree1 {
  background-image: url("../assets/tree1.svg");
}

.tree2 {
  background-image: url("../assets/tree2.svg");
}

.tree3 {
  background-image: url("../assets/tree3.svg");
}

.tree4 {
  background-image: url("../assets/tree4.svg");
}

.tree5 {
  background-image: url("../assets/tree5.svg");
}

.tree6 {
  background-image: url("../assets/tree6.svg");
}

.tree7 {
  background-image: url("../assets/tree7.svg");
}

.tree8 {
  background-image: url("../assets/tree8.svg");
}

.sky {
  background-color: #f9fffc;
}

.ground {
  background: rgb(181, 143, 94);
  background-image: url("../assets/stones2.svg"),
    linear-gradient(
      180deg,
      rgba(181, 143, 94, 1) 0%,
      rgba(179, 142, 92, 1) 16%,
      rgba(129, 87, 41, 1) 46%,
      rgba(79, 44, 10, 1) 100%
    );
  background-position: bottom, top;
  background-repeat: no-repeat;
  // background-image: ;
}

.roots_1 {
  background-image: url("../assets/roots_1.svg");
  background-repeat: no-repeat;
  background-position-x: calc(50% + 20px);
  // background-position-x: -180px;
  // height: 2291px;
  height: 2291px;
  padding-top: 430px;
  @media (max-width: $breakpoint-xs-max) {
    // background-color: red;
    background-position: left -200px top;
    background-image: url("../assets/roots_1_m.svg");
    .rootseg {
      // height: 400px;
      // min-height: 280px;
    }
    // background-position-x: calc(1% + 20px);
  }
}

.roots_n {
  background-image: url("../assets/roots_n.svg");
  // background-color: red;
  // border-bottom: green solid 3px;
  background-repeat: no-repeat;
  background-position-x: calc(50% + 7px);
  height: 2000px;
  padding-top: 170px;

  @media (max-width: $breakpoint-xs-max) {
    // background-color: red;
    background-position: left -250px top;
    // background-position-x: calc(1% + 20px);
    .rootseg {
      // height: inherit;
      // height: 400px;
    }
  }
}

.vine-sm {
  background-position-x: -80px !important;
}

.followprogress {
  border-radius: 30px;
  background: #ffe9ca;
  color: #5d372e;
  font-size: 1.6em;
  padding: 1em;
  display: inline-block;
  font-weight: bold;
}

.post-it {
  transform: rotate(-2.5deg);
  width: 200px;
  overflow: hidden;
  max-height: 300px;
  font-family: "Kalam", cursive;
  padding: 8px;
  background: linear-gradient(
    180deg,
    rgba(187, 235, 255, 1) 0%,
    rgba(187, 235, 255, 1) 12%,
    rgba(170, 220, 241, 1) 75%,
    rgba(195, 229, 244, 1) 100%
  );

  p {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }

  ul {
    margin-left: 0px;
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
    // padding-left: 25px;
    li {
      padding: 0;
      margin: 0;
    }
  }
}
</style>
