import { render, staticRenderFns } from "./Documentation.vue?vue&type=template&id=8d0a2f74&lang=pug&"
import script from "./Documentation.vue?vue&type=script&lang=js&"
export * from "./Documentation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpinner,QSeparator});
