<template lang="pug">
div
  q-stepper.bg-grey-2(
    v-model="step",
    ref="stepper",
    color="primary",
    animated,
    flat,
    header-class="text-bold"
  )
    q-step(:name="1", icon="mic", title="Select your speaker", :done="step > 1")
      .text-center.text-overline.q-mb-lg Check your speakers work before continuing by playing this sound...
      .text-center
        av-line(
          :line-width="2",
          :line-color="colorSec",
          :canv-width="100",
          :canv-height="30",
          cors-anonym,
          :audio-src="getAudio()"
        )
    q-step(
      :name="2",
      icon="headset_mic",
      title="Select your microphone",
      :done="step > 2"
    ) 
      q-select(
        outlined,
        v-model="audioInput",
        :options="audioInputLabels",
        label="Select Microphone",
        behavior="menu",
        emit-value,
        map-options,
        @input="onSelectAudioInput"
      )
        template(v-slot:prepend)
          q-icon(name="headset_mic")
      audio(ref="player")
      .text-center.text-overline.q-mt-md Check your mic works before continuing. <br />You should see the graphic below moving in response to your voice...
      .text-center
        av-media(
          :media="audioMedia",
          :frequ-lnum="5",
          type="frequ",
          :canv-width="52",
          :canv-height="30",
          :frequ-line-cap="true",
          :line-width="6",
          :line-color="colorSec"
        )

    q-step(
      :name="3",
      icon="smart_display",
      title="Select your camera",
      :done="step > 3"
    ) 
      q-select(
        outlined,
        v-model="videoInput",
        :options="videoInputLabels",
        label="Select Camera",
        behavior="menu",
        emit-value,
        map-options,
        @input="onSelectVideoInput"
      )
        template(v-slot:prepend)
          q-icon(name="smart_display")
      .text-center.text-overline.q-mt-md Check your camera works before continuing...
      .camera_box.text-center.q-mt-md
        video.shadow(ref="camera", width="200", height="150", autoplay)

    template(v-slot:navigation)
      q-stepper-navigation.text-right
        q-btn.q-ml-sm(
          rounded,
          v-if="step > 1",
          flat,
          color="primary",
          @click="$refs.stepper.previous()",
          label="Back"
        )

        q-btn(
          rounded,
          v-if="step !== 3",
          @click="$refs.stepper.next()",
          color="primary",
          label="Continue"
        )

        q-btn(
          rounded,
          v-if="step === 3",
          :disabled="!(audioInput && videoInput)",
          color="secondary",
          label="Continue with session",
          @click="doConfirm"
        )
  .text-caption.q-pa-xs.text-center If you have any issue connecting to your mic or camera, just refresh this page in your browser to continue
</template>

<script>
import { find } from "lodash";
import { colors, Platform } from "quasar";

export default {
  props: ["selectAudioInput", "selectVideoInput", "confirmOnboarding"],
  data() {
    return {
      audioMedia: null,
      step: 1,
      audioInput: null,
      videoInput: null,
      audioInputLabels: [],
      videoInputLabels: [],
      audioInputs: [],
      videoInputs: [],
    };
  },
  methods: {
    doConfirm() {
      //INTERCEPT THIS AND CHECK THE THE DEVICES ACTUALLY EXIST BEFORE ALLOWING TO CLOSE THIS WINDOW
      // console.log(this.audioInput);

      if (!find(this.audioInputLabels, { value: this.audioInput })) {
        console.log(`cant find audio device`);
        this.step = 2;
        return;
      }
      // console.log(this.videoInput);
      // console.log(this.videoInputLabels);
      if (!find(this.videoInputLabels, { value: this.videoInput })) {
        console.log(`cant find video device`);
        this.$q.notify({
          type: "warning",
          position: "center",
          message: "Please select a specific camera to continue.",
        });
        this.step = 3;
        return;
      }

      console.log(`'doing confirm: ${this.audioInput}, ${this.videoInput}`);
      this.selectAudioInput(this.audioInput);
      localStorage.audioInput = this.audioInput;
      this.selectVideoInput(this.videoInput);
      localStorage.videoInput = this.videoInput;

      this.confirmOnboarding();
    },
    getAudio() {
      // console.log(`platform: ${Platform.is.safari}`);
      return Platform.is.safari ? "audio/test.mp3" : "audio/test.ogg";
    },
    async enumerateDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      // console.log(devices);
      // if (devices.length > 0 && devices[0].label === "")
      // devices[0].label = "Click to select a device";

      this.audioInputLabels.splice(0);
      this.videoInputLabels.splice(0);

      devices.forEach((device) => {
        switch (device.kind) {
          case "audioinput":
            // console.log(find(this.audioInputLabels, { label: device.label }));
            if (!find(this.audioInputLabels, { label: device.label })) {
              this.audioInputLabels.push({
                label: device.label,
                value: device.deviceId,
              });
              this.audioInputs.push(device);
            }
            break;
          case "videoinput":
            if (!find(this.videoInputLabels, { label: device.label })) {
              this.videoInputLabels.push({
                label: device.label,
                value: device.deviceId,
              });
              this.videoInputs.push(device);
            }
            break;
          default:
            break;
        }
      });

      // console.log(this.audioInputLabels);

      if (
        this.audioInputLabels.length == 1 &&
        this.audioInputLabels[0].label === ""
      ) {
        this.audioInputLabels[0].label = "Click to select a microphone";
        this.audioInputLabels[0].value = "default";
      }

      if (
        this.videoInputLabels.length == 1 &&
        this.videoInputLabels[0].label === ""
      ) {
        this.videoInputLabels[0].label = "Click to select a camera";
        this.videoInputLabels[0].value = "default";
      }

      // console.log(this.audioInput);
      if (this.audioInput == null) {
        // console.log(this.audioInputLabels[0].value);
        this.audioInput = this.audioInputLabels[0].value;
        console.log(`Setting audio to ${this.audioInputLabels[0].value}`);
      }

      // console.log(this.videoInput);
      if (this.videoInput == null) {
        // console.log(this.audioInputLabels[0]);
        this.videoInput = this.videoInputLabels[0].value;
        console.log(`Setting video to ${this.videoInputLabels[0].value}`);
      }
    },
    async onSelectAudioInput(firsttime = false) {
      const device = find(
        this.audioInputs,
        (input) => input.deviceId === this.audioInput
      );

      try {
        let constraints = { audio: true };

        if (typeof device !== "undefined")
          constraints = { audio: { deviceId: device.deviceId } };

        const dev = await navigator.mediaDevices.getUserMedia(constraints);

        await this.enumerateDevices();

        // console.log(dev);
        this.audioMedia = dev;
        this.$refs.player.srcObject = dev;
        // console.log("selected audio");
        this.selectAudioInput(device.deviceId);
        localStorage.audioInput = device.deviceId;
        return true;
      } catch (e) {
        if (!firsttime) {
          //cant set audio input
          console.log("cant select audio", e);
          this.selectAudioInput(this.audioInputLabels[0].deviceId);
          localStorage.audioInput = this.audioInputLabels[0].deviceId;
          this.audioInput = this.audioInputLabels[0].deviceId;
        }
      }
    },
    async onSelectVideoInput(firsttime = false) {
      const device = find(
        this.videoInputs,
        (input) => input.deviceId === this.videoInput
      );

      try {
        let constraints = { video: true };
        if (typeof device !== "undefined")
          constraints = { video: { deviceId: device.deviceId } };

        const dev = await navigator.mediaDevices.getUserMedia(constraints);

        await this.enumerateDevices();

        // this.videoMedia = dev;
        this.$refs.camera.srcObject = dev;

        this.selectVideoInput(device.deviceId);
        localStorage.videoInput = device.deviceId;
        return true;
      } catch {
        if (!firsttime) {
          //cant set video input
          console.log("cant select video");
          this.selectVideoInput(this.videoInputLabels[0].deviceId);
          this.videoInput = this.videoInputLabels[0].deviceId;
          localStorage.videoInput = this.videoInputLabels[0].deviceId;
        }
      }
    },
  },
  computed: {
    colorSec() {
      return colors.getBrand("secondary");
    },
  },
  watch: {
    step() {
      if (this.step == 2 && this.audioInput)
        this.onSelectAudioInput(this.audioInput);
      if (this.step == 3 && this.videoInput)
        this.onSelectVideoInput(this.videoInput);
    },
  },
  async mounted() {
    if (localStorage.videoInput) {
      this.videoInput = localStorage.videoInput;
    }

    if (localStorage.audioInput) {
      this.audioInput = localStorage.audioInput;
    }

    await this.enumerateDevices();

    // console.log(this.audioInputLabels);

    // if (this.audioInputLabels.length == 0)
    //   this.audioInputLabels.push({
    //     label: "Click to select a microphone",
    //     value: "default",
    //   });

    // if (
    //   this.videoInputLabels.length == 1 &&
    //   this.videoInputLabels[0].label === ""
    // )
    //   this.videoInputLabels[0].label = "Click to select a camera";

    // console.log(this.audioInputLabels);
  },
};
</script>

<style lang="sass" scoped>
.camera-box
  border-radius: 4px

.shadow
  border-radius: 6px
  box-shadow: 0px 0px 5px #00000066
  background-color: #555
</style>
