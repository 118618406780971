<template lang="pug">
.row.justify-center
  .col-12.col-md-11
    .q-px-md
      h5.text-center.text-grey-5.q-my-xl Why Bicara?
      
      h5.text-center.q-py-lg Bicara is a web-based one-to-one video telehealth system designed to reduce cognitive load to clients and clinicians whilst maintaining empathetic connection.
      //- It supports the capture of consistent annotations of supplementary material during a session and the re-presentation of recorded session segments back to clients, supporting review and reflection between sessions.
      //- h2 Bicara is a configurable web-based one-to-one videoconference system developed by Action Lab. The qualities of the system arose from: (i) our experience of many deployments of ZoomSense; (ii) experiences of our Turner Institute collaborators using Zoom for one-to-one parent coaching interventions; (iii) multiple workshops with headspace practitioners around the challenges of remote psychological therapy and support; (iv) intensive co-design sessions with clinical psychologists.

      //- figcaption.text-right
      h5.text-center.text-grey-5.q-my-xl A typical Bicara session...

      .row.justify-center
        .col-md-9.col
          blockquote.text-left.text-grey-7 A coach schedules a session with a client in Bicara, selecting a 
            span.highlight session template 
            span to use for the session; at the time of the session, the coach view the 
            span.highlight preparation screen
            span for the client, viewing metadata and snippets from previous sessions; the client receives an email reminder and joins the session in their web-browser; the coach runs the session, controlling 
            span.highlight pre-prepared content
            span , entering 
            span.highlight notes and meta-data into slides
            span and marking 
            span.highlight interesting points for review
            span , via their dashboard; when the session is complete, the coach is presented with 
            span.highlight suggestions for segments of the recording to share
            span with the client, they tweak a couple of them and add additional tags, and share them with the client; the client is then emailed with a magic link to view their session progress, where they can 
            span.highlight view these snippets.


      //- h5 Bicara responds to the following challenges:
      //- ul
        //- li Problems presenting supplementary information to clients during an online session whilst maintaining empathetic connection through video (e.g. remove the need for screen-sharing)
        //- li Problems capturing consistent annotations of supplementary material created during a session (previously captured as ‘drawings’ on screen-shared powerpoint slides).
        //- li The need to capture and re-present segments of sessions back to clients as a core activity of an on-going intervention, allowing clients to review and reflect between sessions.
      //- Features
      //- Basic client management and scheduling functionality, including session reminders for coaches & clients.
      //- ‘Magic link’ for clients connecting to a specific session, in browser.
      //- Single interface for the coach with all relevant information & controls for the session in a single screen.
      //- Single on-screen content and interactive content (such as likert scales).
      //- ‘Templates’ that represent the content and configuration of a specific session, including: on-screen content / ordering of the session / display content from external web-based interventions.
      //- Preparation screen for the coach allowing them to review all past sessions and captured annotations and recordings before the session begins.
      //- Ability for coaches to select sections of the recording based on the transcript post-session, and share these snippets with the client through a simple web-link.
      

      h5.text-center.text-grey-5.q-my-xl Innovative features
      .row.q-mb-xl.q-col-gutter-xl
        .col-12.col-md-6
          Browser
            q-img(src="/1.jpeg")
        .col-12.col-md-6
          .title Context aware controls for clinicians
          br
          q-icon(name="verified" color="secondary" size="2em").on-left
          span.text-body1 Single interface for the host with all relevant information &amp; controls for the session in a single screen, displayed on current context.
          br
          q-icon(name="verified" color="secondary" size="2em").on-left
          span.text-body1 Templates that represent the content and configuration of a specific session, including: on-screen content / ordering of the session / display content from external web-based interventions.
          br
          q-icon(name="verified" color="secondary" size="2em").on-left
          span.text-body1 Entry of specifically tagged content during the session live on screen in response to client discussion. This content can be referenced in future sessions.
          q-separator.q-my-lg
          q-icon(name="bug_report" color="grey" size="2em").on-left
          span.text-body1 Features developed in response to problems capturing consistent annotations of supplementary material created during a session (previously captured as ‘drawings’ on screen-shared powerpoint slides).
      
      .row.q-col-gutter-xl.q-mb-xl
        .col-12.col-md-6
          .title Focussed &amp; intimate experience for clients
          br
          q-icon(name="verified" color="secondary" size="2em").on-left
          span.text-body1 Single screen, web-based experience for client, with on-screen graphics and content displayed crisp, regardless of internet speed.
          br
          q-icon(name="verified" color="secondary" size="2em").on-left
          span.text-body1 All content for the session is displayed in a single visual location, placing focus on the host for increased engagement.
          br
          q-icon(name="verified" color="secondary" size="2em").on-left
          span.text-body1 ‘Magic link’ for clients connecting to a specific session, in browser, removing the need for accounts and passwords.
          q-separator.q-my-lg
          q-icon(name="bug_report" color="grey" size="2em").on-left
          span.text-body1 Features developed in response to problems presenting supplementary information to clients during an online session whilst maintaining empathetic connection through video (e.g. remove the need for screen-sharing)
          br
        .col-12.col-md-6(:class="{'order-first':$q.screen.lt.md}")
          Browser
            q-img(src="/2.jpeg")

      .row.q-col-gutter-xl.q-mb-xl
        .col-12.col-md-6
          Browser
            q-img(src="/3.png")
        .col-12.col-md-6
          .title Machine-assisted session summary creation
          br
          q-icon(name="verified" color="secondary" size="2em").on-left
          span.text-body1 Ability for coaches to select sections of the recording based on the transcript post-session, and share these snippets with the client through a simple web-link.
          br
          q-icon(name="verified" color="secondary" size="2em").on-left
          span.text-body1 Bicara suggests clips based on automatically captured metadata such as slide annotations or goals set during a session.
          br
          q-icon(name="verified" color="secondary" size="2em").on-left
          span.text-body1 Hosts can add content to their own 'learning bank' to be used for clinical supervision or reflection.
          q-separator.q-my-lg
          q-icon(name="bug_report" color="grey" size="2em").on-left
          span.text-body1 Features developed in response to the need to capture and re-present segments of sessions back to clients as a core activity of an on-going intervention, allowing clients to review and reflect between sessions.
          br

      .row.q-col-gutter-xl.q-mb-xl
        .col-12.col-md-6
          .title Inter-session review and reflection
          br
          q-icon(name="verified" color="secondary" size="2em").on-left
          span.text-body1 Clients can return at any point and review the content captured from previous sessions, including additional suggestions or focus areas applied by the host.
          br
          q-icon(name="verified" color="secondary" size="2em").on-left
          span.text-body1 Preparation screen for the coach allowing them to review all past sessions and captured annotations and recordings before the session begins.
          q-separator.q-my-lg
          q-icon(name="bug_report" color="grey" size="2em").on-left
          span.text-body1 Features developed in response to the lack of connection to a programme between sessions for a client, and the ability to reflect on concrete things that were discussed.
          br
        .col-12.col-md-6(:class="{'order-first':$q.screen.lt.md}")
          Browser
            q-img(src="/4.png")


</template>

<script>
// var taskLists = require("markdown-it-task-lists");
// const md = require("markdown-it")({ html: true }).use(taskLists, {
//   enabled: true,
// });

import Browser from "../components/utils/Browser.vue";

export default {
  data: function () {
    return {
      loading: true,
      docs: "",
    };
  },
  components:{Browser},
  async mounted() {
    //go get docs:
    // console.log("getting", process.env.VUE_APP_DOCS_URL);
    // const raw = await fetch(process.env.VUE_APP_DOCS_URL, { mode: "cors" });
    // console.log(await raw.text());
    //render docs:
    // this.docs = md.render(await raw.text());
    // this.loading = false;
  },
};
</script>

<style scoped lang="scss">
@import '~/src/styles/quasar.variables.sass';

blockquote {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 22px;
  margin:0;
}

.title {
  // border-bottom: 2px solid $secondary;
  background: $secondary;
  color:white;
  display:inline-block;
  padding:4px;
  font-size: 24px;
  border-radius: 2px;
  margin-bottom:16px;
  line-height: 27px;
}

.highlight {
  background: $secondary;
  color:white;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
}
</style>
