<template lang="pug">
div
  .row.items-center
    .col-auto
      q-icon.q-mr-xs(name="info", color="grey-6")
    .col
      .text-grey-6(v-if="item.type == 'slide'") This segment has been suggested because it captures feedback from the client.
      .text-grey-6(v-if="item.type == 'tag'") This segment has been suggested because it captures an part of the session you highlighted.
      .text-grey-6(v-if="item.type == 'auto'") This segment has been suggested based on a number of factors.
      .text-grey-6(v-if="item.type == 'manual'") This segment is one that you have decided to keep.
  .row.q-col-gutter-xs
    .col
      q-scroll-area.q-mt-sm.rounded-borders(
        style="height: 300px; border: 1px solid #eee",
        ref="scrollArea"
      )
        .transcript(
          style="margin-right: 30px; margin-top: 5px; margin-bottom: 5px; margin-left: 5px; position: relative"
        )
          .timestamp(
            v-for="(item, index) in transcript.beforeArr",
            :data-time="getCurrentStartTime(item.start)",
            :class="{ t_highlight: isCurrentTime(item) }"
          )
            EditString(
            :value="item.content",
            :path="`${index}/text`",
            :fbref="fbref"
            :index="index"
            :transcription="transcription"
          )

          .timestamp(
            v-for="(item, index) in transcript.currentArr",
            :data-time="getCurrentStartTime(item.start)",
            :class="{ t_highlight: isCurrentTime(item) }"
          )
            a(v-if="index === 0", :id="`marked-${manualKey}`")
            span.text-secondary
              EditString(
            :value="item.content",
            :path="`${index + transcript.beforeArr.length}/text`",
            :fbref="fbref"
            :index="index + transcript.beforeArr.length"
            :transcription="transcription"
          )

          .timestamp(
            v-for="(item, index) in transcript.afterArr",
            :data-time="getCurrentStartTime(item.start)"
          ) 
            EditString(
            :value="item.content",
            :path="`${index + transcript.currentArr.length + transcript.beforeArr.length}/text`",
            :fbref="fbref"
            :index="index + transcript.currentArr.length + transcript.beforeArr.length"
            :transcription="transcription"
          )
    .col-12.col-md-auto
      .row.justify-center
        video-player.vjs-custom-skin.q-mt-sm(
          style="border: 1px solid #eee",
          ref="videoPlayer",
          :options="playerOptions",
          @play="onPlayerPlay($event)",
          @pause="onPlayerPause($event)",
          @ended="onPlayerEnded($event)",
          @loadeddata="onPlayerLoadeddata($event)",
          @waiting="onPlayerWaiting($event)",
          @playing="onPlayerPlaying($event)",
          @timeupdate="onPlayerTimeupdate($event)",
          @canplay="onPlayerCanplay($event)",
          @canplaythrough="onPlayerCanplaythrough($event)",
          @ready="playerReadied",
          @statechanged="playerStateChanged($event)"
        )

  .row.q-mb-sm.justify-center.full-width
    //- Tagging(
    //-   :key="this.item.key",
    //-   :meetingid="meetingid",
    //-   :actualStartTime="actualStartTime",
    //-   :actualEndTime="actualEndTime"
    //- )
    Slider(
      :meetingid="meetingid",
      :patientid="patientid",
      :duration="duration",
      :item="item",
      :startTime="startTime",
      :endTime="endTime",
      :slides="slides",
      :slideChange="onChange",
      :sections="sections",
      :sectionNames="sectionNames",
      :actualStartTime="actualStartTime",
      :transcript="transcription",
      :annotations="annotations"
      :actualEndTime="actualEndTime"
    )
</template>

<script>
import { db } from "@/db";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { formatMinuteSecond, formatDuration } from "../../utils/formatter";
import { getTranscription,getNearestStart,getNearestEnd } from "../../utils/transcription";
import Slider from "./Slider.vue";
import Tagging from "./Tagging.vue";
// import { scroll } from "quasar";
import notifications from "../../utils/notifications";
import EditString from '../dialogs/EditString.vue';


export default {
  name: "VideoCuration",
  mixins: [notifications],
  props: {
    annotations: {
      required: true,
    },
    meetingid: {
      required: true,
    },
    patientid: {
      required: true,
    },
    url: {
      required: true,
    },
    startTime: {
      required: true,
    },
    endTime: {
      required: true,
    },
    item: {
      required: true,
    },
    sections: {
      required: true,
    },
    slides: {
      required: true,
    },
    actualStartTime: {
      required: true,
    },
    actualEndTime: {
      required: true,
    },
    sectionNames: {
      required: true,
    },
    transcription: {
      required: true,
    },
    manualKey: {
      required: false,
    },
    suggested: {
      required: false,
    },
    index: {
      required: true,
    },
    coachid: {
      required: true,
    },
  },
  components: { videoPlayer, Slider, Tagging,EditString },
  data() {
    return {
      play: null,
      duration: 0,
      currentTime: 0,
      playerOptions: {
        autoplay: false,
        muted: false,
        language: "en",
        sources: [{ src: this.url, type: "video/mp4" }],
      },
      saving: false,
      fbref:null
    };
  },
  computed: {
    min() {
      return this.item.editCurrent
        ? this.item.editCurrent.startTime
        : this.startTime;
    },
    max() {
      return this.item.editCurrent
        ? this.item.editCurrent.endTime
        : this.endTime;
    },
    transcript() {
      if (!this.transcription) return "";
      // console.log("getting transcription");
      return getTranscription(this.transcription, this.min, this.max);
    },
  },
  methods: {
    
    pause: function () {
      this.player.pause();
    },
    isCurrentTime(item) {
      return this.currentTime > item.start && this.currentTime < item.end;
    },
    async slideChange(min, max) {
      this.player.currentTime(min);

      // console.log(min, max);

      // Save the editing history to the curation data node
      this.saving = true;
      // const { startTime, endTime } = this.item;
      const timestamp = Date.now();
      // console.log(this.item);
      const dataPath = `${this.meetingid}/${this.item.key}`;
      // this.min = min;
      // this.max = max;

      // const dataPath =
      //   this.item.type === "manual"
      //     ? `${this.meetingid}/manual/${this.manualKey}`
      //     : `${this.meetingid}/${startTime}/${endTime}`;
      try {
        // console.log(min);

        await db
          .ref("data/curation")
          .child(`${dataPath}/editCurrent`)
          .update({ startTime: min, endTime: max });

        db.ref("data/curation")
          .child(`${dataPath}/_editHistory/${timestamp}`)
          .set({ startTime: min, endTime: max });

        this.$set(this.item, "editCurrent", { startTime: min, endTime: max });
      } catch (e) {
        this.showError(e);
      }
      this.saving = false;
    },
    getCurrentStartTime(start) {
      return formatMinuteSecond(start);
    },
    getCurrentEndTime(end) {
      return formatMinuteSecond(end);
    },
    getEndTime() {
      return formatMinuteSecond(this.duration);
    },
    getTimeLabel() {
      return `Suggested: ${formatMinuteSecond(
        this.startTime
      )} - ${formatMinuteSecond(this.endTime)}`;
    },
    getDuration() {
      return `${formatDuration(this.endTime - this.startTime)}`;
    },
    onPlayerPlay(player) {
      this.$emit("onPlay", this);
    },
    onPlayerPause(player) {
      // console.log("player pause!", player);
    },
    onPlayerEnded(player) {
      // console.log("player ended!", player);
    },
    onPlayerLoadeddata(player) {
      // console.log("player Loadeddata!", player);
      // console.log(player.duration());
      if (!this.duration) {
        // player.pause();
        const videoDuration = Math.round(player.duration() * 10) / 10;
        this.duration = videoDuration;
      }
    },
    onPlayerWaiting(player) {
      // console.log("player Waiting!", player);
    },
    onPlayerPlaying(player) {
      // console.log("player Playing!", player);
    },
    onPlayerTimeupdate(player) {
      // Looping the current range
      if (player.currentTime() >= this.max) this.player.currentTime(this.min);

      this.currentTime = player.currentTime();
    },
    onPlayerCanplay(player) {
      // console.log("player Canplay!", player);
    },
    onPlayerCanplaythrough(player) {
      // console.log("player Canplaythrough!", player);
    },
    playerStateChanged(playerCurrentState) {
      // console.log("player current update state", playerCurrentState);
    },
    playerReadied(player) {
      this.player = player;
      this.player.currentTime(this.startTime);
    },
    onChange(min, max) {
      // console.log(min, max);
      this.slideChange(min, max);
      setTimeout(() => {
        this.scroll();
      }, 1000);
    },
    scroll() {
      // const { getScrollTarget, setScrollPosition } = scroll;

      // console.log(this.manualKey);

      const ele = document.getElementById(`marked-${this.manualKey}`);
      // console.log(ele);
      if (ele) {
        // const target = getScrollTarget(ele);
        // console.log(ele.getBoundingClientRect().top);
        // const offset = ele.offsetTop;
        // console.log(ele.offsetParent);
        // const eleRect = ele.getBoundingClientRect();
        // const targetRect = target.getBoundingClientRect();
        // const offset = eleRect.top - targetRect.top;
        // console.log(offset);

        // const duration = 200;

        // setScrollPosition(target, offset, duration);
        ele.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      // throw new Error("this error");
    },
  },
  async mounted() {
    this.fbref = db.ref(`data/transcription/${this.meetingid}/raw/`)
    // console.log(this.index);
    if (this.index == 0) {
      setTimeout(() => {
        this.scroll();
      }, 1000);
    }
  },
};
</script>

<style lang="sass">
.vjs-paused.vjs-has-started.vjs-custom-skin > .video-js .vjs-big-play-button,
.video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button
  display: block

.video-js
  width: 340px
  height: 127px

.vjs-custom-skin > .video-js .vjs-load-progress div,
.vjs-seeking .vjs-big-play-button,
.vjs-waiting .vjs-big-play-button
  display: none !important

.vjs-custom-skin > .video-js .vjs-big-play-button
  top: 50%
  left: 50%
  margin-left: -1.5em
  margin-top: -1em

.vjs-custom-skin
  border-radius: 4px
  overflow: hidden

.vjs-custom-skin > .video-js .vjs-big-play-button
  background-color: rgba(0,0,0,0.45)
  font-size: 2.5em
  height: 2em !important
  line-height: 2em !important
  margin-top: -1em !important

.inline-text
  display: inline

.transcript
  line-height: 30px
  margin-top: 10px

.timestamp
  display: inline
  position: relative

.timestamp::before
  position: absolute
  top: -20px
  font-size: 10px
  color: #ccc
  content: attr(data-time)

.video-js .vjs-tech
  position: absolute
  top: -64px
  left: 0
  width: 100%
  height: 255px

.t_highlight
  text-shadow: 0px 0px 2px var(--q-color-secondary)
</style>
