<template lang="pug">
q-card.patient-card(clickable)
  q-card-section.bg-green.text-white.text-center
    .text-h4 {{ nextSession }}
  //- q-card-section.bg-primary.text-white.horizontal

  q-card-section
    .text-h6 {{ sessionName }}
    .row
      .col-12
        .text-h5.text-bold.q-mb-xs {{ patient.name }}
      .col-12
        .text-grey-6.q-mb-sm(v-if="patient") {{ patient.email }} &middot; {{ uid }}
        //- q-chip(icon="event", size="small", dense, outline) {{ nextSession ? nextSession : 'No Session Scheduled' }}
        //-   q-tooltip Next Session
        //- q-chip(icon="notifications_active", size="small", dense, outline) {{ remindBy ? remindBy : 'No Reminder' }}
          q-tooltip Reminder

  q-separator

  q-card-actions.q-pa-none.justify-end
    .row.q-pr-sm
      q-btn(flat, color="secondary", :to="'/dashboard/' + uid") View
</template>

<script>
import moment from "moment-timezone";

export default {
  props: {
    uid: {
      type: String,
      required: true,
    },
    patient: {
      required: true,
    },
    nextSession: {
      type: String,
      required: true,
    },
    sessionName: {
      type: String,
      required: true,
    },
  },
  methods: {
    getTime(from) {
      return moment(from).format("LT");
    },
  },
};
</script>

<style lang="sass" scoped>
.patient-card
  border-radius: 8px
</style>