import { render, staticRenderFns } from "./InspectVideo.vue?vue&type=template&id=be12695a&lang=pug&"
import script from "./InspectVideo.vue?vue&type=script&lang=js&"
export * from "./InspectVideo.vue?vue&type=script&lang=js&"
import style0 from "./InspectVideo.vue?vue&type=style&index=0&id=be12695a&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QChip from 'quasar/src/components/chip/QChip.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QChip,QTooltip,QBadge,QColor});
