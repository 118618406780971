<template lang="pug">
div.full-width
  portal(to="header_buttons", v-if="id")
    q-btn(
      rounded,
      flat,
      dense,
      icon="arrow_back_ios_new",
      color="primary",
      to="/admin/sessions",
    ) 
  .loading.fixed-full.z-top.dimmed.row(v-show="loading").items-center.justify-center
    q-spinner(size="xl")
  .row.full-width(v-if="schedule")
    .col-12
      InspectVideo.q-mb-xl(
        ref="videoPlayer",
        v-on:onPlay="onPlay($event, this)",
        :meetingid="id",
        :patientid="patientid",
        :url="videoSrc",
        :startTime="0",
        :endTime="meta.actualEndTime - meta.actualStartTime / 1000",
        :actualStartTime="meta.actualStartTime",
        :actualEndTime="meta.actualEndTime",
        :sections="sections",
        :slides="slides",
        :sectionNames="sectionNames",
        :annotations="annotations",
        :transcription="transcription",
        :coachid="meta.uid"
      )
  </template>

<script>
import { mapGetters } from "vuex";
import { db, getRecordingForSession } from "@/db";
import { find, map, filter, groupBy, isObject } from "lodash";
// import { QSpinnerBars } from "quasar";
import InspectVideo from "@/components/Curation/InspectVideo.vue";
// import { getMediaConvertTimeCode } from "../utils/formatter";
// import moment from "moment-timezone";

export default {
  // mixins: [notifications],
  props: {
    id: {
      required: true,
    },
  },
  components: { InspectVideo },
  computed: {
    db() {
      return db;
    },
    annotations() {
      let annotation_keys = filter(
        Object.keys(this.slideChange?.current ?? {}),
        (o) => o.startsWith("_annotation")
      );

      let expt = {};

      //find the last timestamp for which each annotation exists: (inefficient)
      for (const key of annotation_keys) {
        let stamp = -1;
        for (const time in this.slideChange._history) {
          if (Object.keys(this.slideChange._history[time]).includes(key)) {
            //this event is for this annotation:
            if (time > stamp) {
              stamp = time;
            }
          }
        }

        //calculate time from start of meeting:
        // Math.round(timestamp * 10) / 10
        stamp =
          Math.round(((stamp - this.meta.actualStartTime) / 1000) * 10) / 10;
        expt[stamp] = this.slideChange.current[key];
      }

      return expt;
    },
    sections() {
      if (this.sectionChange) {
        let allHistory = this.sectionChange["_history"];
        // Append the meeting end time to get the duration for the last section
        allHistory[this.actualEndTime] = { _currentSection: -1 };
        const keys = Object.keys(allHistory);

        const sectionMap = {};
        for (let i = 1; i < keys.length; i++) {
          const duration = keys[i] - keys[i - 1];
          const prev = allHistory[keys[i - 1]]["_currentSection"];

          if (!sectionMap[prev] || sectionMap[prev].duration < duration)
            sectionMap[prev] = {
              duration,
              startTime: keys[i - 1],
              endTime: keys[i],
            };
        }
        return sectionMap;
      }
      return null;
    },
    slides() {
      if (this.slideChange && this.meta) {
        const noOfSections = Object.keys(this.schedule.script).length;

        const resultArr = {};
        let total = 0;

        for (let i = 0; i < noOfSections; i++) {
          const section = this.sections[i];
          // console.log(this.sections);

          if (section) {
            let nextSectionIndex = i + 1;

            if (i < noOfSections) {
              while (
                typeof this.sections[nextSectionIndex] === "undefined" &&
                nextSectionIndex < noOfSections - 1
              )
                nextSectionIndex++;

              // console.log(`ended while loop at ${nextSectionIndex} for ${i}`);
            }

            // const nextStart =
            //   i < noOfSections - 1
            //     ? this.sections[i + 1].startTime
            //     : this.meta.actualEndTime;

            const nextStart =
              i < noOfSections - 1
                ? this.sections[nextSectionIndex].startTime
                : this.meta.actualEndTime;

            const slideMap = this.getSlideTimestamps(
              section,
              nextSectionIndex,
              nextStart
            );
            const sectionSlides = this.getSectionSlides(i);
            const noOfSlides = sectionSlides.length;

            for (let j = 0; j < noOfSlides; j++) {
              const current = sectionSlides[j];

              if (slideMap[j]) {
                resultArr[total] = {
                  ...slideMap[j],
                  type: "slide",
                  slideContent: current,
                };
                ++total;
              }
            }
          }
        }
        return resultArr;
      }
      return null;
    },
    sectionNames() {
      // console.log(Object.keys(this.schedule.script));
      return map(this.schedule.script, "name");
    },
    ...mapGetters(["getUser"]),
  },
  methods: {
    // onPlay(obj) {
    //   // console.log("playing", obj);

    //   for (const video of this.$refs.videoPlayer) {
    //     // console.log(video);
    //     if (video != obj) video.pause();
    //   }
    // },
    getSlideTimestamps(current, index, nextStart) {
      let allHistory = this.slideChange["_history"];

      allHistory = Object.fromEntries(
        Object.entries(allHistory).filter(
          (history) =>
            history[1]["_slide"] !== undefined &&
            history[0] >= current.startTime &&
            history[0] <= current.endTime
        )
      );
      allHistory[nextStart] = { _slide: -1 };

      const keys = Object.keys(allHistory);
      const slideMap = {};

      for (let i = 1; i < keys.length; i++) {
        const duration = keys[i] - keys[i - 1];
        const prev = allHistory[keys[i - 1]]["_slide"];

        if (!slideMap[prev] || slideMap[prev].duration < duration)
          slideMap[prev] = {
            duration,
            startTime: keys[i - 1],
            endTime: keys[i],
            sectionIdx: index,
          };
      }

      return slideMap;
    },
    getSectionSlides(index) {
      const plugins = this.schedule.script[index].plugins;
      const tmp = find(plugins, (plugin) => plugin.plugin === "Slides");

      if (tmp) return tmp.settings.content;
      return [];
    },
  },
  data: () => {
    return {
      loading: true,
      step: 1,
      tagging: null,
      recording: null,
      slideChange: null,
      sectionChange: null,
      schedule: null,
      currentSection: null,
      curation: {},
      removeLoading: false,
      saveLoading: false,
      currentItem: null,
      transcription: null,
      transcriptionObj: null,
      tags: [],
      recordingList: {},
      meeting: {},
      videoSrc: null,
      patientid: null,
      actualEndTime: null,
      meta: null,
    };
  },
  async mounted() {
    //have to get these from the meetingid:

    this.meta = (
      await db.ref("data/meetingMeta").child(this.id).once("value")
    ).val();
    this.patientid = this.meta.patientUid;
    this.actualEndTime = this.meta.actualEndTime;
    // this.coachId = this.meta.uid;

    // patientid: {
    //   required: true,
    // },
    // actualEndTime: {
    //   required: true,
    // },
    // meta: {
    //   required: true,
    // },

    try {
      await Promise.all([
        this.$rtdbBind("tagging", db.ref("data/tagging").child(this.id)),
        this.$rtdbBind("recording", db.ref("data/recording").child(this.id)),
        this.$rtdbBind("slideChange", db.ref("data/slides").child(this.id)),
        this.$rtdbBind(
          "sectionChange",
          db.ref("data/sectionChange").child(this.id)
        ),
        this.$rtdbBind("curation", db.ref(`data/curation/${this.id}`)),
        this.$rtdbBind(
          "meeting",
          db.ref(`meetings/${this.meta.uid}/${this.id}`)
        ),
        this.$rtdbBind(
          "schedule",
          db.ref(`config/${this.id}/schedule/schedule`)
        ),
        this.$rtdbBind("tags", db.ref(`meta/tags`)),
        // this.$rtdbBind("meta", db.ref(`data/meetingMeta/${this.id}`)),
      ]);

      //load video src:
      const url = await getRecordingForSession({
        meetingid: this.id,
        coachid: this.meta.uid,
      });

      this.videoSrc = url.data;

      const transcriptionSnap = (
        await db.ref(`data/transcription/${this.id}`).once("value")
      ).val();
      if (transcriptionSnap) {
        this.transcriptionObj = transcriptionSnap;
        this.transcription = transcriptionSnap.raw;
      }
    } catch (e) {
      console.log(e);
      this.showError(e);
    }

    // this.$q.loading.hide();
    this.loading = false;
  },
  created() {},
};
</script>

<style lang="sass" scoped>
.asset-card
  border-radius: 20px
  width: 100%
  height: 00%

.slide
  color: white
  border-radius: 5px
  padding: 8px
  font-size: 14px
  overflow: hidden
</style>
