<template lang="pug">
div(v-if="timer")
  .text-h5.text-bold.text-grey-8 {{ display.hours + ' : ' + display.mins + ' : ' + display.seconds }}
</template>

<script>
import { crono } from "vue-crono";

export default {
  mixins: [crono],
  created() {},
  mounted() {},
  methods: {
    loadTime() {
      this.timer = (Date.now() - this.startedAt) / 1000;
    },
  },
  props: ["startedAt"],
  data() {
    return {
      timer: 0,
    };
  },
  computed: {
    display() {
      // console.log(this.timer);
      const secs = this.timer;
      let leftover = secs % 86400;
      const hours = Math.floor(leftover / 3600)
        .toString()
        .padStart(2, 0);
      leftover = leftover % 3600;
      const mins = Math.floor(leftover / 60)
        .toString()
        .padStart(2, 0);
      leftover = leftover % 60;
      const seconds = Math.floor(leftover).toString().padStart(2, 0);

      return {
        hours,
        mins,
        seconds,
      };
    },
  },
  cron: {
    time: 1000,
    method: "loadTime",
    autoStart: true,
  },
};
</script>

<style>
</style>
