<template lang="pug">
.hover {{finalstring}}&nbsp;
  q-popup-edit(v-model="newstring" :validate="val => val.length > 0" @save="save()")
    q-input(v-model="newstring" dense autofocus style="min-width:200px;")
    .text-grey-5.q-mt-xs.text-tiny This is designed for minor edits of the transcription, for sharing only.
</template>
<script>
export default {
  name: "EditString",
  props: ["value", "path", "fbref","transcription","index"],
  data: () => {
    return {
      newstring: "",
      finalstring:""
    };
  },
  methods: {
    async save() {
      await this.fbref.child(this.path).set(this.newstring);
      this.finalstring = this.newstring;
      // console.log(this.index);
      this.transcription[this.index].text = this.newstring;

      // console.log(this.transcription[this.index]);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.newstring = this.value;
        this.finalstring = this.value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.hover {
  display:inline;
}
.hover:hover {
  // text-decoration: underline;
  border-radius: 2px;
  background-color: rgb(227, 255, 255);
  cursor: pointer;
}
.text-tiny {
  font-size: 9pt;
}
</style>
