<template>
  <div ref="pub_container"></div>
</template>

<script>
import OT from "@opentok/client";

export default {
  name: "publisher",
  props: {
    session: {
      type: OT.Session,
      required: false,
    },
    opts: {
      type: Object,
      required: false,
    },
    audioInput: {
      required: true,
    },
    videoInput: {
      required: true,
    },
    actualHeight: {},
    isMuted: {
      type: Boolean,
    },
  },
  publisher: null,
  methods: {
    publish() {
      this.session.publish(this.publisher, (err) => {
        if (err) {
          this.$emit("error", err);
        } else {
          this.$emit("publisherConnected", this.publisher);
        }
      });
    },
    disconnect() {
      // console.log("destroy pub");
      this.publisher.destroy();
    },
  },
  watch: {
    isMuted() {
      console.log(`Mute: ${this.isMuted}`);
      this.publisher.publishAudio(!this.isMuted);
    },
    actualHeight() {
      // console.log(this.actualHeight);
      this.$refs.pub_container.style.height = `${this.actualHeight}px`;
    },
    async videoInput() {
      // console.log(this.videoInput);
      try {
        await this.publisher.setVideoSource(this.videoInput);
      } catch (e) {
        this.$emit("error", e);
      }
    },
    async audioInput() {
      // console.log(this.videoInput.deviceId);
      try {
        await this.publisher.setAudioSource(this.audioInput);
      } catch (e) {
        this.$emit("error", e);
      }
    },
  },
  mounted: async function () {
    // console.log(this.session.getPublisherForStream());
    this.publisher = OT.initPublisher(this.$el, this.opts, (err) => {
      if (err) {
        this.$emit("error", err);
      } else {
        this.$emit("publisherCompleted");
      }
    });

    this.$emit("publisherCreated", this.publisher);

    if (this.session && this.session.isConnected()) {
      this.publish();
    }

    if (this.session) {
      this.session.on("sessionConnected", this.publish);
    }
  },
};
</script>
