<template lang="pug">
.text-caption.text-primary Powered by <a href="https://gitlab.com/action-lab-aus/bicara/zoomsense-tops">Bicara</a> &middot; Code licenced under MIT &middot; Content licensed under Creative Commons BY 4.0 &middot; v{{ version }}
</template>

<script>
export default {
  data: () => {
    return {
      version: process.env.PACKAGE_VERSION,
    };
  },
};
</script>