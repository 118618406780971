<template lang="pug">
q-item(clickable, :to="'/dashboard/' + uid")
  q-item-section
    .text-body1(v-if="name") {{ name }}
  q-item-section
    .text-grey-6(v-if="patient") {{ patient.email }} &middot; {{ uid }}

  q-item-section(side)
    .row
      .col-12(style="min-width: 200px")
        q-chip(icon="event", size="small", dense, outline, v-if="nextSession") {{ getTime(nextSession.startTime) }}
          q-tooltip Next Session
</template>

<script>
import moment from "moment-timezone";

export default {
  props: {
    uid: {
      type: String,
      required: true,
    },
    patient: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    nextSession: {
      type: Object,
    },
  },
  methods: {
    getTime(time) {
      return moment(time).format("ddd, h:mma");
    },
  },
};
</script>

<style lang="sass" scoped>
.patient-card
  border-radius: 8px
</style>
