<template lang="pug">
div(:class="{ nomargin: !demo }")
  v-style {{ style.style }}
  .rightclear(v-if="!demo")
  .bottomclear(v-if="!demo")
  .widescreen.bicara-scoped-template(:class="{ 'noborder imgbackground': demo }")
    //- div {{ uilibs }}
    //- div {{ library }}
    div(v-for="(control,index) of uilibs", :key="index" :data-plugin="index" v-if="!control.clickable",)
      keep-alive
        ComputedComponent(
          :library="library",
          :component="control",
          type="Overlay",
          :firebase="db",
          :demo="demo",
          :functions="functions",
          :context="context"
        )
    //- this is hidden for the host's version of the overlay (so they can click on things)
    .cover(v-show="nonInteractive")
    //- thesse plugins can be interacted with by either the host or client regardless
    div(v-for="(control,index) of uilibs", :key="`o_${index}`" :data-plugin="index" v-if="control.clickable",)
      keep-alive
        ComputedComponent(
          style="z-index: 200000",
          :library="library",
          :component="control",
          type="Overlay",
          :demo="demo",
          :firebase="db",
          :functions="functions",
          :context="context"
        ) 
</template>

<script>
// designed to be routed to directly /display/:meetingid
import ComputedComponent from "@/components/utils/ComputedComponent.vue";
import { find, includes, isEqual } from "lodash";

import { db, functions, getStyleForMeeting } from "@/db";

const BASE_URL = process.env.VUE_APP_PLUGIN_DIRECTORY;

export default {
  name: "OverlayDisplay",
  plugins: [],
  props: {
    host: {
      type: Boolean,
    },
    meetingid: {
      type: String,
      required: true,
    },
    templateid: {
      type: String,
      required: false,
    },
    sequenceid: {
      type: String,
    },
    demo: {
      type: Boolean,
      default: false,
    },
    nonInteractive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ComputedComponent,
  },
  computed: {
    context() {
      return {
        meetingid: this.meetingid,
        sequenceid: this.sequenceid,
        host: this.host,
      };
    },
    db() {
      return db;
    },
    functions() {
      return functions;
    },
  },
  data: () => {
    return {
      config: {},
      uilibs: {},
      library: {},
      style: {},
    };
  },
  async mounted() {
    // console.log(this.plugins);
  },
  methods: {
    async calcUI(newVal, oldVal) {
      if (!this.plugins) {
        let resp = await fetch(`${BASE_URL}index.json`);
        this.plugins = await resp.json();
      }

      // console.log(oldVal, newVal);
      let oldKeys = Object.keys(oldVal);
      let newKeys = Object.keys(newVal);
      // console.log(oldKeys, newKeys, isEqual(oldKeys, newKeys));

      //plugins are the same:
      if (!isEqual(oldKeys, newKeys)) {
        // console.log("processing");
        //for each key, if that key matches a plugin with an overlay component, then load it if it has an overlay option
        for (let plugneeded of newKeys) {
          //if oldkeys does not in includes the newkey, go get it
          if (!includes(oldKeys, plugneeded)) {
            // console.log(plugneeded, this.plugins);
            let plug = find(this.plugins, function (pl) {
              return pl.plugin.toLowerCase() == plugneeded.toLowerCase();
            });

            // console.log(plug);
            if (plug && includes(plug.exports, "Overlay")) {
              this.$set(this.uilibs, plugneeded, {
                url: `${BASE_URL}${plug.modules[0].path}`,
                plugin: plug,
                clickable: plug.clickable,
              });
              // this.uilibs.push();
            }
          } else {
            // console.log(`${plugneeded} already loaded`);
          }
        }

        //go through oldkeys that are not in newkeys and remove:
        for (let plugremove of oldKeys) {
          if (!includes(newKeys, plugremove)) {
            this.$delete(this.uilibs, plugremove);
          }
        }
      }
    },
  },
  watch: {
    config: {
      async handler(newVal, oldVal) {
        this.calcUI(newVal, oldVal);
      },
    },
    meetingid: {
      immediate: true,
      async handler() {
        this.$rtdbBind(
          "config",
          db.ref(`config/${this.meetingid}/current/currentState/plugins`)
        );

        //get the style for this template:
        if (this.demo)
          this.style = (
            await getStyleForMeeting({ templateid: this.templateid })
          ).data;
        else
          this.style = (
            await getStyleForMeeting({ meetingid: this.meetingid })
          ).data;
        // console.log(this.style);
      },
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Titillium+Web&display=swap");
</style>

<style lang="scss">
.nomargin {
  margin: -8px;
}

.cover {
  width: 1280px;
  height: 720px;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200;
}

.widescreen {
  width: 1280px;
  height: 720px;
  overflow: hidden;
  position: absolute;
  // outline: solid yellow 5px;
  left: 0;
  top: 0;
  // z-index: -1;
}

.fade-in-enter-active {
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
  transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.live {
  color: var(--textdark);
  position: absolute;
  right: 14px;
  top: 10px;

  // border:solid 1px var(--primary);
  // padding-left:115px;
  div {
    display: inline-block;
    background: var(--primary);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: var(--corners) var(--corners) var(--corners) var(--corners);
  }
}

.classlabel {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  display: none;
  background: red;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 8pt;
  line-height: 15px;
  // height:20px;
  opacity: 0.2;
}

.showclassname {
  .classlabel {
    display: block !important;
  }
  .classborder {
    // box-shadow: 0px 3px 0px red inset;
    outline: 2px solid red;
    outline-offset: -2px;

    &:hover {
      cursor: pointer;
      .classlabel {
        opacity: 1;
      }
    }

    // &:hover {
    // opacity: 1;
    // }
  }
}

.rightclear {
  background-color: #333;
  position: absolute;
  top: 0;
  left: 1285px;
  bottom: 0;
  right: 0;
}

.bottomclear {
  background-color: #333;
  position: absolute;
  top: 725px;
  left: 0;
  bottom: 0;
  right: 0;
}

.noborder {
  outline: none;
}

.imgbackground {
  background-image: url(../assets/example1.jpg) !important;
}
</style>
