import { render, staticRenderFns } from "./CurationButtonGroup.vue?vue&type=template&id=5c71f8c6&scoped=true&lang=pug&"
import script from "./CurationButtonGroup.vue?vue&type=script&lang=js&"
export * from "./CurationButtonGroup.vue?vue&type=script&lang=js&"
import style0 from "./CurationButtonGroup.vue?vue&type=style&index=0&id=5c71f8c6&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c71f8c6",
  null
  
)

export default component.exports
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCardActions,QSelect,QItem,QItemSection,QAvatar,QItemLabel,QIcon,QSeparator,QInput,QBtn,QInnerLoading});
