<template lang="pug">
.row.fit
  .col.full-height(style="position: relative")
    .text-center
      q-spinner(v-if="loading", size="lg")

    q-resize-observer(@resize="onResize", debounce="200", ref="resizeMe")
    q-stepper.movestepper.bg-grey-1(
      v-model="currentstate.current.currentSection",
      color="primary",
      flat,
      header-class="bottom-border no-wrap",
    )
      q-step(
        :title="script.name",
        v-for="(script, index) in currentstate.schedule.schedule.script",
        :key="index",
        :name="index",
        :prefix="index + 1",
        active-icon="south",
        ref="steps"
        :style="{ height: `${tabHeight - 62}px` }"
      )
        .column(
          :style="{ height: `${tabHeight - 128}px` }",
          style="border-top: solid silver 1px; margin-top: -20px"
        )
          .text-center.q-pa-md(v-if="!sessionLoaded()")
            q-spinner(, size="2em")
          .col.scroll
            DashboardControls.q-pa-sm(
              v-if="sessionLoaded()",
              :library="library",
              :key="index",
              :meetingid="meetingid",
              :sequenceid="sequenceid",
              :config="script",
              :index="index",
              :currentstate="currentstate.current.currentState.plugins"
            )

        .fixed-bottom.top-border.bg-grey-2
          .row.items-center
            .col-auto(v-if="index > 0")
              .q-mr-md
                q-btn(
                  rounded,
                  @click="prev",
                  color="grey-5",
                  icon="west",
                  flat
                )
            q-space
            .col-auto(v-if="script.advance.type == 'timed'")
              .q-mr-md
                q-chip(color="warning", text-color="white") Moving on {{ countdown }}
            .col-auto(v-if="script.advance.type == 'auto'")
              .q-mr-md
                q-chip(color="info", text-color="white") Moving on automatically
            .col-auto(
              v-if="script.advance.type == 'manual' && index < currentstate.schedule.schedule.script.length - 1"
            )
              .q-mr-md
                q-btn(
                  rounded,
                  @click="next",
                  color="secondary",
                  label="Move to Next Section",
                  icon-right="east",
                  style="margin-right: -20px"
                )
            .col-auto(
              v-if="script.advance.type == 'timed' || script.advance.type == 'auto'"
            )
              .q-mr-md
                q-btn(@click="next", color="grey-5", icon="south", flat)
                  q-tooltip Manually skip to next item
            .col-auto
              slot(
                name="ending",
                v-if="index == currentstate.schedule.schedule.script.length - 1"
              )
</template>

<script>
import DashboardControls from "./DashboardControls.vue";
import humanInterval from "human-interval";
import moment from "moment";
// import { filter } from "lodash";
import { db } from "@/db";
import { scroll } from "quasar";
const { getScrollTarget, setHorizontalScrollPosition } = scroll;

export default {
  name: "SessionController",
  props: {
    meetingid: {
      type: String,
      required: true,
    },
    sequenceid: {
      required: true,
    },
    sessionEnded: {
      required: false,
    },
  },
  data: () => {
    return {
      currentstate: {
        schedule: { schedule: { script: {} } },
        current: {
          currentSection: 0,
          currentState: {},
        },
      },
      loading: true,
      library: {},
      // plugins: [],
      tabHeight: 0,
    };
  },
  computed: {
    totalSections() {
      return this.currentstate.schedule.schedule.script.length;
    },
    countdown() {
      if (
        this.currentstate.schedule.schedule.script[
          this.currentstate.current.currentSection
        ].advance.type === "timed"
      ) {
        // amount due:
        let interval = humanInterval(
          this.currentstate.schedule.schedule.script[
            this.currentstate.current.currentSection
          ].advance.when
        );

        let futureTime =
          this.currentstate.current.currentState.startedAt + interval;

        return moment().to(moment(futureTime));
      } else {
        return " never";
      }
    },
  },
  methods: {
    onResize(report) {
      // console.log(report);
      this.tabHeight = report.height;
    },
    async next() {
      if (this.totalSections > this.currentstate.current.currentSection + 1) {
        this.currentstate.current.currentSection++;
        await db
          .ref(`config/${this.meetingid}/current/currentSection`)
          .set(this.currentstate.current.currentSection);
      }
    },
    async prev() {
      if (this.currentstate.current.currentSection > 0) {
        this.currentstate.current.currentSection--;
        await db
          .ref(`config/${this.meetingid}/current/currentSection`)
          .set(this.currentstate.current.currentSection);
      }
    },
    sessionLoaded() {
      // return true;
      return (
        this.currentstate.current.currentState.sectionLoaded ===
        this.currentstate.current.currentSection
      );
    },
  },
  components: {
    DashboardControls,
  },
  watch: {
    meetingid: {
      immediate: true,
      async handler() {
        // console.log(this.meetingid);
        // console.log(this.firebase);
        await this.$rtdbBind(
          "currentstate",
          db.ref(`config/${this.meetingid}`)
        );
        this.loading = false;
      },
    },
    sessionEnded: {
      immediate: true,
      async handler(val) {
        if (val === true) this.next();
      },
    },
    "currentstate.current.currentSection": {
      // immediate: true,
      handler() {
        // console.log("step changed", this.currentstate.current.currentSection);
        this.$nextTick(function () {
          // DOM is now updated
          // `this` is bound to the current instance

          // let el = this.$refs[`steps`];
          let els = document.querySelectorAll(".movestepper .q-stepper__tab");
          // console.log(els);
          let el = els[this.currentstate.current.currentSection];
          // console.log(el);
          // const target = getScrollTarget(el);
          const target = document.querySelector(
            ".movestepper .q-stepper__header"
          );
          // console.log(el);
          const offset = el.offsetLeft;
          const duration = 300;
          setHorizontalScrollPosition(target, offset, duration);
          // this.doSomethingElse()
        });
      },
    },
  },
  // async created() {
  // let resp = await fetch(`${process.env.VUE_APP_PLUGIN_DIRECTORY}index.json`);
  // this.plugins = await resp.json();
  // },
};
</script>
<style lang="sass">
.movestepper
  margin-left: -15px !important
  margin-right: -15px !important
  position: absolute
  bottom: 0
  top: 0
  left: 0
  right: 0

.movestepper
  .q-stepper__header
    overflow: hidden

.bottom-border
  // border-bottom: 1px red solid
  overflow-x: scroll
  max-height: 60px !important

.top-border
  height: 60px
  margin-left: -12px !important
  margin-right: -12px !important
  padding-top: 12px
  padding-left: 12px
  padding-right: 24px
  z-index: 1
  border-top: 1px silver solid
</style>
