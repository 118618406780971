import { render, staticRenderFns } from "./SessionItem.vue?vue&type=template&id=2783b13d&scoped=true&lang=pug&"
import script from "./SessionItem.vue?vue&type=script&lang=js&"
export * from "./SessionItem.vue?vue&type=script&lang=js&"
import style0 from "./SessionItem.vue?vue&type=style&index=0&id=2783b13d&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2783b13d",
  null
  
)

export default component.exports
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTimelineEntry,QItem,QItemSection,QChip,QBtn,QSeparator});
