export default {
  methods: {
    showError(err, msg) {
      this.$q.notify({
        message: msg || "An error ocurred",
        caption: `${err}`,
        type: "negative",
      });
      console.error(err);
    },
  },
};
