<template lang="pug">
div
  q-btn.q-mr-sm(
    round,
    @mouseover="hover = true",
    @mouseleave="hover = false",
    flat,
    dense,
    :to="getToPath()"
  )
    img(
      src="../../assets/favicon.svg",
      style="width: 32px",
      v-show="!hover"
    )
    q-icon(
      name="arrow_back_ios_new",
      v-if="hover",
      style="width: 32px"
    )
</template>

<script>
export default {
  name: "BackBtn",
  data: () => {
    return {
      hover: false,
    };
  },
  methods:{
    getToPath() {
      if (this.$route.name === "SessionDetail")
        return `/dashboard/${this.$route.params.uid}`;
      return "/dashboard";
    },
  }
}
</script>