<template lang="pug">
div(style="height: 100vh !important")
  div
    .row.justify-center
      Session.q-pa-sm(
        v-if="isSessionRunning && !sessionEnd && videoInput && audioInput && session.session",
        apiKey=process.env.apiKey,
        :sessionId="session.session",
        :token="session.token",
        ref="sessionRef",
        :patientid="patientUid",
        :meetingid="sessionId",
        publisherWidth=1120,
        publisherHeight=630,
        subscriberWidth=320,
        subscriberHeight=180,
        scalePatient,
        :audioInput="audioInput",
        :videoInput="videoInput"
      )

    .text-grey-6.absolute-center.text-center(
      v-if="!isSessionRunning && !sessionEnd"
    )
      .text-h4.text-secondary {{ `This session ${inPast} due to start ${timeFromNow}.` }}
      .text-h5 This page will refresh when your coach is online.
    .text-h3.text-grey-6.absolute-center.text-center(v-if="sessionEnd")
      .text-h4.text-secondary This session has ended.
      .text-h5 You can close this browser.
      .text-h5 You will receive an email explaining what to do next.

  q-btn.absolute-bottom-left.z-max.q-ma-lg(
    v-if="!sessionEnd && !showOnboarding",
    flat,
    round,
    icon="settings",
    dense,
    @click="showOnboarding = true"
  )
  .absolute-top-left.fit.bg-grey-4(v-if="showOnboarding && !sessionEnd")
    .row.full-width.q-mb-sm.q-px-xl.absolute-center.justify-center
      Onboarding(
        :selectAudioInput="selectAudioInput",
        :selectVideoInput="selectVideoInput",
        :confirmOnboarding="confirmOnboarding"
      )
</template>

<script>
import { db } from "@/db";
import Session from "@/components/OpenTok/Session.vue";
import Onboarding from "@/components/dialogs/Onboarding.vue";
import moment from "moment";
import { crono } from "vue-crono";
import notifications from "@/utils/notifications";

export default {
  props: {},
  components: { Session, Onboarding },
  mixins: [crono, notifications],
  computed: {
    db() {
      return db;
    },
    sessionEnd() {
      // return false;
      return this.session && this.session.actualEndTime;
    },
    isSessionRunning() {
      return this.session && this.session.actualStartTime;
    },
    timeFromNow() {
      //hack to make the computed binding recognise that the time has changed...
      let xx = this.currentTime - this.currentTime;
      return moment(this.session.startTime).add(xx).fromNow();
    },
    inPast() {
      return moment(this.session.startTime).isBefore() ? "was" : "is";
    },
  },
  cron: {
    time: 1000,
    method: "updateTime",
    autoStart: true,
  },
  watch: {
    session: {
      immediate: true,
      handler(session) {
        if (session && session.actualEndTime) this.endSession();
      },
    },
  },
  methods: {
    updateTime() {
      this.currentTime = moment().unix();
      //update firebase 'isonline' indicator:
      if (new Date().valueOf() - this.lastSentPing > 5000) {
        this.lastSentPing = new Date().valueOf();
        // console.log(`data/pings/${this.sessionId}/clientPing`);
        db.ref(`data/pings/${this.sessionId}/clientPing`).set(
          this.lastSentPing
        );
      }
    },
    endSession() {
      try {
        this.$refs.sessionRef.disconnect();
      } catch {
        //tried to disconnect...
      }
    },
    selectAudioInput(audioInput) {
      this.audioInput = audioInput;
    },
    selectVideoInput(videoInput) {
      this.videoInput = videoInput;
    },
    confirmOnboarding() {
      // console.log("confirmed");
      this.showOnboarding = false;
      localStorage[`confirmedSettings-${this.sessionId}`] = true;
    },
  },
  data: () => {
    return {
      currentTime: moment().unix(),
      coachUid: null,
      sessionId: null,
      session: {
        startTime: -1,
      },
      meetingMeta: null,
      audioInput: null,
      videoInput: null,
      showOnboarding: false,
      patientUid: null,
      lastSentPing: -1,
    };
  },
  async mounted() {
    this.$q.loading.show({
      message: "Loading your session...",
      customClass: "bg-grey-4",
    });

    this.coachUid = this.$route.params.coachid;
    this.sessionId = this.$route.params.meetingid;

    console.log(`Coach UID: ${this.coachUid}`);
    console.log(`Meeting ID: ${this.sessionId}`);
    // console.log("TEST");

    try {
      // await Promise.all([
      await this.$rtdbBind(
        "session",
        db.ref("meetings").child(this.coachUid).child(this.sessionId)
      );
      // this.$rtdbBind(
      //   "meetingMeta",
      //   db.ref("data").child("meetingMeta").child(this.sessionId)
      // ),
      // ]);

      this.patientUid = this.session.patientUid;

      // console.log(this.meetingMeta);

      console.log(`Starting with PID: ${this.patientUid}`);

      if (
        localStorage[`confirmedSettings-${this.sessionId}`] &&
        localStorage[`confirmedSettings-${this.sessionId}`] == "true" &&
        localStorage.audioInput &&
        localStorage.videoInput
      ) {
        // console.log("dont show");
        this.confirmOnboarding();
        this.audioInput = localStorage.audioInput;
        this.videoInput = localStorage.videoInput;
      } else {
        this.showOnboarding = true;
      }
    } catch (e) {
      this.showError(e);
    }
    this.$q.loading.hide();
  },
  created() {},
};
</script>

<style lang="scss" scoped>
</style>
