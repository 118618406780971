<template lang="pug">
q-page.q-ma-sm.q-pa-sm
  .row.q-col-gutter-sm(v-if="patients")
    .col-xs-12.col-sm
      q-select.full-width(
        dense,
        outlined,
        v-model="currentSession",
        :options="templates",
        option-label="name"
      )
        template(v-slot:prepend)
          q-icon(name="library_books", color="secondary", size="md")

    .col-xs-12.col-sm
      q-select.full-width(
        dense,
        outlined,
        v-model="currentPatient",
        :options="getPatientOptions()"
      )
        template(v-slot:prepend)
          q-icon(name="people", color="secondary", size="md")

    .col-xs-12.col-sm
      q-select.full-width(
        dense,
        outlined,
        v-model="currentLabel",
        :options="allLabels",
        option-label="name",
        option-value="name"
      )
        template(v-slot:prepend)
          q-icon(name="tag", color="secondary", size="md")

    .col-auto
      q-toggle(label="Include Client Clips", v-model="currentParentToggle")

  div(v-if="allAssets")
    .row(v-if="filterAssets().length == 0")
      .col.q-mt-lg.text-overline.text-center Nothing matches your filter.
    .row.q-col-gutter-md.items-stretch.q-mt-xs
      .col-12.col-sm-6.col-md-4.col-lg-3(
        v-for="asset in filterAssets()",
        :key="asset.id"
      )
        q-card.full-height(flat, bordered)
          .column.full-height
            .col-auto
            q-card-section
              .text-h5 {{ asset.title }}
              .text-subtitle.text-secondary {{ asset.patientName }} &middot; {{ getTime(asset.createdAt) }}
            q-separator
            .col
              q-card-section.full-height
                span(
                  outline,
                  dense,
                  icon="library_books",
                  color="primary",
                  text-color="white"
                ) {{ getTemplate(asset.template) }} &middot;&nbsp;

                span.text-grey-6(
                  outline,
                  dense,
                  icon="people",
                  color="primary",
                  text-color="white",
                  v-if="asset.toParents"
                ) Shared with Client &middot;&nbsp;

                div
                  span.text-secondary(
                    outline,
                    dense,
                    v-for="label in asset.labels",
                    :key="label",
                    icon="tag",
                    color="primary",
                    text-color="white"
                  ) &#35;{{ label }} &middot;
            q-separator
            .col-auto
              q-card-section
                .row.justify-center
                  audio.full-width(controls)
                    source(
                      v-if="asset.src",
                      :src="asset.src",
                      type="audio/mp3"
                    )
</template>

<script>
import { mapGetters } from "vuex";
import { db, functions, getRecordingForAsset } from "@/db";
import { filter, includes, find, orderBy } from "lodash";
import { getTimeInSeconds, formatDuration } from "../utils/formatter";
import moment from "moment";
import notifications from "@/utils/notifications";

export default {
  name: "Learning",
  plugins: [],
  props: {},
  components: {},
  mixins: [notifications],
  computed: {
    db() {
      return db;
    },
    ...mapGetters(["getUser"]),
    allLabels() {
      return ["All", ...this.tags];
    },
  },
  data: () => {
    return {
      sessions: null,
      patients: null,
      allAssets: null,
      sessionNames: {},
      currentSession: { name: "All" },
      currentPatient: "All",
      currentType: "All",
      currentTag: "All",
      currentLabel: "All",
      currentParentToggle: false,
      sessionOptions: [],
      tags: [],
      templates: [],
    };
  },
  methods: {
    getTemplate(id) {
      // console.log(id);
      return find(this.templates, { id: id })?.name;
    },
    getTime(time) {
      return moment.unix(time).format("LT, LL");
    },
    // getS3Link(src) {
    //   if (!src) return "";
    //   let url = `${process.env.VUE_APP_CLOUDFRONT_URL}/${src
    //     .replace("s3://", "")
    //     .replace(process.env.VUE_APP_S3_BUCKET, "")}`;
    //   return url;
    // },
    async getAudio(asset, meetingid, assetid) {
      // console.log(asset);
      let url = await getRecordingForAsset({
        meetingid: meetingid,
        assetid: assetid,
      });
      // console.log(url.data);
      this.$set(asset, "src", url.data);
    },
    getAssetDuration(asset) {
      const endTime = getTimeInSeconds(asset.endTimecode);
      const startTime = getTimeInSeconds(asset.startTimecode);
      return formatDuration(endTime - startTime);
    },
    getPatientOptions() {
      const options = ["All"];
      Object.values(this.patients).forEach((v) => options.push(v.name));
      return options;
    },
    filterAssets() {
      let tmp = [...this.allAssets];

      //filter by session
      // console.log(this.currentSession);
      // Filter sessions
      if (this.currentSession.name !== "All")
        tmp = filter(tmp, (t) => t.template === this.currentSession.id);

      // Filter patients
      if (this.currentPatient !== "All")
        tmp = filter(
          tmp,
          (t) =>
            t.patientName.toLowerCase() === this.currentPatient.toLowerCase()
        );

      // Filter asset tags
      if (this.currentTag !== "All")
        tmp = filter(
          tmp,
          (t) => t.tag && t.tag.toLowerCase() === this.currentTag.toLowerCase()
        );

      // Filter labels
      // console.log(this.currentLabel);
      if (this.currentLabel !== "All")
        tmp = filter(tmp, (t) => includes(t.labels, this.currentLabel.name));

      if (this.currentParentToggle == false) {
        tmp = filter(tmp, (t) => t.toParents == false);
      }

      return tmp;
    },
  },
  async mounted() {
    this.$q.loading.show();
    this.uid = this.getUser.uid;

    //TODO: replace this with a firebase function that loads the data (like for the parent):

    try {
      await Promise.all([
        this.$rtdbBind("tags", db.ref(`meta/tags`)),
        // this.$rtdbBind("sessions", db.ref("meetings").child(this.uid)),
      ]);

      // Fetch all patients for the current coach
      const patients = (await db.ref("patients").once("value")).val();

      const tmpPatients = {};
      Object.keys(patients).forEach((key) => {
        if (patients[key].coach === this.uid) tmpPatients[key] = patients[key];
      });
      this.patients = orderBy(tmpPatients, ["name"], ["asc"]);

      // Get session names from /meta/session
      this.templates = Object.values(
        (await functions.httpsCallable("templates-listTemplates")({})).data
      );

      this.templates.splice(0, 0, { name: "All" });

      // const sessionNames = (await db.ref("meta/session").once("value")).val();
      // this.sessionOptions = ["All", ...Object.values(sessionNames)];
      // this.sessionNames = sessionNames;

      const meetings = (
        await db.ref(`meetings/${this.uid}`).once("value")
      ).val();
      const promises = [];
      // console.log(meetings);
      Object.keys(meetings || {}).forEach((key) => {
        // if (meetings[key].curated)
        promises.push(db.ref(`data/curationAsset/${key}`).once("value"));
      });

      // console.log(promises);

      const curatedMeetingAssets = await Promise.all(promises);

      const allAssets = [];
      // console.log(curatedMeetingAssets);
      curatedMeetingAssets.forEach((item) => {
        // console.log(item.val());
        if (item.val()) {
          Object.keys(item.val()).forEach((itemKey) => {
            const data = item.val()[itemKey];
            if (
              itemKey !== "remaining" &&
              data.status === "generated" &&
              data.toCoach
            ) {
              const patientUid = meetings[item.key].patientUid;
              let ass = {
                ...data,
                id: itemKey,
                patientUid,
                patientName: tmpPatients[patientUid].name,
                meetingId: item.key,
                src: false,
              };
              allAssets.push(ass);
              this.getAudio(ass, item.key, itemKey);
            }
          });
        }
      });
      this.allAssets = orderBy(allAssets, ["createdAt"], ["desc"]);
    } catch (e) {
      this.showError(e);
    }
    this.$q.loading.hide();
  },
  watch: {},
};
</script>

<style>
</style> 

