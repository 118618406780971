import { render, staticRenderFns } from "./ParentLearning.vue?vue&type=template&id=3d1deaf8&scoped=true&lang=pug&"
import script from "./ParentLearning.vue?vue&type=script&lang=js&"
export * from "./ParentLearning.vue?vue&type=script&lang=js&"
import style0 from "./ParentLearning.vue?vue&type=style&index=0&id=3d1deaf8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d1deaf8",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QForm,QInput,QAvatar,QImg,QSeparator,QIcon});
