import { render, staticRenderFns } from "./SessionDetail.vue?vue&type=template&id=37b820e1&scoped=true&lang=pug&"
import script from "./SessionDetail.vue?vue&type=script&lang=js&"
export * from "./SessionDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b820e1",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QBanner,QChip,QIcon,QDialog,QCard,QCardSection,QCardActions});qInstall(component, 'directives', {ClosePopup});
